import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './PropertyModal.module.scss'

import { PATH } from '../../../constants'
import { useCreateNextPath } from '../../../hooks'
import { Button, Modal } from '../../basics'
import { PropertyCard, PropertyCardProps } from '../../contents/PropertyCard'

type PropertyModalProps = {
  isOpen: boolean
  onClose: () => void
  showButton?: boolean
} & { property: PropertyCardProps }

const translateNamespace = 'components.PropertyModal'

export const PropertyModal: React.FC<PropertyModalProps> = ({
  isOpen,
  onClose,
  property,
  showButton = true,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()

  return (
    <Modal
      isWide
      isOpen={isOpen}
      onClose={onClose}
      leftButton={
        showButton ? (
          <Button variant="secondary" htmlType="button" onClick={onClose}>
            {t(`${translateNamespace}.close`)}
          </Button>
        ) : undefined
      }
      rightButton={
        showButton ? (
          <Button
            htmlType="button"
            onClick={() => {
              navigate(
                onCreateNextPath(PATH.property, {
                  pathParams: { property: property.property },
                  queryParams: { page: undefined },
                })
              )
            }}
          >
            {t(`${translateNamespace}.propertyDetail`)}
          </Button>
        ) : undefined
      }
    >
      <div className={styles.propertyModalContent}>
        <PropertyCard
          isTapAllowed={false}
          {...property}
          direction="horizontal"
          className="pc"
        />
        <div className={styles.propertyCard}>
          <PropertyCard isTapAllowed={false} {...property} className="sp" />
        </div>
      </div>
    </Modal>
  )
}
