import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './ScheduleListFrame.module.scss'

import {
  Hero,
  Layout,
  NavigateLinkButton,
  Notification,
  ScheduleTable,
} from '../../components'
import { PATH } from '../../constants'
import {
  useCreateNextPath,
  useNotificationsApi,
  useSearchQueryParams,
} from '../../hooks'

const translateNamespace = 'frames.ScheduleListFrame'

export const ScheduleListFrame: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const { data: notifications } = useNotificationsApi()

  return (
    <Layout>
      <Hero imageUrl="/img/hero.png" title={t(`${translateNamespace}.title`)} />
      <div className={styles.centerArea}>
        {notifications && notifications?.length > 0 && (
          <div className={styles.notificationArea}>
            <Notification notifications={notifications} />
          </div>
        )}
        <ScheduleTable showFilter={false} showPagination pageSize={20} />
        <div className={styles.backToTop}>
          <NavigateLinkButton
            text={t(`${translateNamespace}.backToTop`)}
            arrow="left"
            onClick={() => {
              navigate(
                onCreateNextPath(PATH.root, {
                  queryParams: {
                    startDate: queryParams?.startDate,
                    propertyOrder: undefined,
                    areas: [],
                  },
                })
              )
            }}
          />
        </div>
      </div>
    </Layout>
  )
}
