import { useState } from 'react'

import clsx from 'clsx'

import * as styles from './Accordion.module.scss'

import { BaseText, FontSize, Text } from '../Text'

type AccordionProps = {
  title: React.ReactNode
  subTitle: React.ReactNode
  children: React.ReactNode
  defaultOpen?: boolean
  subTitleColor?: 'gray3' | 'red1'
  className?: string
  spSize?: FontSize
  pcSize?: FontSize
  accordionIcon?: 'arrow' | 'plus'
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  subTitle,
  defaultOpen = false,
  children,
  className,
  spSize = 18,
  pcSize = 18,
  subTitleColor = 'gray3',
  accordionIcon = 'plus',
}) => {
  const [isOpen, setOpen] = useState(defaultOpen)
  return (
    <div className={clsx(styles.accordion, className)}>
      <a
        className={styles.label}
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setOpen(!isOpen)
        }}
      >
        <div className={styles.title}>
          <Text weight="bold" pcSize={pcSize} spSize={spSize}>
            {title}
          </Text>
        </div>
        <BaseText color={subTitleColor} size={18}>
          {subTitle}
        </BaseText>

        <div
          className={clsx(
            accordionIcon === 'arrow'
              ? styles.accordionArrowIcon
              : styles.accordionIcon,
            isOpen && styles.isOpen
          )}
        />
      </a>
      <div className={clsx(styles.content, isOpen && styles.isOpen)}>
        {children}
      </div>
    </div>
  )
}
