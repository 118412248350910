import React, { useState } from 'react'

import * as styles from './Property.module.scss'

import { Flex, FlexBox } from '../../basics'
import { PropertyCard } from '../../contents'
import { PropertyModal } from '../../modals/PropertyModal'

type PropertyProps = {
  name?: string
  description?: string
  descriptionDetail?: string
  slug?: string
  images: string[]
  access?: string
  pricing?: string
  shortName?: string
  insideFvillage?: boolean
}

type PropertyListProps = {
  properties: PropertyProps[]
  canNavigate?: boolean
}

const PropertyCardWrapper: React.FC<
  PropertyProps & {
    canNavigate?: boolean
  }
> = ({
  name,
  slug,
  description,
  descriptionDetail,
  images,
  access,
  pricing,
  shortName,
  canNavigate,
  insideFvillage,
}) => {
  const [openedProperty, setOpenedProperty] = useState(false)
  return (
    <React.Fragment>
      <PropertyCard
        title={name ?? ''}
        images={images}
        access={access}
        pricing={pricing}
        canNavigate={canNavigate}
        property={slug}
        showFitScreenIcon
        onModalOpen={() => setOpenedProperty(true)}
      >
        <p className={styles.desc}>{description}</p>
      </PropertyCard>
      <PropertyModal
        isOpen={openedProperty}
        onClose={() => setOpenedProperty(false)}
        property={{
          images,
          title: shortName ?? '',
          access,
          pricing,
          property: slug,
          children: descriptionDetail,
        }}
        showButton={insideFvillage}
      />
    </React.Fragment>
  )
}

export const PropertyList: React.FC<PropertyListProps> = ({
  properties,
  canNavigate,
}) => (
  <Flex optionClass={styles.list}>
    {properties.map(
      ({
        slug,
        name,
        description,
        descriptionDetail,
        images,
        access,
        pricing,
        shortName,
        insideFvillage,
      }) =>
        name &&
        slug && (
          <FlexBox key={slug} optionClass={styles.item}>
            <PropertyCardWrapper
              slug={slug}
              name={name}
              description={description}
              descriptionDetail={descriptionDetail}
              images={images}
              access={access}
              pricing={pricing}
              shortName={shortName}
              canNavigate={canNavigate}
              insideFvillage={insideFvillage}
            />
          </FlexBox>
        )
    )}
  </Flex>
)
