import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { format, utcToZonedTime } from 'date-fns-tz'

import * as styles from './AreaSearchPanel.module.scss'

import { PATH, TIMEZONE } from '../../../constants'
import {
  SearchParam,
  useCreateNextPath,
  usePropertyApi,
  usePublication,
  useRouteParams,
  useSearchQueryParams,
} from '../../../hooks'
import { useAirlineTicketUrl } from '../../../hooks/useAirlineTicketUrl'
import i18n from '../../../i18n'
import { formatJstDate } from '../../../utils'
import { Button, Col, Container, Row, Text } from '../../basics'
import {
  Area,
  AreaSearchModal,
  FlightTicketModal,
  RoomSearchModal,
  ScheduleModal,
} from '../../modals'
import { Panel } from '../Panel'

type AreaSearchPanelProps = {
  disabled?: boolean
}

const translateNamespace = 'components.Panel'

const useNavigateProperty = () => {
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()

  const onParamsChange = useCallback(
    (queryParams: SearchParam) => {
      navigate(
        onCreateNextPath(PATH.search, {
          queryParams,
        })
      )
      window.scrollTo(0, 0)
    },
    [navigate, onCreateNextPath]
  )

  return onParamsChange
}

type PanelItemProps = {
  label: React.ReactNode
  value: React.ReactNode
  action?: () => void
  disabled?: boolean
}

const PanelItem: React.FC<PanelItemProps> = ({
  label,
  value,
  action,
  disabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.panelItem}>
      <Container>
        <Row>
          <Col pc={9} sp={9}>
            <Text size={12}>{label}</Text>
            <Text size={14} weight="bold" pcAlign="left" spAlign="left">
              {value}
            </Text>
          </Col>

          <Col pc={3} sp={3}>
            <div className={styles.button}>
              <Button
                display="inlineBlock"
                htmlType="button"
                outline
                size="small"
                variant="secondary"
                onClick={action}
                disabled={disabled}
              >
                {t(`${translateNamespace}.change`)}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export const AreaSearchPanel: React.FC<AreaSearchPanelProps> = ({
  disabled = false,
}) => {
  const { t } = useTranslation()
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false)
  const [roomIsOpen, setRoomIsOpen] = useState(false)
  const [areaIsOpen, setAreaIsOpen] = useState(false)
  const [flightTicketIsOpen, setFlightTicketIsOpen] = useState(false)
  const { property: propertySlug } = useRouteParams()
  const { data: property } = usePropertyApi(propertySlug ?? '')
  const publication = usePublication(i18n.language, property?.publications)
  const onParamsChange = useNavigateProperty()

  // TODO refactor
  const areaArray: Area[] = [
    { label: t(`${translateNamespace}.fvillage`), value: 'fvillage' },
    { label: t(`${translateNamespace}.kitahiroshima`), value: 'kitahiroshima' },
    { label: t(`${translateNamespace}.sapporo`), value: 'sapporo' },
    { label: t(`${translateNamespace}.chitose`), value: 'chitose' },
  ]

  const { params } = useSearchQueryParams()
  const {
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomCount,
    areas,
  } = params

  const withAirlineTicketUrl = useAirlineTicketUrl(
    property?.withAirlineTicketUrl
  )

  // TODO refactor
  const displayAreas =
    areas.includes('all') ||
    (areas.every(
      (area) =>
        area === 'fvillage' ||
        area === 'kitahiroshima' ||
        area === 'sapporo' ||
        area === 'chitose'
    ) &&
      areas.length === 4)
      ? t(`${translateNamespace}.allAreas`)
      : areaArray
          .filter(({ value }) => areas.includes(value))
          .map((area) => area.label)
          .join(', ')

  return (
    <React.Fragment>
      <Panel
        cover={{
          desc: '',
          name: t(`${translateNamespace}.facilitySelection`),
          image: property?.images?.at(0)?.url ?? '/img/hero.png',
        }}
        label={t(`${translateNamespace}.label.search`)}
        items={[
          <PanelItem
            label={t(`${translateNamespace}.checkIn`)}
            value={formatJstDate(
              checkInDate,
              i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
            )}
            action={() => setScheduleIsOpen(true)}
            disabled={disabled}
          />,
          <PanelItem
            label={t(`${translateNamespace}.checkOut`)}
            value={formatJstDate(
              checkOutDate,
              i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
            )}
            action={() => setScheduleIsOpen(true)}
            disabled={disabled}
          />,
          <PanelItem
            label={t(`${translateNamespace}.roomCount`)}
            value={`${roomCount} ${t(`${translateNamespace}.roomUnit`)}`}
            action={() => setRoomIsOpen(true)}
            disabled={disabled}
          />,
          <PanelItem
            label={t(`${translateNamespace}.peopleCount`)}
            value={
              <Trans
                i18nKey={`${translateNamespace}.guests`}
                value={{
                  numberOfAdults,
                  numberOfChildren,
                  numberOfInfants,
                }}
              >
                {{ numberOfAdults }} adults
                <br />
                {{ numberOfChildren }} children
                <br />
                {{ numberOfInfants }} infants
              </Trans>
            }
            action={() => setRoomIsOpen(true)}
            disabled={disabled}
          />,
          <PanelItem
            label={t(`${translateNamespace}.area`)}
            value={displayAreas}
            action={() => setAreaIsOpen(true)}
            disabled={disabled}
          />,
        ]}
      />
      {withAirlineTicketUrl && (
        <React.Fragment>
          <div className={styles.reservationButtonWrapper}>
            <Button
              htmlType="button"
              size="full"
              variant="tertiary"
              bold={!!property?.withAirlineTicketUrl}
              onClick={() => setFlightTicketIsOpen(true)}
            >
              {t(`${translateNamespace}.withFlight`)}
            </Button>
          </div>
          <div className={styles.notice}>
            <Text size={12}>{t(`${translateNamespace}.withFlightNote`)}</Text>
          </div>
          <FlightTicketModal
            linkUrl={withAirlineTicketUrl}
            isOpen={flightTicketIsOpen}
            onClose={() => setFlightTicketIsOpen(false)}
          />
        </React.Fragment>
      )}
      {property?.insideFvillage && property?.officialUrl && (
        <div className={styles.notice}>
          <Text size={12}>
            {t(`${translateNamespace}.notice`)}
            <br />
            <a href={property.officialUrl} target="_blank" rel="noreferrer">
              {t(`${translateNamespace}.propertyDetail`, {
                name: publication?.shortName,
              })}
            </a>
          </Text>
        </div>
      )}
      <ScheduleModal
        isOpen={scheduleIsOpen}
        onClose={() => setScheduleIsOpen(false)}
        checkInDate={utcToZonedTime(checkInDate, TIMEZONE)}
        checkOutDate={utcToZonedTime(checkOutDate, TIMEZONE)}
        onSearch={(checkIn, checkOut) => {
          setScheduleIsOpen(false)
          onParamsChange({
            checkInDate: format(checkIn, 'yyyy-MM-dd', {
              timeZone: TIMEZONE,
            }),
            checkOutDate: format(checkOut, 'yyyy-MM-dd', {
              timeZone: TIMEZONE,
            }),
          })
        }}
      />
      <RoomSearchModal
        isOpen={roomIsOpen}
        onClose={() => setRoomIsOpen(false)}
        propertyName={publication?.shortName ?? ''}
        officialUrl={property?.officialUrl ?? ''}
        roomParam={roomCount}
        adultParam={numberOfAdults}
        childParam={numberOfChildren}
        infantParam={numberOfInfants}
        checkIn={checkInDate}
        checkOut={checkOutDate}
        onSearch={(rooms, adults, children, infant) => {
          setRoomIsOpen(false)
          onParamsChange({
            roomCount: rooms,
            numberOfAdults: adults,
            numberOfChildren: children,
            numberOfInfants: infant,
          })
        }}
      />
      <AreaSearchModal
        isOpen={areaIsOpen}
        onClose={() => setAreaIsOpen(false)}
        onSearch={(areaArry) => {
          setAreaIsOpen(false)
          onParamsChange({
            areas: areaArry.map(({ value }) => value),
          })
        }}
      />
    </React.Fragment>
  )
}
