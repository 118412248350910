import React from 'react'

import clsx from 'clsx'

import * as styles from './Pagination.module.scss'
import * as NavStyles from './PaginationNav.module.scss'

import { usePagination } from '../../../hooks'
import { Button } from '../Button'
import { Flex, FlexBox } from '../Flex'
import { Icon } from '../Icon'
import { Text, WhiteText } from '../Text'

type PaginationProps = {
  totalPage: number
  currentPage: number
  pageSize?: number
  paginationInfoLabel?: string
  onChange: (page: number) => void
}

const TEXT_SIZE = 24
// TODO: スマホにしたとき色変える
export const Pagination: React.FC<PaginationProps> = ({
  totalPage,
  currentPage,
  pageSize = 10,
  paginationInfoLabel,
  onChange,
}) => {
  const { pages, paginationCount } = usePagination(
    totalPage,
    pageSize,
    currentPage
  )
  
  // Hide pagination when there is only one page or less
  if (!paginationCount || paginationCount <= 1) {
    return null
  }

  const currentPageStart = (currentPage - 1) * pageSize + 1
  const currentPageEnd =
    currentPage * pageSize > totalPage ? totalPage : currentPage * pageSize

  return (
    <React.Fragment>
      {paginationInfoLabel && (
        <Text align="center" pcSize={16} spSize={12}>
          {currentPageStart} ~ {currentPageEnd} {paginationInfoLabel}
        </Text>
      )}
      <Flex optionClass={styles.paginationWrapper}>
        <FlexBox>
          <Button
            outline
            htmlType="button"
            variant="secondary"
            disabled={currentPage === 1}
            className={clsx(NavStyles.linkBtn, NavStyles.rotate)}
            onClick={() => onChange(currentPage - 1)}
          >
            <Icon mark="arrow" />
          </Button>
        </FlexBox>

        <FlexBox>
          <ul
            className={clsx(
              styles.pagination,
              paginationCount && paginationCount > 8 && styles.smallGap
            )}
          >
            {pages.flat().map((page, i) => {
              if (page === '...') {
                return (
                  <li
                    className={clsx(styles.paginationLink, styles.abbreviation)}
                    key={i === 1 ? 'firstAbbreviation' : 'lastAbbreviation'}
                  >
                    <Text pcSize={TEXT_SIZE} spSize={20}>
                      {page}
                    </Text>
                  </li>
                )
              }

              if (page === currentPage) {
                return (
                  <li
                    className={clsx(styles.paginationLink, styles.active)}
                    key={`pagination-${page}`}
                  >
                    <WhiteText pcSize={TEXT_SIZE} spSize={20}>
                      {page}
                    </WhiteText>
                  </li>
                )
              }
              if (typeof page === 'number') {
                return (
                  <li
                    className={clsx(styles.paginationLink)}
                    key={`pagination-${page}`}
                  >
                    <button
                      type="button"
                      className={styles.paginationItem}
                      onClick={() => onChange(page)}
                    >
                      <Text pcSize={TEXT_SIZE} spSize={20}>
                        {page}
                      </Text>
                    </button>
                  </li>
                )
              }

              return null
            })}
          </ul>
        </FlexBox>

        <FlexBox>
          <Button
            outline
            htmlType="button"
            variant="secondary"
            disabled={paginationCount === currentPage}
            className={NavStyles.linkBtn}
            onClick={() => onChange(currentPage + 1)}
          >
            <Icon mark="arrow" />
          </Button>
        </FlexBox>
      </Flex>
    </React.Fragment>
  )
}
