import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'
import { Order } from '../../constants'
import { filterAreas } from '../../utils'

type UsePropertiesSearchApiProps = {
  checkInDate: string
  checkOutDate: string
  roomCount: number
  numberOfAdults: number
  numberOfChildren: number
  numberOfInfants: number
  offset?: number
  limit?: number
  isHotel?: boolean
  areas?: string[]
  propertyOrder?: string
  showAll?: boolean
}

const isOrder = (value: string | undefined): value is Order => {
  switch (value) {
    case 'min_price':
    case '-min_price':
      return true
    default:
      return false
  }
}

export const usePropertiesSearchApi = ({
  checkInDate,
  checkOutDate,
  numberOfAdults,
  numberOfChildren,
  numberOfInfants,
  roomCount,
  offset,
  limit,
  isHotel = true,
  areas,
  propertyOrder,
  showAll,
}: UsePropertiesSearchApiProps) => {
  const key = JSON.stringify({
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomCount,
    offset,
    limit,
    isHotel,
    areas,
    propertyOrder,
    showAll,
  })
  const {
    data: { results, count, next, previous } = {},
    isLoading,
    isValidating,
    mutate,
    error,
  } = useSWR(`/hotels/rooms?${key}`, () =>
    propertyApi
      .getHotelRooms(
        checkInDate,
        checkOutDate,
        roomCount,
        numberOfAdults,
        numberOfChildren,
        numberOfInfants,
        offset,
        limit,
        isHotel,
        undefined, // insideFvillage set always undefined
        filterAreas(areas),
        isOrder(propertyOrder) ? propertyOrder : undefined,
        showAll,
        options
      )
      .then((res) => res.data)
  )

  return {
    data: results,
    count,
    next,
    previous,
    isLoading,
    isValidating,
    mutate,
    error,
  }
}
