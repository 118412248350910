import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as styles from './PropertyDetailFrame.module.scss'

import {
  Hero,
  Layout,
  Notification,
  PropertyDetail,
  PropertySearchPanel,
} from '../../components'
import { PATH } from '../../constants'
import {
  useCreateNextPath,
  useHeroImage,
  useNotificationsApi,
  usePropertyProvider,
} from '../../hooks'

export const PropertyDetailFrame: React.FC = () => {
  const { data: notifications } = useNotificationsApi()
  const navigate = useNavigate()
  const { params, onCreateNextPath } = useCreateNextPath()
  const { images } = usePropertyProvider()
  const { pc, sp, image } = useHeroImage(images ?? [])

  return (
    <Layout>
      <div className={styles.topBannerArea}>
        <Hero imageUrl={sp ?? image} className={styles.sp} />
        <Hero imageUrl={pc ?? image} className={styles.pc} />
        <div className={styles.propertySearchPanel}>
          <PropertySearchPanel
            showNumberOfGuestsSearch
            onSearch={({
              schedule: { endDate, startDate } = {},
              numberOfGuests,
            }) => {
              navigate(
                onCreateNextPath(PATH.rooms, {
                  pathParams: {
                    room: params.room,
                  },
                  queryParams: {
                    checkInDate: startDate,
                    checkOutDate: endDate,
                    numberOfAdults: numberOfGuests?.adults,
                    numberOfChildren: numberOfGuests?.children,
                    numberOfInfants: numberOfGuests?.infants,
                  },
                })
              )
            }}
          />
        </div>
      </div>
      {notifications && notifications?.length > 0 && (
        <div className={styles.centerArea}>
          <div className={styles.notificationArea}>
            <Notification notifications={notifications} />
          </div>
        </div>
      )}
      <PropertyDetail />
    </Layout>
  )
}
