import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

export const usePropertyDetailApi = (property?: string) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    property ? `/hotels/${property}/details` : null,
    property
      ? () =>
          propertyApi.getHotelDetail(property, options).then((res) => res.data)
      : () => undefined,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
