import React, { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import './Navigation.scss'
import * as styles from './RoomSlideShow.module.scss'

import {
  ImageResponse,
  PublicationResponse,
  RoomResponse,
} from '../../../apis/fvillage'
import { PATH } from '../../../constants'
import {
  useCreateNextPath,
  useImages,
  useMedia,
  usePublication,
  useRoomFeature,
  useSearchQueryParams,
} from '../../../hooks'
import { formatRoomSizeUnit, formatRoomType } from '../../../utils'
import {
  BaseText,
  ButtonWrapper as Button,
  CardSlideShow,
  Col,
  Icon,
  MaskIcon,
  Row,
  SlideShow,
  Text,
  WhiteText,
} from '../../basics'
import { GalleryModal, RoomDetailModal } from '../../modals'

type RoomItemProps = RoomResponse & {
  property: string
}

type RoomCardShowProps = {
  rooms: RoomResponse[]
  property: string
}

type UseDefaultValuesProps = {
  feature: string
  images: ImageResponse[] | undefined
  publications: PublicationResponse[] | undefined
}

const useDefaultValues = ({
  feature,
  images,
  publications,
}: UseDefaultValuesProps) => {
  const { i18n } = useTranslation()

  const publication = usePublication(i18n.language, publications)
  const { canSmoke, petsAllowed, accessible } = useRoomFeature(feature)
  const itemImages = useImages(images)

  return useMemo(
    () => ({
      canSmoke,
      petsAllowed,
      accessible,
      itemImages,
      roomCategory: publication?.name ?? '',
    }),
    [canSmoke, petsAllowed, accessible, itemImages, publication?.name]
  )
}

const translateNamespace = 'components.RoomItem'

const RoomItem: React.FC<RoomItemProps> = ({
  feature,
  images,
  maxPax,
  minPax,
  minPrice,
  publications,
  roomSize,
  roomSizeUnit,
  roomType,
  slug,
  property,
  canReserve,
}) => {
  const { t, i18n } = useTranslation()
  const [isOpenDetailModal, toggleOpenDetailModal] = useState(false)
  const [isOpenGalleryModal, toggleGalleryModal] = useState(false)
  const [galleryInitialSlide, setGalleryInitialSlide] = useState(0)
  const publication = usePublication(i18n.language, publications)
  const { params } = useSearchQueryParams()
  const { numberOfAdults, numberOfChildren, numberOfInfants, roomCount } =
    params

  const { onCreateNextPath } = useCreateNextPath()
  const navigate = useNavigate()
  const isAvailable = !!slug && !!canReserve && minPrice > 0

  const { canSmoke, petsAllowed, accessible, itemImages, roomCategory } =
    useDefaultValues({
      feature,
      images,
      publications,
    })

  return (
    <Fragment>
      {isOpenDetailModal && (
        <RoomDetailModal
          isOpen={isOpenDetailModal}
          images={images}
          onClose={() => toggleOpenDetailModal(false)}
          roomCategoryName={publication?.name ?? ''}
          description={publication?.description ?? ''}
          content={publication?.descriptionDetail ?? ''}
          canSmoke={canSmoke}
          petsAllowed={petsAllowed}
          accessible={accessible}
          minPax={minPax}
          maxPax={maxPax}
          roomSize={roomSize}
          roomSizeUnit={roomSizeUnit}
          roomType={roomType}
          isHotel
        />
      )}
      {isOpenGalleryModal && (
        <GalleryModal
          isOpen={isOpenGalleryModal}
          onClose={() => toggleGalleryModal(false)}
          initialSlide={galleryInitialSlide}
          items={itemImages}
          renderItem={(item) => <img src={item.pc.src} alt={item.alt} />}
        />
      )}
      <Row>
        <Col pc={5} className={styles.itemSlideShow}>
          <SlideShow
            items={itemImages}
            renderItem={(item) => (
              <figure>
                <img
                  src={item.pc.src}
                  alt={item.alt}
                  className="pc"
                  width="1920"
                  height="1440"
                />
                <img
                  src={item.sp.src}
                  alt={item.alt}
                  className="sp"
                  width="600"
                  height="400"
                />
              </figure>
            )}
            onTap={(swiper) => {
              setGalleryInitialSlide(swiper.activeIndex)
              toggleGalleryModal(true)
            }}
          />
        </Col>
        <Col offset={{ pc: 1, sp: 0 }}>
          <div className={styles.item}>
            <div className={styles.itemTitle}>
              <Text pcSize={16} spSize={14} weight="bold">
                {roomCategory}
              </Text>
            </div>
            <div
              className={
                i18n.language === 'ja'
                  ? styles.itemInfoContainer
                  : styles.itemInfoContainerEn
              }
            >
              <div className={styles.itemInfo}>
                <Icon mark="capacity">
                  {t(`${translateNamespace}.capacity`)}:{' '}
                  {minPax !== maxPax
                    ? t(`${translateNamespace}.availableGuestsRange`, {
                        min: minPax,
                        max: maxPax,
                      })
                    : t(`${translateNamespace}.availableGuests`, {
                        max: maxPax,
                      })}
                </Icon>
                <Icon mark="size">
                  {t(`${translateNamespace}.roomSize`)}:{' '}
                  {t(`${translateNamespace}.about`, {
                    number: roomSize,
                  })}
                  {formatRoomSizeUnit(roomSizeUnit)}
                </Icon>
                <Icon mark="bed">
                  {t(`${translateNamespace}.roomType`)}:{' '}
                  {formatRoomType(roomType)}
                </Icon>
                {canSmoke ? (
                  <Icon mark="smoke">
                    {t(`${translateNamespace}.smokingAllowed`)}
                  </Icon>
                ) : (
                  <Icon mark="smokeOff">
                    {t(`${translateNamespace}.nonSmoking`)}
                  </Icon>
                )}
                {petsAllowed && (
                  <Icon mark="dog">
                    {t(`${translateNamespace}.petsAllowed`)}
                  </Icon>
                )}
                {accessible && (
                  <Icon mark="wheelchair">
                    {t(`${translateNamespace}.accessible`)}
                  </Icon>
                )}
              </div>
            </div>
            <div className={styles.itemInfo}>
              <div className={styles.roomsAndGuests}>
                <BaseText color="gray3" size={12}>
                  {t(`${translateNamespace}.guests`, {
                    numberOfAdults,
                    numberOfChildren,
                    numberOfInfants,
                  })}
                  {`・${t(`${translateNamespace}.rooms`, {
                    rooms: roomCount,
                  })}`}
                </BaseText>
              </div>
            </div>
            <div className={styles.price}>
              {minPrice > 0 ? (
                <React.Fragment>
                  {t(`${translateNamespace}.price`)}
                  <b className={styles.num}>{minPrice.toLocaleString()}</b>
                  {t(`${translateNamespace}.yen`)}
                </React.Fragment>
              ) : (
                <b className={styles.notAvailable}>
                  {t('components.ScheduleTable.notAvailable')}
                </b>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={styles.itemDescription}>
          <hr />
          <Row>
            <Col pc={10}>
              <div className={styles.itemDescriptionText}>
                <Text spSize={12}>{publication?.description}</Text>
              </div>
            </Col>
            <Col pc={2}>
              <div className={styles.detailButton}>
                <Button
                  htmlType="button"
                  outline
                  size="smallWide"
                  variant="secondary"
                  position="center"
                  onClick={() => toggleOpenDetailModal(true)}
                >
                  {t(`${translateNamespace}.detail`)}
                </Button>
              </div>
            </Col>
          </Row>
          <hr className="pc" />
          <Button
            htmlType="button"
            position="center"
            variant="primary"
            className={styles.itemButton}
            disabled={!isAvailable}
            onClick={() =>
              navigate(
                onCreateNextPath(PATH.plans, {
                  pathParams: {
                    property,
                    room: slug,
                  },
                })
              )
            }
          >
            <WhiteText weight="bold">
              {t(`${translateNamespace}.selectRoom`)}
            </WhiteText>
          </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

const CustomSwiperNavigation: React.FC = () => (
  <React.Fragment>
    <button
      type="button"
      className={clsx('slide-prev', 'navigateBtn', 'prevBtn')}
    >
      <MaskIcon pcSize={28} url="/img/icon/icon-arrow-white.svg" />
    </button>

    <button
      type="button"
      className={clsx('slide-next', 'navigateBtn', 'nextBtn')}
    >
      <MaskIcon pcSize={28} url="/img/icon/icon-arrow-white.svg" />
    </button>
  </React.Fragment>
)

/**
 * This component is displayed when the screen size is mobile.
 * The rooms are listed in a slide show.
 */
export const RoomSlideShow: React.FC<RoomCardShowProps> = ({
  rooms,
  property,
}) => {
  const { params } = useSearchQueryParams()
  const isWide = useMedia('(min-width: 480px)')

  const filteredRooms = useMemo(
    () =>
      rooms.filter((item) => {
        // minPrice 0 means "stop selling", so not available
        if ((!item.canReserve || item.minPrice <= 0) && !params.showAll) {
          return false
        }
        return true
      }),
    [rooms, params]
  )

  if (!filteredRooms.length) return null

  if (filteredRooms.length === 1)
    return (
      <div className={styles.card}>
        <RoomItem {...filteredRooms[0]} property={property} />
      </div>
    )

  return (
    <div className={styles.slideContainer}>
      <CardSlideShow
        navigation={{ nextEl: '.slide-next', prevEl: '.slide-prev' }}
        bordered={!isWide}
      >
        {filteredRooms.map((room) => (
          <div key={room.id} className={styles.card}>
            <RoomItem {...room} property={property} />
          </div>
        ))}
      </CardSlideShow>

      <CustomSwiperNavigation />
    </div>
  )
}
