import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dompurify from 'dompurify'

import * as styles from './OptionPlanItem.module.scss'

import { OptionResponse } from '../../../apis/fvillage'
import { useImages, usePublication } from '../../../hooks'
import { OptionItem } from '../../../schemas'
import {
  Accordion,
  BaseText,
  Col,
  Container,
  ErrorMessage,
  Flex,
  FlexBox,
  FormSelect,
  Option,
  Row,
  SlideShow,
  Text,
} from '../../basics'
import { GalleryModal } from '../../modals'

type OptionPlanItemProps = {
  option: OptionResponse
  index: number
}

const translateNamespace = 'components.OptionPlanItem'

export const OptionPlanItem: React.FC<OptionPlanItemProps> = ({
  option,
  index,
}) => {
  const { t, i18n } = useTranslation()
  const [isOpenGalleryModal, toggleGalleryModal] = useState(false)
  const [galleryInitialSlide, setGalleryInitialSlide] = useState(0)
  const publication = usePublication(i18n.language, option.publications)
  const images = useImages(option.images)

  const {
    control,
    formState: { errors },
  } = useFormContext<OptionItem>()

  const { isAvailable, subTitle, subTitleColor } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
    const _isAvailable = option?.stocks?.[0]?.availableStock === 0 ?? false
    const price = option.unitSellingPrice
      ? t(`${translateNamespace}.price`, {
          price: option.unitSellingPrice.toLocaleString(),
        })
      : ''
    return {
      isAvailable: _isAvailable,
      subTitle: _isAvailable ? t(`${translateNamespace}.soldout`) : price,
      subTitleColor: _isAvailable ? 'red1' : ('gray3' as 'gray3' | 'red1'),
    }
  }, [t, option.stocks, option.unitSellingPrice])

  if (!option) {
    return null
  }

  return (
    <Accordion
      key={publication?.id}
      title={publication?.name ?? ''}
      subTitle={subTitle}
      defaultOpen={!isAvailable}
      subTitleColor={subTitleColor}
    >
      {isOpenGalleryModal && (
        <GalleryModal
          isOpen={isOpenGalleryModal}
          onClose={() => toggleGalleryModal(false)}
          items={images}
          initialSlide={galleryInitialSlide}
          renderItem={(item) => <img src={item.pc.src} alt={item.alt} />}
        />
      )}
      <div className={styles.content}>
        <Container>
          <Row gutterWidth={20} gutterHeight={10}>
            <Col pc={6}>
              <SlideShow
                items={images}
                renderItem={(item) => (
                  <figure>
                    <img
                      src={item.pc.src}
                      alt={item.alt}
                      className="pc"
                      width="380"
                      height="250"
                    />
                    <img
                      src={item.sp.src}
                      alt={item.alt}
                      className="sp"
                      width="380"
                      height="250"
                    />
                  </figure>
                )}
                onTap={(swiper) => {
                  setGalleryInitialSlide(swiper.activeIndex)
                  toggleGalleryModal(true)
                }}
              />
            </Col>
            <Col pc={6}>
              <div className={styles.labels}>
                <div key={option.id} className={styles.label}>
                  <Flex optionClass={styles.labelFlex}>
                    <FlexBox>{t(`${translateNamespace}.label`)}</FlexBox>
                    <FlexBox optionClass={styles.select}>
                      {publication && option?.stocks?.[0]?.availableStock ? (
                        <FormSelect
                          name={`items.${index}.count`}
                          control={control}
                        >
                          {Array.from(
                            new Array(option.stocks[0].availableStock + 1),
                            (_, i) => (
                              <Option
                                value={i}
                                key={`${publication.name}-option-${i}`}
                              >
                                {i}
                              </Option>
                            )
                          )}
                        </FormSelect>
                      ) : (
                        <Text>{t(`${translateNamespace}.soldout`)}</Text>
                      )}
                      <ErrorMessage
                        errors={errors}
                        name={`items.${index}.count`}
                        message={errors?.items?.[index]?.count?.message ?? ''}
                      />
                    </FlexBox>
                  </Flex>
                </div>
              </div>
              {option.canCancel === false && (
                <div className={styles.cancelPolicy}>
                  <BaseText size={14}>
                    {t(`${translateNamespace}.cannotCancel`)}
                  </BaseText>
                </div>
              )}
            </Col>
          </Row>
          <Row gutterHeight={20}>
            <Col>
              <div
                className="information-body"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(
                    publication?.descriptionDetail ?? ''
                  ),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Accordion>
  )
}
