import React from 'react'
import { useTranslation } from 'react-i18next'

import dompurify from 'dompurify'

import * as styles from './Faq.module.scss'

import { HotelDetail } from '../../../../apis/fvillage'
import { getPublication } from '../../../../hooks'
import { Accordion } from '../../../basics'

type FaqProps = {
  faqs: HotelDetail[]
}

export const Faq: React.FC<FaqProps> = ({ faqs }) => {
  const { i18n } = useTranslation()

  return (
    <div className={styles.faq}>
      <h2>FAQ</h2>

      {faqs.map(({ publications }, index) => {
        const publication = getPublication(i18n.language, publications ?? [])

        if (!publication) return null

        return (
          <Accordion
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            pcSize={20}
            spSize={14}
            subTitle=""
            accordionIcon="arrow"
            title={`${String(index + 1).padStart(2, '0')} ${publication?.name}`}
            className={styles.accordionContainer}
          >
            <div
              className={styles.accordionContent}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(
                  (publication?.descriptionDetail ?? '')
                    .replace(/<\/div><div>/gi, '')
                    .replace(/<br\s*\/?>/gi, '\r\n')
                    .trim(),
                  {
                    ADD_ATTR: ['target'],
                  }
                ),
              }}
            />
          </Accordion>
        )
      })}
    </div>
  )
}
