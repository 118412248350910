import 'material-icons/iconfont/material-icons.css'

import { clsx } from 'clsx'

import * as styles from './MuiIcon.module.scss'

type MuiIconProps = {
  variable?: string | null
  size?: string
  className?: string
}

export const MuiIcon: React.FC<MuiIconProps> = ({
  variable = '',
  size = '24px',
  className,
}) => (
  <div className={className ? clsx(styles.icon, className) : styles.icon}>
    <span className="material-icons-outlined" style={{ fontSize: size }}>
      {variable}
    </span>
  </div>
)
