import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import clsx from 'clsx'
import {
  addDays,
  addMonths,
  getMonth,
  isAfter,
  isSameDay,
  isSameMonth,
  isSaturday,
  isSunday,
  lastDayOfMonth,
  parse,
} from 'date-fns'
import ja from 'date-fns/locale/ja'
import { format, utcToZonedTime } from 'date-fns-tz'

import './ScheduleTable.scss'
import * as styles from './ScheduleTable.module.scss'

import {
  HotelPublicationImage as ImageResponse,
  PropertyAvailabilityResponse,
  PublicationResponse,
} from '../../../apis/fvillage'
import { PATH, TIMEZONE } from '../../../constants'
import {
  useAvailability,
  useCreateNextPath,
  useGameDays,
  usePropertyAvailabilitiesApi,
  usePublication,
  useScheduleWeekNavigation,
  useSearchQueryParams,
} from '../../../hooks'
import { getCurrentJstDateTime, getDefaultCheckInDate } from '../../../utils'
import {
  Button,
  Flex,
  FlexBox,
  Option,
  Pagination,
  Select,
  BaseText as Text,
  fontColor,
} from '../../basics'
import { PropertyModal } from '../../modals'
import { Loader } from '../Loader'

type AvailabilityProps = {
  rate: number | null
  date: string
  property: string
}

const translateNamespace = 'components.ScheduleTable'

const NotAvailable: React.FC<{ slug?: string; days?: number }> = ({
  slug = '',
  days = 16,
}) => (
  <React.Fragment>
    {[...Array(days).keys()].map((v) => (
      <td key={`${slug}-${v}`} className="bgGray">
        <div className="availabilityStatus one" />
      </td>
    ))}
  </React.Fragment>
)

const Availability: React.FC<AvailabilityProps> = ({
  rate,
  date,
  property,
}) => {
  const { onCreateNextPath } = useCreateNextPath()
  const availability = useAvailability(rate)
  const datetime = parse(date, 'yyyy-MM-dd', getCurrentJstDateTime())
  const checkOut = format(addDays(datetime, 1), 'yyyy-MM-dd')
  const nextPath = onCreateNextPath(PATH.rooms, {
    pathParams: { property },
    queryParams: {
      checkInDate: date,
      checkOutDate: checkOut,
    },
  })

  const availabilityStatus = useMemo(() => {
    if (availability === 'notAvailable') {
      return <div className="availabilityStatus one" />
    }

    if (availability === 'occupied') {
      return <div className="availabilityStatus four" />
    }

    if (availability === 'few') {
      return (
        <Link to={nextPath} style={{ display: 'block', height: '100%' }}>
          <div className="availabilityStatus three" />
        </Link>
      )
    }

    return (
      <Link to={nextPath} style={{ display: 'block', height: '100%' }}>
        <div className="availabilityStatus two" />
      </Link>
    )
  }, [availability, nextPath])

  return (
    <td
      className={
        availability === 'notAvailable' || availability === 'occupied'
          ? 'bgGray'
          : ''
      }
    >
      {availabilityStatus}
    </td>
  )
}

const CalendarDate: React.FC<{ date: string; showMonth: boolean }> = ({
  date,
  showMonth,
}) => {
  const { isGameDay, gameDays } = useGameDays()
  const { i18n } = useTranslation()
  const datetime = utcToZonedTime(date, TIMEZONE)
  let color: (typeof fontColor)[keyof typeof fontColor] = 'black1'
  if (isSaturday(datetime)) {
    color = 'blue0'
  } else if (isSunday(datetime)) {
    color = 'red0'
  }

  const image = useMemo(() => {
    if (!isGameDay(datetime)) return ''

    return gameDays.filter(({ date: gameDay }) =>
      isSameDay(gameDay, datetime)
    )[0].image
  }, [datetime, gameDays, isGameDay])

  return (
    <td
      key={date}
      className={styles[`${color}Bg`]}
      style={{ verticalAlign: 'top' }}
    >
      <div className={clsx(styles.date, styles.flexColumn)}>
        <Text
          inline
          pcSize={18}
          spSize={12}
          align="center"
          weight="bold"
          color={color}
        >
          {format(datetime, showMonth ? 'MM/dd' : 'dd')}
        </Text>
        <Text inline align="center" color={color} pcSize={14} spSize={10}>
          {i18n.language === 'ja'
            ? format(datetime, 'EEE', { locale: ja })
            : format(datetime, 'E').toUpperCase()}
        </Text>
      </div>
      {image && (
        <Flex optionClass={styles.gameImageContainer}>
          <Text weight="bold" pcSize={14} spSize={10}>
            VS.
          </Text>
          <img src={image} alt="Opponent logo" />
        </Flex>
      )}
    </td>
  )
}

const PropertyName: React.FC<{
  publications?: PublicationResponse[]
}> = ({ publications }) => {
  const { i18n } = useTranslation()
  const publication = usePublication(i18n.language, publications)
  if (!publication) {
    return null
  }

  const { shortName, access } = publication

  return (
    <div role="button" tabIndex={0} className={styles.propertyName}>
      <h2>{shortName}</h2>
      <Text color="gray3" pcSize={12} spSize={10} weight="normal">
        {access}
      </Text>
    </div>
  )
}

type PropertyModalType = {
  images?: ImageResponse[]
  publications?: PublicationResponse[]
  slug?: string
  insideFvillage?: boolean
}

const PropertyCalendar: React.FC<{
  filterContainerHeight: number
  isPending: boolean
  properties?: PropertyAvailabilityResponse[]
}> = ({ isPending, properties, filterContainerHeight }) => {
  const { t, i18n } = useTranslation()
  const [tableHeight, setTableHeight] = useState<number>(400)
  const tableRef = useRef<HTMLTableElement>(null)
  const [openedProperty, setOpenedProperty] = useState<PropertyModalType>()
  const publication = usePublication(
    i18n.language,
    openedProperty?.publications
  )

  const calendarDates = useMemo(() => {
    if (!properties || !properties[0].availabilities?.length) return null

    const { availabilities } = properties[0]
    let startDate = new Date(availabilities[0].date ?? '')

    return availabilities.map(({ date }, index) => {
      if (!date) return null

      const currentDate = new Date(date)
      const innerStartDate = startDate

      if (!isSameMonth(startDate, currentDate)) startDate = currentDate

      return (
        <CalendarDate
          date={date}
          key={date}
          showMonth={
            index === 0 || !isSameMonth(innerStartDate, new Date(date))
          }
        />
      )
    })
  }, [properties])

  const handleTableHeight = useCallback(() => {
    if (!tableRef.current) return

    setTableHeight(tableRef.current.offsetHeight)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleTableHeight)

    return () => window.removeEventListener('resize', handleTableHeight)
  }, [handleTableHeight])

  useEffect(() => {
    if (!properties?.length) return

    handleTableHeight()
  }, [handleTableHeight, properties])

  if (!properties || !Array.isArray(properties) || isPending) {
    return (
      <div style={{ height: tableHeight, minHeight: '50vh' }}>
        <div className={styles.loading}>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div style={{ minHeight: '50vh' }}>
      <table className={styles.availability} ref={tableRef}>
        <thead style={{ position: 'sticky', top: filterContainerHeight + 1 }}>
          <tr>
            <th className="pc" style={{ textAlign: 'center', zIndex: 10 }}>
              {t(`${translateNamespace}.availability`)}
            </th>
            {calendarDates}
          </tr>
        </thead>
        <tbody>
          {properties.map(
            ({
              id,
              slug,
              images,
              availabilities,
              publications,
              insideFvillage,
            }) => (
              <React.Fragment key={id}>
                <tr className={clsx('sp', styles.propertyNameSp)}>
                  <td
                    align="center"
                    style={{
                      paddingLeft: '10px',
                    }}
                    className={styles.cursorPointer}
                    colSpan={7}
                    onClick={() =>
                      setOpenedProperty({
                        publications,
                        images,
                        slug,
                        insideFvillage,
                      })
                    }
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={images ? images[0].url : ''}
                        width="1440"
                        height="960"
                        alt={images ? images[0].title : 'hotel'}
                      />
                      <PropertyName publications={publications} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th
                    className={clsx('pc', styles.cursorPointer)}
                    onClick={() =>
                      setOpenedProperty({
                        publications,
                        images,
                        slug,
                        insideFvillage,
                      })
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '1.6rem',
                      }}
                    >
                      <img
                        src={images ? images[0].url : ''}
                        width="84"
                        alt={images ? images[0].title : 'hotel'}
                        style={{}}
                      />
                      <PropertyName publications={publications} />
                    </div>
                  </th>
                  {Array.isArray(availabilities) &&
                  availabilities.length > 0 ? (
                    availabilities.map(
                      ({ date, availableRate }) =>
                        date &&
                        slug && (
                          <Availability
                            key={date}
                            date={date}
                            property={slug}
                            rate={availableRate ?? null}
                          />
                        )
                    )
                  ) : (
                    <NotAvailable slug={slug} />
                  )}
                </tr>
                <tr className={clsx(styles.blankLine, 'sp')}>
                  <td colSpan={properties[0]?.availabilities?.length ?? 0}>
                    blank line
                  </td>
                </tr>
              </React.Fragment>
            )
          )}
        </tbody>
      </table>
      {!!openedProperty && (
        <PropertyModal
          isOpen={!!openedProperty}
          onClose={() => setOpenedProperty(undefined)}
          property={{
            images: openedProperty.images?.map(({ url }) => url ?? '') ?? [],
            title: publication?.shortName ?? '',
            access: publication?.access,
            pricing: publication?.pricing,
            property: openedProperty?.slug,
            children: publication?.descriptionDetail,
          }}
          showButton={openedProperty.insideFvillage}
        />
      )}
    </div>
  )
}

type FilterProps = {
  toDate: Date
  fromDate: Date
  showFilter?: boolean
  showSort?: boolean
  options: { value: string; display: string }[]
  onStartTransition: React.TransitionStartFunction
  onSetFromDate: React.Dispatch<React.SetStateAction<string>>
}
const Filter: React.FC<FilterProps> = ({
  fromDate,
  toDate,
  showFilter = false,
  showSort = true,
  options,
  onStartTransition,
  onSetFromDate,
}) => {
  const {
    onUpdateSearchParams,
    params: { propertyOrder, areas },
  } = useSearchQueryParams()
  const { t } = useTranslation()
  const { handleNextWeek, handlePreviousWeek } =
    useScheduleWeekNavigation(fromDate)
  const currentDate = getCurrentJstDateTime()
  const lastDayOfSixMonthsLater = lastDayOfMonth(addMonths(currentDate, 6))
  const startDate = parse(getDefaultCheckInDate(), 'yyyy-MM-dd', currentDate)
  const startMonth = getMonth(startDate)

  const sortOptions = [
    {
      value: 'order',
      display: t(`${translateNamespace}.recommendation`),
    },
    {
      value: 'distance',
      display: t(`${translateNamespace}.distance`),
    },
  ]

  // TODO reafactor: Area related is scattered, so We'll combine them into one.
  const areaOptions = [
    {
      value: 'all',
      display: t(`components.AreaSearchModal.allAreas`),
    },
    {
      value: 'fvillage',
      display: t(`components.AreaSearchModal.fvillage`),
    },
    {
      value: 'kitahiroshima',
      display: t(`components.AreaSearchModal.kitahiroshima`),
    },
    {
      value: 'sapporo',
      display: t(`components.AreaSearchModal.sapporo`),
    },
    {
      value: 'chitose',
      display: t(`components.AreaSearchModal.chitose`),
    },
  ]

  const handleChangeWeek = useCallback(
    (isClickNextWeek: boolean) => {
      const newFromDate = isClickNextWeek
        ? handleNextWeek()
        : handlePreviousWeek()

      onUpdateSearchParams({ startDate: newFromDate }, true)

      onStartTransition(() => {
        onSetFromDate(newFromDate)
      })
    },
    [
      handleNextWeek,
      handlePreviousWeek,
      onSetFromDate,
      onStartTransition,
      onUpdateSearchParams,
    ]
  )

  return (
    <Flex
      optionClass={clsx(
        styles.selectContainer,
        !showFilter && styles.selectContainerFlexEnd
      )}
    >
      <Flex optionClass={styles.dateSelect}>
        <Button
          bold
          htmlType="button"
          variant="secondary"
          outline
          size="small"
          disabled={isSameDay(fromDate, currentDate)}
          onClick={() => handleChangeWeek(false)}
        >
          {t(`${translateNamespace}.previousWeek`)}
        </Button>
        <FlexBox optionClass={clsx(styles.select, styles.date)}>
          <Select
            round
            size="small"
            isBold
            value={format(
              fromDate,
              getMonth(fromDate) === startMonth ? 'yyyy-MM-dd' : 'yyyy-MM-01'
            )}
            onChange={(event) => {
              onStartTransition(() => {
                onSetFromDate(event.target.value)
              })
              onUpdateSearchParams({ startDate: event.target.value }, true)
            }}
          >
            {options.map(({ value, display }) => (
              <Option key={value} value={value}>
                {display}
              </Option>
            ))}
          </Select>
        </FlexBox>
        <Button
          bold
          htmlType="button"
          variant="secondary"
          outline
          size="small"
          disabled={isSameDay(toDate, lastDayOfSixMonthsLater)}
          onClick={() => handleChangeWeek(true)}
        >
          {t(`${translateNamespace}.nextWeek`)}
        </Button>
      </Flex>
      {showFilter && (
        <Flex optionClass={styles.filterSelect}>
          {showSort && (
            <FlexBox optionClass={styles.select}>
              <Select
                round
                isBold
                value={propertyOrder}
                size="small"
                hasArrow={false}
                onChange={(event) => {
                  onStartTransition(() => {
                    onUpdateSearchParams(
                      {
                        propertyOrder: event.target.value,
                        areas: areas.length === 4 ? undefined : areas,
                        page: 1,
                      },
                      true
                    )
                  })
                }}
              >
                {sortOptions.map(({ value, display }) => (
                  <Option key={value} value={value}>
                    {display}
                  </Option>
                ))}
              </Select>
            </FlexBox>
          )}
          <FlexBox optionClass={styles.select}>
            <Select
              round
              isBold
              value={areas.length === 4 ? 'all' : areas.at(0)}
              size="small"
              hasArrow={false}
              onChange={(event) => {
                onStartTransition(() => {
                  onUpdateSearchParams(
                    {
                      areas:
                        event.target.value === 'all'
                          ? []
                          : [event.target.value],
                      propertyOrder,
                      page: 1,
                    },
                    true
                  )
                })
              }}
            >
              {areaOptions.map(({ value, display }) => (
                <Option key={value} value={value}>
                  {display}
                </Option>
              ))}
            </Select>
          </FlexBox>
        </Flex>
      )}
    </Flex>
  )
}

type ScheduleTableProps = {
  pageSize?: number
  showFilter?: boolean
  showPagination?: boolean
}

export const ScheduleTable: React.FC<ScheduleTableProps> = ({
  pageSize = 10,
  showFilter = false,
  showPagination = false,
}) => {
  const {
    params: { startDate: checkInDate },
  } = useSearchQueryParams()
  const { i18n, t } = useTranslation()
  const [isPending, startTransition] = useTransition()
  const [filterContainerHeight, setFilterContainerHeight] = useState(90)
  const filterContainerRef = useRef<HTMLDivElement>(null)

  const current = getCurrentJstDateTime()
  const startDate = parse(getDefaultCheckInDate(), 'yyyy-MM-dd', current)
  const startMonth = getMonth(startDate)
  const [fromDateString, setFromDate] = useState(
    format(parse(checkInDate, 'yyyy-MM-dd', current), 'yyyy-MM-dd')
  )

  const fromDate = parse(fromDateString, 'yyyy-MM-dd', current)
  const lastDayOfSixMonthsLater = lastDayOfMonth(addMonths(current, 6))
  const toDate = isAfter(addDays(fromDate, 6), lastDayOfSixMonthsLater)
    ? lastDayOfSixMonthsLater
    : addDays(fromDate, 6)
  const toDateString = format(toDate, 'yyyy-MM-dd')

  const monthCount = 7
  const dateOptions = [...Array(monthCount)].map((_, i) => {
    const date = addMonths(startDate, i)
    const month = getMonth(date)

    return {
      value: format(date, month === startMonth ? 'yyyy-MM-dd' : 'yyyy-MM-01'),
      display:
        i18n.language === 'ja'
          ? format(date, 'yyyy/MM', { locale: ja })
          : format(date, 'MMM. yyyy'),
    }
  })

  const {
    params: { propertyOrder, areas, page },
    onUpdateSearchParams,
  } = useSearchQueryParams()
  const { data, isLoading } = usePropertyAvailabilitiesApi({
    areas,
    propertyOrder,
    limit: pageSize,
    toDate: toDateString,
    fromDate: fromDateString,
    withPagination: showPagination,
    offset: (page <= 0 ? 0 : page - 1) * pageSize,
  })

  const handleFilterContainerHeight = useCallback(() => {
    if (!filterContainerRef.current) return

    setFilterContainerHeight(filterContainerRef.current.offsetHeight)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleFilterContainerHeight)

    return () =>
      window.removeEventListener('resize', handleFilterContainerHeight)
  }, [handleFilterContainerHeight])

  useEffect(() => {
    handleFilterContainerHeight()
  }, [handleFilterContainerHeight])

  return (
    <React.Fragment>
      <div ref={filterContainerRef} className={styles.sticky}>
        <Flex optionClass={styles.filterContainer}>
          <FlexBox>
            <Filter
              toDate={toDate}
              fromDate={fromDate}
              showFilter={showFilter}
              options={dateOptions}
              onStartTransition={startTransition}
              onSetFromDate={setFromDate}
            />
          </FlexBox>
          <FlexBox>
            <div className="availabilityDesc">
              <div className="availabilityStatus two" />
              <Text size={12} pcSize={14}>
                {t(`${translateNamespace}.available`)} /
              </Text>
              <div className="availabilityStatus three" />
              <Text size={12} pcSize={14}>
                {t(`${translateNamespace}.runningLow`)} /
              </Text>
              <div className="availabilityStatus four" />
              <Text size={12} pcSize={14}>
                {t(`${translateNamespace}.soldOut`)} /
              </Text>
              <div className="availabilityStatus one" />
              <Text size={12} pcSize={14}>
                {t(`${translateNamespace}.notAvailable`)}
              </Text>
            </div>
          </FlexBox>
        </Flex>
      </div>
      <PropertyCalendar
        filterContainerHeight={filterContainerHeight}
        isPending={isPending || isLoading}
        properties={
          showPagination
            ? data?.results
            : (data as PropertyAvailabilityResponse[])
        }
      />
      {showPagination && data?.count && (
        <div style={{ marginTop: '3.2rem' }}>
          <Pagination
            totalPage={data?.count}
            pageSize={pageSize}
            currentPage={page}
            onChange={(p) =>
              onUpdateSearchParams({ page: p, propertyOrder }, true)
            }
          />
        </div>
      )}
    </React.Fragment>
  )
}
