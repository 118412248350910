import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './ReservationButton.module.scss'
import * as animationStyles from './ReservationButtonAnimation.module.scss'

import {
  Button,
  FlightTicketModal,
  MaskIcon,
  SearchModal,
} from '../../../components'

export const useFooterAnimationClass = () => {
  const [lastPosition, setLastPosition] = useState(0)
  const [animationClass, setAnimationClass] = useState(animationStyles.hide)

  const scrollEvent = useCallback(() => {
    // 400 is approximately the height of the footer.
    // When the user is in the area of the footer, the button is hidden.
    const isBottom =
      document.documentElement.scrollHeight -
        document.documentElement.clientHeight -
        window.scrollY <=
      400

    if (isBottom || window.scrollY === 0) {
      setAnimationClass(animationStyles.hide)
      return
    }

    setLastPosition(window.scrollY)

    if (window.scrollY > 0 && window.scrollY < lastPosition) {
      setAnimationClass(animationStyles.showFilled)
    }
  }, [lastPosition])

  return useMemo(
    () => ({
      animationClass,
      scrollEvent,
    }),
    [animationClass, scrollEvent]
  )
}

export const ReservationButton = () => {
  const { t } = useTranslation()
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false)
  const [withFlightModalIsOpen, setWithFlightModalIsOpen] = useState(false)

  const { animationClass, scrollEvent } = useFooterAnimationClass()

  const flightTicketUrl = process.env.VITE_WITH_AIRLINE_TICKET_URL

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent)

    return () => {
      window.removeEventListener('scroll', scrollEvent)
    }
  }, [scrollEvent])

  const translateNamespace = 'components.Footer.button'
  return (
    <div className={clsx(styles.flexWrapper, animationClass)}>
      <div className={clsx(styles.buttonWrapper, styles.cta)}>
        <Button
          htmlType="button"
          size="footer"
          shape="circle"
          variant="cta"
          onClick={() => {
            setSearchModalIsOpen(true)
          }}
        >
          <MaskIcon url="/img/icon/date-range.svg" pcSize={48} spSize={32} />
          <p>{t(`${translateNamespace}.cta`)}</p>
        </Button>
      </div>
      <SearchModal
        isOpen={searchModalIsOpen}
        onClose={() => setSearchModalIsOpen(false)}
      />
      {flightTicketUrl && (
        <React.Fragment>
          <div className={clsx(styles.buttonWrapper, styles.withFlight)}>
            <Button
              htmlType="button"
              size="footer"
              shape="circle"
              variant="primary"
              onClick={() => setWithFlightModalIsOpen(true)}
            >
              <MaskIcon
                url="/img/icon/airplane-ticket.svg"
                pcSize={48}
                spSize={32}
              />
              <p>{t(`${translateNamespace}.withFlight`)}</p>
            </Button>
          </div>
          <FlightTicketModal
            linkUrl={flightTicketUrl}
            isOpen={withFlightModalIsOpen}
            onClose={() => setWithFlightModalIsOpen(false)}
          />
        </React.Fragment>
      )}
    </div>
  )
}
