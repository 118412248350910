import { useTranslation } from 'react-i18next'

import styles from './ShowAllToggle.module.scss'

import { useSearchQueryParams } from '../../../hooks'
import { BaseText, Toggle } from '../../basics'

const translateNamespace = 'components.ShowAllToggle'

export const ShowAllToggle: React.FC<{ withPagination?: boolean }> = ({
  withPagination = false,
}) => {
  const { t } = useTranslation()
  const {
    params: { showAll },
    onUpdateSearchParams,
  } = useSearchQueryParams()
  return (
    <div className={styles.showAll}>
      <Toggle
        defaultIsOn={!showAll}
        onToggle={(isOn) =>
          onUpdateSearchParams(
            { showAll: !isOn, page: withPagination ? 1 : undefined },
            true
          )
        }
        text={
          <BaseText color="gray1" size={14}>
            {t(`${translateNamespace}.showReservable`)}
          </BaseText>
        }
      />
    </div>
  )
}
