import React from 'react'

import clsx from 'clsx'

import { BaseLayoutProps, Layout } from './Layout'
import * as styles from './Layout.module.scss'

import { useNotificationsApi } from '../../../hooks'
import { Notification } from '../../contents'

type SearchLayoutProps = BaseLayoutProps

export const SearchLayout: React.FC<SearchLayoutProps> = ({ children }) => {
  const { data: notifications } = useNotificationsApi()

  return (
    <Layout isPcBgGray>
      {notifications && notifications?.length > 0 && (
        <div className={clsx(styles.notificationWrapper, styles.searchLayout)}>
          <Notification notifications={notifications} />
        </div>
      )}
      <div className={styles.search}>
        <div className={styles.content}>{children}</div>
      </div>
    </Layout>
  )
}

type SearchMainContentProps = {
  children: React.ReactNode
}

export const SearchMainContent: React.FC<SearchMainContentProps> = ({
  children,
}) => <div className={styles.main}>{children}</div>

type SearchBottomContentProps = {
  children: React.ReactNode
}

export const SearchBottomContent: React.FC<SearchBottomContentProps> = ({
  children,
}) => <div className={styles.bottom}>{children}</div>
