import clsx from 'clsx'

import * as styles from './GalleryModal.module.scss'

import {
  Modal,
  ModalProps,
  ThumbsGallery,
  ThumbsGalleryProps,
} from '../../basics'

type GalleryModalProps<T> = Pick<ModalProps, 'isOpen' | 'onClose'> &
  ThumbsGalleryProps<T>

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const GalleryModal = <T,>({
  isOpen,
  onClose,
  ...props
}: GalleryModalProps<T>) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div
      className={clsx(
        styles.container,
        props.items.length > 1 && styles.hasSwiperThumbs
      )}
    >
      <ThumbsGallery {...props} />
    </div>
  </Modal>
)
