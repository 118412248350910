import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './SearchPanel.module.scss'

import { PATH } from '../../../constants'
import {
  PathParams,
  SearchParam,
  useCreateNextPath,
  useProperties,
  usePropertyApi,
  usePublication,
  useRouteParams,
  useSearchQueryParams,
} from '../../../hooks'
import { useAirlineTicketUrl } from '../../../hooks/useAirlineTicketUrl'
import i18n from '../../../i18n'
import { formatJstDate } from '../../../utils'
import { Button, Col, Container, Row, Text } from '../../basics'
import {
  CalendarModal,
  FlightTicketModal,
  PropertyListModal,
  RoomSearchModal,
} from '../../modals'
import { Panel } from '../Panel'

const translateNamespace = 'components.Panel'

const useNavigateRooms = () => {
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()

  const onParamsChange = useCallback(
    (pathParams: PathParams, queryParams: SearchParam) => {
      navigate(
        onCreateNextPath(PATH.rooms, {
          pathParams,
          queryParams,
        })
      )
      window.scrollTo(0, 0)
    },
    [navigate, onCreateNextPath]
  )

  return onParamsChange
}

type PanelItemProps = {
  label: React.ReactNode
  value: React.ReactNode
  action?: () => void
}

const PanelItem: React.FC<PanelItemProps> = ({ label, value, action }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.panelItem}>
      <Container>
        <Row>
          <Col pc={9} sp={9}>
            <Text size={12}>{label}</Text>
            <Text size={14} weight="bold" pcAlign="left" spAlign="left">
              {value}
            </Text>
          </Col>

          <Col pc={3} sp={3}>
            <div className={styles.button}>
              <Button
                display="inlineBlock"
                htmlType="button"
                outline
                size="small"
                variant="secondary"
                onClick={action}
              >
                {t(`${translateNamespace}.change`)}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export const SearchPanel: React.FC = () => {
  const [propertyIsOpen, setPropertyIsOpen] = useState(false)
  const [calendarIsOpen, setCalendarIsOpen] = useState(false)
  const [roomIsOpen, setRoomIsOpen] = useState(false)
  const [flightTicketIsOpen, setFlightTicketIsOpen] = useState(false)
  const { t } = useTranslation()
  const { property: propertySlug } = useRouteParams()
  const { data: property } = usePropertyApi(propertySlug ?? '')
  const publication = usePublication(i18n.language, property?.publications)
  const onParamsChange = useNavigateRooms()

  const properties = useProperties().properties.map((p) => ({
    shortName: p.shortName ?? '',
    slug: p.slug ?? '',
    images: p.images,
    insideFvillage: p.insideFvillage,
  }))

  const { params } = useSearchQueryParams()
  const {
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomCount,
  } = params

  const withAirlineTicketUrl = useAirlineTicketUrl(
    property?.withAirlineTicketUrl
  )

  const image = useMemo(() => property?.images?.at(0)?.url, [property?.images])

  return (
    <React.Fragment>
      <Panel
        cover={{
          name: publication?.name ?? '',
          image: image ?? '/img/hotel01.jpg',
          desc: t(`${translateNamespace}.action.room`),
        }}
        label={t(`${translateNamespace}.label.search`)}
        items={[
          <PanelItem
            label={t(`${translateNamespace}.property`)}
            value={publication?.name ?? ''}
            action={() => setPropertyIsOpen(true)}
          />,
          <PanelItem
            label={t(`${translateNamespace}.checkIn`)}
            value={formatJstDate(
              checkInDate,
              i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
            )}
            action={() => setCalendarIsOpen(true)}
          />,
          <PanelItem
            label={t(`${translateNamespace}.checkOut`)}
            value={formatJstDate(
              checkOutDate,
              i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
            )}
            action={() => setCalendarIsOpen(true)}
          />,
          <PanelItem
            label={t(`${translateNamespace}.roomCount`)}
            value={roomCount + t(`${translateNamespace}.roomUnit`)}
            action={() => setRoomIsOpen(true)}
          />,
          <PanelItem
            label={t(`${translateNamespace}.peopleCount`)}
            value={
              <Trans
                i18nKey={`${translateNamespace}.guests`}
                value={{
                  numberOfAdults,
                  numberOfChildren,
                  numberOfInfants,
                }}
              >
                {{ numberOfAdults }} adults
                <br />
                {{ numberOfChildren }} children
                <br />
                {{ numberOfInfants }} infants
              </Trans>
            }
            action={() => setRoomIsOpen(true)}
          />,
        ]}
      />
      {withAirlineTicketUrl && (
        <React.Fragment>
          <div className={styles.reservationButtonWrapper}>
            <Button
              htmlType="button"
              size="full"
              variant="tertiary"
              bold={!!property?.withAirlineTicketUrl}
              onClick={() => setFlightTicketIsOpen(true)}
            >
              {t(`${translateNamespace}.withFlight`)}
            </Button>
          </div>
          <div className={styles.notice}>
            <Text size={12}>{t(`${translateNamespace}.withFlightNote`)}</Text>
          </div>
          <FlightTicketModal
            linkUrl={withAirlineTicketUrl}
            isOpen={flightTicketIsOpen}
            onClose={() => setFlightTicketIsOpen(false)}
          />
        </React.Fragment>
      )}
      {property?.insideFvillage && property?.officialUrl && (
        <div className={styles.notice}>
          <Text size={12}>
            {t(`${translateNamespace}.notice`)}
            <br />
            <a href={property.officialUrl} target="_blank" rel="noreferrer">
              {t(`${translateNamespace}.propertyDetail`, {
                name: publication?.shortName,
              })}
            </a>
          </Text>
        </div>
      )}
      <PropertyListModal
        isOpen={propertyIsOpen}
        onClose={() => setPropertyIsOpen(false)}
        onNext={(selectedProperty) => {
          setPropertyIsOpen(false)
          onParamsChange({ property: selectedProperty.slug }, params)
        }}
        defaultProperty={{
          shortName: publication?.name ?? '',
          slug: property?.slug ?? '',
          images: image ? [image] : [],
        }}
        properties={properties}
        nextButtonText={t(`${translateNamespace}.search`)}
      />
      <CalendarModal
        propertyName={publication?.name ?? ''}
        checkInParam={checkInDate}
        checkOutParam={checkOutDate}
        isOpen={calendarIsOpen}
        onClose={() => setCalendarIsOpen(false)}
        onSearch={(checkIn, checkOut) => {
          onParamsChange(
            { property: property?.slug },
            {
              checkInDate: checkIn,
              checkOutDate: checkOut,
            }
          )
          setCalendarIsOpen(false)
        }}
        key={`${checkInDate}${checkOutDate}`}
      />
      <RoomSearchModal
        isOpen={roomIsOpen}
        onClose={() => setRoomIsOpen(false)}
        propertyName={publication?.shortName ?? ''}
        officialUrl={property?.officialUrl ?? ''}
        roomParam={roomCount}
        adultParam={numberOfAdults}
        childParam={numberOfChildren}
        infantParam={numberOfInfants}
        checkIn={checkInDate}
        checkOut={checkOutDate}
        onSearch={(rooms, adults, children, infant) => {
          setRoomIsOpen(false)
          onParamsChange(
            { property: property?.slug },
            {
              roomCount: rooms,
              numberOfAdults: adults,
              numberOfChildren: children,
              numberOfInfants: infant,
            }
          )
        }}
        key={`${roomCount}${numberOfAdults}${numberOfChildren}${numberOfInfants}`}
      />
    </React.Fragment>
  )
}
