import React from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './CatchCopy.module.scss'

import { usePropertyProvider, usePublication } from '../../../../hooks'
import { BaseText, Flex, FlexBox, Text, WhiteText } from '../../../basics'

const translateNamespace = 'components.CatchCopy'
export const CatchCopy: React.FC = () => {
  const { t, i18n } = useTranslation()
  const property = usePropertyProvider()
  const publication = usePublication(i18n.language, property.publications)

  if (!publication) return null

  const {
    shortName,
    description,
    descriptionDetail,
    information,
    recommendation,
  } = publication

  return (
    <Flex optionClass={styles.container}>
      <FlexBox>
        <Flex optionClass={styles.infoContainer}>
          <h1>{shortName}</h1>
          {information && (
            <FlexBox optionClass={styles.operatingTimeContainer}>
              <BaseText pcSize={14} spSize={12} weight="bold" color="gray2">
                {information}
              </BaseText>
            </FlexBox>
          )}
        </Flex>
      </FlexBox>

      <FlexBox optionClass={styles.detail}>
        <Text pcSize={24} spSize={18} weight="bold">
          {description}
        </Text>
        <p style={{ height: '3.2rem' }} />
        <Text pcSize={16} spSize={14}>
          {descriptionDetail}
        </Text>
      </FlexBox>

      {recommendation && (
        <FlexBox>
          <div className={styles.recommendText}>
            <WhiteText pcSize={20} spSize={14}>
              {t(`${translateNamespace}.recommendation`)}
            </WhiteText>
          </div>
          <div className={styles.recommendation}>
            <Text pcSize={16} spSize={12}>
              {recommendation}
            </Text>
          </div>
        </FlexBox>
      )}
    </Flex>
  )
}
