import { Trans, useTranslation } from 'react-i18next'

import { Section } from '../../basics'

type ConfirmationCodeProps = {
  confirmationCode: string
}

const translateNamespace = 'components.ConfirmationCode'

export const ConfirmationCode: React.FC<ConfirmationCodeProps> = ({
  confirmationCode,
}) => {
  const { t } = useTranslation()

  // FIXME styling
  return (
    <Section>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            border: '5px solid #c1c6c8',
            padding: '5rem 2rem',
          }}
        >
          <p
            style={{
              fontSize: '2.2rem',
              letterSpacing: '.06em',
              fontWeight: 'bold',
            }}
          >
            {t(`${translateNamespace}.title`)}
          </p>
          <p
            style={{
              fontSize: '3rem',
              letterSpacing: '.1em',
              fontFamily: '"Fira Sans",sans-serif',
              color: '#009ecb',
              marginTop: '2rem',
              wordBreak: 'break-word',
            }}
          >
            {confirmationCode}
          </p>
          <p
            style={{
              marginTop: '2rem',
              color: '#494949',
            }}
          >
            <Trans i18nKey={`${translateNamespace}.note`}>
              Please keep this confirmation code in a safe place
              <br />
              as it is necessary for your inquiry.
            </Trans>
          </p>
        </div>
      </div>
    </Section>
  )
}
