import React from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './Guidline.module.scss'

import { HotelDetail } from '../../../../apis/fvillage'
import { getPublication } from '../../../../hooks'
import {
  Col,
  Container,
  Flex,
  FlexBox,
  MuiIcon,
  Row,
  Text,
} from '../../../basics'

type GuidlineProps = {
  guidelines: HotelDetail[]
}

const translateNamespace = 'components.Guidline'
export const Guidline: React.FC<GuidlineProps> = ({ guidelines }) => {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.guidline}>
      <h2>{t(`${translateNamespace}.title`)}</h2>

      <Container>
        <Row gutterWidth={32}>
          {guidelines.map(({ publications }, index) => {
            const publication = getPublication(
              i18n.language,
              publications ?? []
            )
            if (!publication) return null
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Col pc={6} key={index}>
                <Flex optionClass={styles.guidlineItem}>
                  <MuiIcon size="28px" variable={publication?.icon} />
                  <FlexBox optionClass={styles.guidlineItemInfo}>
                    <Text pcSize={18} spSize={16} weight="bold">
                      {publication?.name}
                    </Text>
                    <Text pcSize={14} spSize={12}>
                      {publication?.description}
                    </Text>
                  </FlexBox>
                </Flex>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}
