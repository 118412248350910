import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './BudgetSelect.module.scss'

import { BudgetLimits } from '../../../constants'
import { Flex, FlexBox, Option, Select } from '../../basics'

type CustomSelectProps = {
  className?: string
  budgetMin: string
  budgetMax: string
  onBudgetMinChange: (value: string) => void
  onBudgetMaxChange: (value: string) => void
}

const translateNamespace = 'components.CustomSelect'

export const BudgetSelect: React.FC<CustomSelectProps> = ({
  className,
  budgetMin,
  budgetMax,
  onBudgetMinChange,
  onBudgetMaxChange,
}) => {
  const { t } = useTranslation()

  return (
    <Flex optionClass={clsx(styles.flexWrapper, className)}>
      <FlexBox optionClass={clsx(styles.flexBox, budgetMin ? '' : styles.gray)}>
        <Select
          round
          value={budgetMin}
          onChange={(e) => onBudgetMinChange(e.target.value)}
        >
          <Option value="">{t(`${translateNamespace}.noBudgetMinimum`)}</Option>
          {BudgetLimits.map((value) => (
            <Option
              key={value}
              value={value}
              disabled={!!budgetMax && value > Number(budgetMax)}
            >
              ¥{value.toLocaleString()}
            </Option>
          ))}
        </Select>
      </FlexBox>

      <span className={styles.wavyLine}>~</span>

      <FlexBox optionClass={clsx(styles.flexBox, budgetMax ? '' : styles.gray)}>
        <Select
          round
          value={budgetMax}
          onChange={(e) => onBudgetMaxChange(e.target.value)}
        >
          <Option value="">{t(`${translateNamespace}.noBudgetMaximum`)}</Option>
          {BudgetLimits.map((value) => (
            <Option
              key={value}
              value={value}
              disabled={value < Number(budgetMin)}
            >
              ¥{value.toLocaleString()}
            </Option>
          ))}
        </Select>
      </FlexBox>
    </Flex>
  )
}
