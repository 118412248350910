import React from 'react'

import * as styles from './FullCalendar.module.scss'

import { useAvailability } from '../../../hooks'
import { BaseText, Icon } from '../../basics'

export const Availability: React.FC<{ rate: number | null }> = ({ rate }) => {
  const availability = useAvailability(rate)

  if (availability === 'notAvailable') {
    return <Icon mark="bar" />
  }

  if (availability === 'occupied') {
    return <Icon mark="cross" />
  }

  if (availability === 'few') {
    return <Icon mark="triangle" />
  }

  return <Icon mark="circle" />
}

export const DisabledAvailability = () => (
  <React.Fragment>
    <div className={styles.date} style={{ color: 'transparent' }}>
      <BaseText spSize={12} pcSize={20}>
        blank
      </BaseText>
    </div>
    <div className={styles.availabilityStatus}>
      <Icon mark="bar" />
    </div>
  </React.Fragment>
)
