import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import { addDays, endOfDay, isBefore, isSameDay, parse } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

import { Availability, DisabledAvailability } from './Availability'
import * as styles from './FullCalendar.module.scss'

import { PATH, TIMEZONE } from '../../../constants'
import {
  useCreateNextPath,
  useGameDays,
  usePropertyDetailAvailabilitiesApi,
  usePropertyProvider,
} from '../../../hooks'
import { getCurrentJstDateTime } from '../../../utils'
import { BaseText, MaskIcon } from '../../basics'
import { Loader } from '../Loader'

type FullCalendarProps = {
  fromDate: string
  toDate: string
}

// The original design displays the days of the week in English without translation.
const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export const FullCalendar: React.FC<FullCalendarProps> = ({
  fromDate,
  toDate,
}) => {
  const { onCreateNextPath } = useCreateNextPath()
  const navigate = useNavigate()
  const { isGameDay, gameDays } = useGameDays()
  const property = usePropertyProvider()
  const { data, isLoading } = usePropertyDetailAvailabilitiesApi(
    property.slug,
    fromDate,
    toDate
  )

  const getNextPath = useCallback(
    (date: string, slug: string) => {
      const datetime = parse(date, 'yyyy-MM-dd', getCurrentJstDateTime())
      const checkOut = format(addDays(datetime, 1), 'yyyy-MM-dd')

      return onCreateNextPath(PATH.rooms, {
        pathParams: { property: slug },
        queryParams: {
          checkInDate: date,
          checkOutDate: checkOut,
        },
      })
    },
    [onCreateNextPath]
  )

  if (!data || isLoading)
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    )

  const availabilities = []
  for (let i = 0; i < 42; i += 7) {
    availabilities.push(data.availabilities?.slice(i, i + 7))
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {daysOfWeek.map((day, i) => (
            <th key={day}>
              <BaseText
                pcSize={16}
                spSize={12}
                // eslint-disable-next-line no-nested-ternary
                color={i === 0 ? 'red0' : i === 6 ? 'blue0' : 'white'}
                weight="normal"
              >
                {day}
              </BaseText>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {availabilities.map((items, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={i}>
            {items?.map(({ date, availableRate }, index) => {
              const disabled = isBefore(
                endOfDay(new Date(date ?? '')),
                endOfDay(new Date())
              )
              return (
                <td
                  align="center"
                  key={date}
                  style={{
                    cursor: disabled ? 'auto' : undefined,
                  }}
                  className={clsx(
                    (availableRate == null ||
                      availableRate === 0 ||
                      disabled) &&
                      styles.grayBg
                  )}
                  onClick={() => {
                    if (
                      availableRate == null ||
                      availableRate === 0 ||
                      disabled
                    )
                      return

                    if (date && property.slug)
                      navigate(getNextPath(date, property.slug))
                  }}
                >
                  {date ? (
                    <div>
                      {disabled ? (
                        <DisabledAvailability />
                      ) : (
                        <React.Fragment>
                          <div className={styles.date}>
                            {date && (
                              <BaseText
                                weight="bold"
                                spSize={12}
                                pcSize={20}
                                color={
                                  // eslint-disable-next-line no-nested-ternary
                                  index === 0
                                    ? 'red0'
                                    : index === 6
                                    ? 'blue0'
                                    : 'black0'
                                }
                              >
                                {new Date(date).getDate()}
                              </BaseText>
                            )}
                            {(() => {
                              const zonedDate = utcToZonedTime(date, TIMEZONE)
                              if (!isGameDay(zonedDate)) return null

                              const gameInfo = gameDays.find(
                                ({ date: gameDay }) =>
                                  isSameDay(gameDay, zonedDate)
                              )

                              return gameInfo?.image ? (
                                <img
                                  src={gameInfo.image}
                                  alt="Opponent logo"
                                  className={styles.teamLogo}
                                />
                              ) : (
                                <MaskIcon url="/img/icon/icon-baseball-black.svg" />
                              )
                            })()}
                          </div>
                          <div className={styles.availabilityStatus}>
                            <Availability rate={availableRate ?? null} />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  ) : (
                    <DisabledAvailability />
                  )}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
