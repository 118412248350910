import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './Pickup.module.scss'

import {
  HotelDetail,
  HotelDetailPublication,
  HotelPublicationImage as ImageResponse,
} from '../../../../apis/fvillage'
import { getPublication, usePublication } from '../../../../hooks'
import { CardSlideShow, Flex, FlexBox, WhiteText } from '../../../basics'

type ItemProps = {
  images?: ImageResponse[]
  publication?: HotelDetailPublication
}

const Item: React.FC<ItemProps> = ({ images, publication }) => (
  <React.Fragment>
    <img
      src={images?.length ? images[0].url : ''}
      alt={images?.length ? images[0].title : ''}
    />

    <WhiteText weight="bold" spSize={16} pcSize={18}>
      {publication?.name}
    </WhiteText>

    <div style={{ height: '1.2rem' }} />

    <WhiteText spSize={12} pcSize={16}>
      {publication?.description}
    </WhiteText>
    {publication?.url && (
      <div className={styles.url}>
        <a href={publication.url} target="_blank" rel="noreferrer">
          {publication.url}
        </a>
      </div>
    )}
  </React.Fragment>
)

type PickupProps = {
  items: HotelDetail[]
}

const translateNamespace = 'components.Pickup'

export const Pickup: React.FC<PickupProps> = ({ items }) => {
  const { t, i18n } = useTranslation()

  const firstItem = items[0]
  const firstItemPublication = usePublication(
    i18n.language,
    firstItem.publications
  )

  return (
    <div className={styles.pickupContainer}>
      <h2>{t(`${translateNamespace}.title`)}</h2>

      <Flex optionClass={styles.firstItem}>
        <FlexBox optionClass={styles.firstItemImage}>
          <img
            src={firstItem.images?.length ? firstItem.images[0].url : ''}
            alt="pickup hotel"
          />
        </FlexBox>

        <FlexBox>
          <h3 style={{ wordBreak: 'break-word' }}>
            {firstItemPublication?.name}
          </h3>
          <WhiteText pcSize={16} spSize={12}>
            {firstItemPublication?.description}
          </WhiteText>
          {firstItemPublication?.url && (
            <div className={styles.url}>
              <a
                href={firstItemPublication.url}
                target="_blank"
                rel="noreferrer"
              >
                {firstItemPublication.url}
              </a>
            </div>
          )}
        </FlexBox>
      </Flex>

      <CardSlideShow spaceBetween="6%" className={clsx(styles.slideCard, 'sp')}>
        {[...items].splice(1).map(({ images, publications }, index) => {
          const publication = getPublication(i18n.language, publications ?? [])

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <Item images={images} publication={publication} />
            </div>
          )
        })}
      </CardSlideShow>

      <div className={clsx(styles.pcListContainer, 'pc')}>
        {[...items].splice(1).map(({ images, publications }, index) => {
          const publication = getPublication(i18n.language, publications ?? [])

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.item}>
              <Item images={images} publication={publication} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
