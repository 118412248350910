import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { format, utcToZonedTime } from 'date-fns-tz'

import * as styles from './CalendarModal.module.scss'

import { TIMEZONE } from '../../../constants'
import { formatJstDate } from '../../../utils'
import {
  BaseText,
  Button,
  Calendar,
  Flex,
  FlexBox,
  List,
  Modal,
  ModalProps,
  Text,
} from '../../basics'

const translateNamespace = 'components.SearchModal'

type CalendarModalProps = Pick<
  ModalProps,
  'isOpen' | 'onClose' | 'onPrevious'
> & {
  checkInParam?: string
  checkOutParam?: string
  propertyName: string
  onSearch: (checkInParam: string, checkOutParam: string) => void
  mode?: 'range' | 'single'
}

export const CalendarModal: React.FC<CalendarModalProps> = ({
  isOpen,
  onClose,
  checkInParam,
  checkOutParam,
  propertyName,
  onSearch,
  onPrevious,
  mode = 'range',
}) => {
  const { t, i18n } = useTranslation()
  const [checkIn, setCheckIn] = useState<Date | undefined>(
    checkInParam ? utcToZonedTime(checkInParam, TIMEZONE) : undefined
  )
  const [checkOut, setCheckOut] = useState<Date | undefined>(
    checkOutParam ? utcToZonedTime(checkOutParam, TIMEZONE) : undefined
  )
  // Helper function to format dates based on language
  const formatDate = (date: Date | undefined, language: string): string => {
    if (!date) return ''
    
    const dateFormat = language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
    return formatJstDate(date, dateFormat)
  }
  
  const items = useMemo(() => {
    const checkInText = formatDate(checkIn, i18n.language)
    const checkOutText = formatDate(checkOut, i18n.language)

    return [
      {
        label: `${t(`${translateNamespace}.property`)}`,
        text: `${propertyName}`,
      },
      {
        label:
          mode === 'range'
            ? `${t(`${translateNamespace}.checkIn`)}`
            : `${t(`${translateNamespace}.dateOfUse`)}`,
        text: `${checkInText}`,
      },
      mode === 'range'
        ? {
            label: `${t(`${translateNamespace}.checkOut`)}`,
            text: `${checkOutText}`,
          }
        : null,
    ].filter(Boolean)
  }, [checkIn, checkOut, propertyName, t, i18n, mode])
  const invalidDate = useMemo(
    () => mode === 'range' && checkIn?.getTime() === checkOut?.getTime(),
    [mode, checkIn, checkOut]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (checkInParam) {
          setCheckIn(utcToZonedTime(checkInParam, TIMEZONE))
        }
        if (checkOutParam) {
          setCheckOut(utcToZonedTime(checkOutParam, TIMEZONE))
        }
        onClose()
      }}
      onPrevious={onPrevious}
      title={t(`${translateNamespace}.selectDate`)}
      subTitle={
        mode === 'range' && (
          <React.Fragment>
            {t(`${translateNamespace}.selectDateDesc.line1`)}
            <br className="sp" />
            {t(`${translateNamespace}.selectDateDesc.line2`)}
          </React.Fragment>
        )
      }
      leftButton={
        <Button
          variant="secondary"
          htmlType="button"
          onClick={() => {
            if (checkInParam) {
              setCheckIn(utcToZonedTime(checkInParam, TIMEZONE))
            }
            if (checkOutParam) {
              setCheckOut(utcToZonedTime(checkOutParam, TIMEZONE))
            }
            onClose()
          }}
        >
          {t(`${translateNamespace}.close`)}
        </Button>
      }
      rightButton={
        <Button
          htmlType="button"
          disabled={
            mode === 'range' ? !checkIn || !checkOut || invalidDate : !checkIn
          }
          onClick={() => {
            if (!checkIn || !checkOut) {
              return
            }
            onSearch(
              format(checkIn, 'yyyy-MM-dd', { timeZone: TIMEZONE }),
              format(checkOut, 'yyyy-MM-dd', { timeZone: TIMEZONE })
            )
          }}
        >
          {t(`${translateNamespace}.search`)}
        </Button>
      }
    >
      <Flex optionClass={styles.contents}>
        <FlexBox>
          <Calendar
            startDate={checkIn}
            endDate={mode === 'range' ? checkOut : checkIn}
            onChange={(startDate, endDate) => {
              setCheckIn(startDate)
              setCheckOut(mode === 'range' ? endDate : startDate)
            }}
            mode={mode}
          />
        </FlexBox>
        <FlexBox>
          <div className={styles.listWrapper}>
            <div className={styles.list}>
              <List
                items={items}
                renderItem={(item) => (
                  <List.Item key={item?.label}>
                    <div className={styles.listItem}>
                      <Flex optionClass={styles.listItemContents}>
                        <FlexBox>
                          <Text size={12}>{item?.label}</Text>
                        </FlexBox>
                        <FlexBox>
                          <Text spSize={16} pcSize={14} weight="bold">
                            {item?.text}
                          </Text>
                        </FlexBox>
                      </Flex>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        </FlexBox>
      </Flex>
      {invalidDate && (
        <div className={styles.error}>
          <BaseText color="red1" pcAlign="center" size={14}>
            {t(`${translateNamespace}.invalidDate`)}
          </BaseText>
        </div>
      )}
    </Modal>
  )
}
