import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { addMonths, getMonth, parse } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { format } from 'date-fns-tz'

import { AvailabilityDescription } from './AvailabilityDescription'
import * as styles from './Schedule.module.scss'

import { useSearchQueryParams } from '../../../../hooks'
import { useScheduleDate } from '../../../../hooks/useScheduleDate'
import { getCurrentJstDateTime, getDefaultCheckInDate } from '../../../../utils'
import { Flex, FlexBox, Option, Select } from '../../../basics'
import { FullCalendar } from '../../../contents'

const translateNamespace = 'components.Schedule'

export const Schedule: React.FC = () => {
  const { t, i18n } = useTranslation()
  const {
    params: { checkInDate },
    onUpdateSearchParams,
  } = useSearchQueryParams()
  const current = getCurrentJstDateTime()
  const startDate = parse(getDefaultCheckInDate(), 'yyyy-MM-dd', current)
  const startMonth = getMonth(startDate)
  const fromDate = parse(checkInDate, 'yyyy-MM-dd', current)
  const scheduleDate = useScheduleDate(fromDate)

  const monthCount = 7
  const dateOptions = useMemo(
    () =>
      [...Array(monthCount)].map((_, i) => {
        const date = addMonths(startDate, i)

        return {
          value: format(date, 'yyyy-MM-01'),
          display:
            i18n.language === 'ja'
              ? format(date, 'yyyy/MM', { locale: ja })
              : format(date, 'MMM. yyyy'),
        }
      }),
    [i18n.language, startDate]
  )

  return (
    <div>
      <Flex optionClass={styles.filter}>
        <h2>{t(`${translateNamespace}.availability`)}</h2>
        <AvailabilityDescription className="sp" />
        <FlexBox optionClass={styles.selectBox}>
          <Select
            round
            size="small"
            isBold
            value={format(
              fromDate,
              getMonth(fromDate) === startMonth ? 'yyyy-MM-dd' : 'yyyy-MM-01'
            )}
            onChange={(event) =>
              onUpdateSearchParams({ checkInDate: event.target.value }, true)
            }
          >
            {dateOptions.map(({ value, display }) => (
              <Option key={value} value={value}>
                {display}
              </Option>
            ))}
          </Select>
        </FlexBox>
      </Flex>

      <AvailabilityDescription className="pc" />

      <div className={styles.scheduleContainer}>
        <FullCalendar
          fromDate={scheduleDate.fromDate}
          toDate={scheduleDate.toDate}
        />
      </div>
    </div>
  )
}
