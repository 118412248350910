import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { format } from 'date-fns-tz'

import * as styles from './PropertySearchPanel.module.scss'

import { TIMEZONE } from '../../../constants'
import { Button, Flex, FlexBox, Text } from '../../basics'
import {
  Area,
  AreaSearchModal,
  NumberOfGuestsModal,
  ScheduleModal,
} from '../../modals'

type NumbersOfGuests = { adults: number; children: number; infants: number }

type PropertySearchPanelProps = {
  showNumberOfGuestsSearch?: boolean
  showAreaSearch?: boolean
  onSearch: (search: {
    areas?: string[]
    numberOfGuests?: NumbersOfGuests
    schedule?: { startDate: string; endDate: string }
  }) => void
}

const translateNamespace = 'components.PropertySearchPanel'
export const PropertySearchPanel: React.FC<PropertySearchPanelProps> = ({
  showNumberOfGuestsSearch = false,
  showAreaSearch = false,
  onSearch,
}) => {
  const { t } = useTranslation()
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)
  const [numberOfGuestsModalOpen, setNumberOfGuestsModalOpen] = useState(false)
  const [areaModalOpen, setAreaModalOpen] = useState(false)
  const [schedule, setSchedule] = useState<{ startDate: Date; endDate: Date }>()
  const [numberOfGuests, setNumberOfGuests] = useState<NumbersOfGuests>()

  // TODO Refactor
  const [areas, setAreas] = useState<Area[]>()

  const displayNumberOfGuests = numberOfGuests
    ? t(`${translateNamespace}.guests`, {
        numberOfAdults: numberOfGuests.adults,
        numberOfChildren: numberOfGuests.children,
        numberOfInfants: numberOfGuests.infants,
      })
    : t(`${translateNamespace}.selectNumberOfGuests`)

  // TODO Refactor
  const displayArea = useMemo(() => {
    if (!areas) {
      return t(`${translateNamespace}.selectAccommodationArea`)
    }
    const isAllArea = areas.every((item) => areas.includes(item))
    if (isAllArea && areas.length === 4) {
      return t(`${translateNamespace}.allAreas`)
    }
    return areas.map((item) => item.label).join(', ')
  }, [t, areas])

  return (
    <React.Fragment>
      <Flex optionClass={styles.searchPanel}>
        <Flex
          optionClass={clsx(
            styles.searchPanelLeft,
            !showAreaSearch && !showNumberOfGuestsSearch && styles.halfWidth
          )}
        >
          <FlexBox optionClass={styles.searchPanelItem}>
            <Text weight="bold" pcSize={18} spSize={12}>
              {t(`${translateNamespace}.schedule`)}
            </Text>
            <button
              type="button"
              className={clsx(styles.select, !schedule && styles.gray)}
              onClick={() => setScheduleModalOpen(true)}
            >
              {schedule
                ? `${format(schedule.startDate, 'MM/dd')} ~ ${format(
                    schedule.endDate,
                    'MM/dd'
                  )}`
                : t(`${translateNamespace}.selectUsageDate`)}
            </button>
          </FlexBox>

          {showAreaSearch && (
            <React.Fragment>
              <FlexBox optionClass={styles.divider}>divider</FlexBox>
              <FlexBox optionClass={styles.searchPanelItem}>
                <Text weight="bold" pcSize={18} spSize={12}>
                  {t(`${translateNamespace}.area`)}
                </Text>
                <button
                  type="button"
                  className={clsx(styles.select, !areas && styles.gray)}
                  onClick={() => setAreaModalOpen(true)}
                >
                  {displayArea}
                </button>
              </FlexBox>
            </React.Fragment>
          )}

          {showNumberOfGuestsSearch && (
            <React.Fragment>
              <FlexBox optionClass={styles.divider}>divider</FlexBox>
              <FlexBox optionClass={styles.searchPanelItem}>
                <Text weight="bold" pcSize={18} spSize={12}>
                  {t(`${translateNamespace}.numberOfGuests`)}
                </Text>
                <button
                  type="button"
                  className={clsx(
                    styles.select,
                    !numberOfGuests && styles.gray
                  )}
                  onClick={() => setNumberOfGuestsModalOpen(true)}
                >
                  {displayNumberOfGuests}
                </button>
              </FlexBox>
            </React.Fragment>
          )}
          <FlexBox optionClass={clsx(styles.divider, 'sp')}>divider</FlexBox>
        </Flex>

        <FlexBox optionClass={styles.searchBtn}>
          <Button
            bold
            htmlType="button"
            variant="primary"
            onClick={() =>
              onSearch({
                areas: areas?.map(({ value }) => value),
                numberOfGuests,
                schedule: {
                  startDate: schedule?.startDate
                    ? format(schedule.startDate, 'yyyy-MM-dd', {
                        timeZone: TIMEZONE,
                      })
                    : '',
                  endDate: schedule?.endDate
                    ? format(schedule.endDate, 'yyyy-MM-dd', {
                        timeZone: TIMEZONE,
                      })
                    : '',
                },
              })
            }
          >
            <span className={styles.searchIcon} />
            {t(`${translateNamespace}.searchAvailability`)}
          </Button>
        </FlexBox>
      </Flex>

      {scheduleModalOpen && (
        <ScheduleModal
          checkInDate={schedule?.startDate}
          checkOutDate={schedule?.endDate}
          isOpen={scheduleModalOpen}
          onClose={() => setScheduleModalOpen(false)}
          onSearch={(startDate, endDate) => {
            setSchedule({ startDate, endDate })
            setScheduleModalOpen(false)
          }}
        />
      )}

      {areaModalOpen && (
        <AreaSearchModal
          areas={areas}
          isOpen={areaModalOpen}
          onClose={() => setAreaModalOpen(false)}
          onSearch={(items) => {
            setAreas(items)
            setAreaModalOpen(false)
          }}
        />
      )}

      {numberOfGuestsModalOpen && (
        <NumberOfGuestsModal
          adultParam={numberOfGuests?.adults}
          childParam={numberOfGuests?.children}
          infantParam={numberOfGuests?.infants}
          isOpen={numberOfGuestsModalOpen}
          onClose={() => setNumberOfGuestsModalOpen(false)}
          onSearch={(adults, children, infants) => {
            setNumberOfGuests({ adults, children, infants })
            setNumberOfGuestsModalOpen(false)
          }}
        />
      )}
    </React.Fragment>
  )
}
