import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import * as styles from './PropertyToolbar.module.scss'

import { Flex, FlexBox, Icon, Option, Select, Text } from '../../basics'
import {
  PropertySearchFilterModal,
  SearchFilterValues,
} from '../../modals/PropertySearchFilterModal'

type PropertyToolbarProps = {
  dateOptions?: { value: string; display: string }[]
  onFromDateChange?: (date: string) => void
  onSortChange: (value: string) => void
  onAreaChange?: (area: string) => void
  onFilterChange?: (area: SearchFilterValues) => void
  onStartTransition: React.TransitionStartFunction
  showSort?: boolean
}

const translateNamespace = 'components.PropertyToolbar'
export const PropertyToolbar: React.FC<PropertyToolbarProps> = ({
  dateOptions,
  onStartTransition,
  onFromDateChange,
  onAreaChange,
  onFilterChange,
  onSortChange,
  showSort = true,
}) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [openFilterModal, setOpenFilterModal] = useState(false)

  const sortOptions = [
    {
      value: 'order',
      display: t(`${translateNamespace}.recommendation`),
    },
    {
      value: 'distance',
      display: t(`${translateNamespace}.distance`),
    },
  ]

  const areaOptions = [
    {
      value: 'fvillage',
      display: t(`${translateNamespace}.fvillage`),
    },
    {
      value: 'sapporo',
      display: t(`${translateNamespace}.sapporo`),
    },
    {
      value: 'chitose',
      display: t(`${translateNamespace}.chitose`),
    },
    {
      value: 'kitahiroshima',
      display: t(`${translateNamespace}.kitahiroshima`),
    },
  ]

  return (
    <Flex optionClass={styles.filter}>
      {dateOptions && onFromDateChange && (
        <FlexBox optionClass={styles.select}>
          <Select
            round
            size="small"
            isBold
            onChange={(event) => {
              onStartTransition(() => {
                onFromDateChange(event.target.value)
              })
            }}
          >
            {dateOptions.map(({ value, display }) => (
              <Option key={value} value={value}>
                {display}
              </Option>
            ))}
          </Select>
        </FlexBox>
      )}

      {showSort && (
        <FlexBox optionClass={styles.select}>
          <Select
            round
            isBold
            size="small"
            hasArrow={false}
            value={searchParams.get('property_order') || 'order'}
            onChange={(event) => {
              onStartTransition(() => onSortChange(event.target.value))
            }}
          >
            {sortOptions.map(({ value, display }) => (
              <Option key={value} value={value}>
                {display}
              </Option>
            ))}
          </Select>
        </FlexBox>
      )}

      {onAreaChange && (
        <FlexBox optionClass={clsx(styles.select, styles.areaSelect)}>
          <Select
            round
            isBold
            size="small"
            hasArrow={false}
            value={searchParams.get('area') || ''}
            onChange={(event) => {
              onStartTransition(() => onAreaChange(event.target.value))
            }}
          >
            <option hidden value="">
              {t(`${translateNamespace}.areaFilter`)}
            </option>
            {areaOptions.map(({ value, display }) => (
              <Option key={value} value={value}>
                {display}
              </Option>
            ))}
          </Select>
        </FlexBox>
      )}

      {onFilterChange && (
        <React.Fragment>
          <FlexBox>
            <button
              type="button"
              className={styles.filterBtn}
              onClick={() => setOpenFilterModal(true)}
            >
              <Icon mark="filter" />
              <Text pcSize={16} spSize={12} weight="bold">
                {t(`${translateNamespace}.filter`)}
              </Text>
            </button>
          </FlexBox>
          <PropertySearchFilterModal
            isOpen={openFilterModal}
            onClose={() => setOpenFilterModal(false)}
            onConfirm={(values) => {
              onFilterChange(values)
              setOpenFilterModal(false)
            }}
          />
        </React.Fragment>
      )}
    </Flex>
  )
}
