import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './RoomList.module.scss'

import { RoomResponse } from '../../../../apis/fvillage'
import { PATH } from '../../../../constants'
import {
  useCreateNextPath,
  useImages,
  usePublication,
  useRoomFeature,
} from '../../../../hooks'
import { formatRoomSizeUnit, formatRoomType } from '../../../../utils'
import {
  ButtonWrapper as Button,
  Col,
  Container,
  Icon,
  MaskIcon,
  Row,
  SlideShow,
  Text,
  WhiteText,
} from '../../../basics'
import { GalleryModal } from '../../../modals'

const translateNamespace = 'components.RoomList.RoomItem'

export const RoomItem: React.FC<{ room: RoomResponse }> = ({ room }) => {
  const {
    feature,
    images,
    maxPax,
    minPax,
    publications,
    roomSize,
    roomSizeUnit,
    roomType,
  } = room
  const navigate = useNavigate()
  const {
    params: { property },
    onCreateNextPath,
  } = useCreateNextPath()
  const { t, i18n } = useTranslation()
  const publication = usePublication(i18n.language, publications)
  const [galleryInitialSlide, setGalleryInitialSlide] = useState(0)
  const [isOpenGalleryModal, toggleGalleryModal] = useState(false)

  const { canSmoke, petsAllowed, accessible } = useRoomFeature(feature)
  const itemImages = useImages(images)

  const RoomSearchButton = (
    <Button
      htmlType="button"
      position="left"
      variant="primary"
      className={styles.itemButton}
      onClick={() =>
        navigate(onCreateNextPath(PATH.rooms, { pathParams: { property } }))
      }
    >
      <MaskIcon size={24} url="/img/icon/icon-search.svg" />
      <WhiteText weight="bold">
        {t(`${translateNamespace}.availableRoomsSearch`)}
      </WhiteText>
    </Button>
  )

  return (
    <Fragment>
      {isOpenGalleryModal && (
        <GalleryModal
          isOpen={isOpenGalleryModal}
          onClose={() => toggleGalleryModal(false)}
          items={itemImages}
          initialSlide={galleryInitialSlide}
          renderItem={(item) => <img src={item.pc.src} alt={item.alt} />}
        />
      )}
      <Container>
        <Row gutterWidth={32} gutterHeight={4}>
          <Col pc={5} className={styles.itemSlideShow}>
            <SlideShow
              items={itemImages}
              renderItem={(item) => (
                <figure>
                  <img
                    src={item.pc.src}
                    alt={item.alt}
                    className="pc"
                    width="600"
                    height="400"
                  />
                  <img
                    src={item.sp.src}
                    alt={item.alt}
                    className="sp"
                    width="600"
                    height="400"
                  />
                </figure>
              )}
              onTap={(swiper) => {
                setGalleryInitialSlide(swiper.activeIndex)
                toggleGalleryModal(true)
              }}
            />
          </Col>
          <Col pc={7}>
            <div className={styles.item}>
              <div className={styles.title}>
                <Text pcSize={24} spSize={14} weight="bold">
                  {publication?.name}
                </Text>
              </div>
              <div className={styles.itemInfoContainer}>
                <div className={styles.itemInfo}>
                  <Icon mark="capacity">
                    {t(`${translateNamespace}.capacity`)}:{' '}
                    {minPax !== maxPax
                      ? t(`${translateNamespace}.availableGuestsRange`, {
                          min: minPax,
                          max: maxPax,
                        })
                      : t(`${translateNamespace}.availableGuests`, {
                          max: maxPax,
                        })}
                  </Icon>
                  <Icon mark="size">
                    {t(`${translateNamespace}.roomSize`)}:{' '}
                    {t(`${translateNamespace}.about`, {
                      number: roomSize,
                    })}
                    {formatRoomSizeUnit(roomSizeUnit)}
                  </Icon>
                  <Icon mark="bed">
                    {t(`${translateNamespace}.roomType`)}:{' '}
                    {formatRoomType(roomType)}
                  </Icon>
                  {canSmoke ? (
                    <Icon mark="smoke">
                      {t(`${translateNamespace}.smokingAllowed`)}
                    </Icon>
                  ) : (
                    <Icon mark="smokeOff">
                      {t(`${translateNamespace}.nonSmoking`)}
                    </Icon>
                  )}
                  {petsAllowed && (
                    <Icon mark="dog">
                      {t(`${translateNamespace}.petsAllowed`)}
                    </Icon>
                  )}
                  {accessible && (
                    <Icon mark="wheelchair">
                      {t(`${translateNamespace}.accessible`)}
                    </Icon>
                  )}
                </div>
              </div>
              <div className="pc">{RoomSearchButton}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={styles.itemDescription}>
            <div className={styles.itemDescriptionText}>
              <Text pcSize={14} spSize={12}>
                {publication?.description}
              </Text>
            </div>
            <div className="sp">{RoomSearchButton}</div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
