import clsx from 'clsx'

import * as styles from './NavigateLinkButton.module.scss'

import { Icon } from '../../basics/Icon'

type NavigateLinkButtonProps = {
  text: string
  arrow?: 'left' | 'right'
  onClick: () => void
}

export const NavigateLinkButton = ({
  text,
  arrow = 'right',
  onClick,
}: NavigateLinkButtonProps) => (
  <button
    type="button"
    className={clsx(
      styles.navigateLink,
      arrow === 'left' && styles.navigateLinkLeft
    )}
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
  >
    {arrow === 'right' && text}
    <div
      className={clsx(styles.linkIcon, arrow === 'left' && styles.linkIconLeft)}
    >
      <Icon mark="arrow" />
    </div>
    {arrow === 'left' && text}
  </button>
)
