import { useCallback } from 'react'

import {
  addDays,
  addMonths,
  format,
  isAfter,
  isBefore,
  lastDayOfMonth,
  subDays,
  subMonths,
} from 'date-fns'

import { getCurrentJstDateTime } from '../../utils'

export const useScheduleWeekNavigation = (prevFromDate: Date) => {
  const handlePreviousWeek = useCallback(() => {
    const fromDate = subDays(prevFromDate, 7)
    const current = getCurrentJstDateTime()
    const oneMonthAgo = subMonths(current, 1)

    if (isAfter(current, fromDate) && isBefore(current, prevFromDate))
      return format(current, 'yyyy-MM-dd')

    return format(
      isBefore(fromDate, oneMonthAgo) ? current : fromDate,
      'yyyy-MM-dd'
    )
  }, [prevFromDate])

  const handleNextWeek = useCallback(() => {
    const fromDate = addDays(prevFromDate, 7)
    const current = getCurrentJstDateTime()
    const lastDayOfSixMonthsLater = lastDayOfMonth(addMonths(current, 6))

    if (
      isAfter(fromDate, lastDayOfSixMonthsLater) ||
      isAfter(addDays(fromDate, 6), lastDayOfSixMonthsLater)
    )
      return format(subDays(lastDayOfSixMonthsLater, 6), 'yyyy-MM-dd')

    return format(fromDate, 'yyyy-MM-dd')
  }, [prevFromDate])

  return { handlePreviousWeek, handleNextWeek }
}
