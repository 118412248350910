import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import * as styles from './PropertyList.module.scss'

import {
  NavigateLinkButton,
  PropertyCard,
  PropertyModal,
} from '../../../components'
import { PATH } from '../../../constants'
import { useProperties } from '../../../hooks'

type CardProps = {
  name: string
  description: string
  descriptionDetail: string
  images: string[]
  className: string
  access?: string
  pricing?: string
  property?: string
  direction?: 'horizontal' | 'vertical'
  shortName?: string
  showFitScreenIcon?: boolean
  insideFvillage?: boolean
}

const Card: React.FC<CardProps> = ({
  name,
  description,
  descriptionDetail,
  images,
  access,
  pricing,
  className,
  property,
  showFitScreenIcon = false,
  direction = 'vertical',
  shortName,
  insideFvillage,
}) => {
  const [openedProperty, setOpenedProperty] = useState(false)
  return (
    <div className={clsx(className, styles.card)}>
      <PropertyCard
        images={images}
        title={name}
        access={access}
        pricing={pricing}
        property={property}
        direction={direction}
        showFitScreenIcon={showFitScreenIcon}
        onModalOpen={() => setOpenedProperty(true)}
      >
        <p className={styles.desc}>{description}</p>
      </PropertyCard>
      <PropertyModal
        isOpen={openedProperty}
        onClose={() => setOpenedProperty(false)}
        property={{
          images,
          title: shortName ?? '',
          access,
          pricing,
          property,
          children: descriptionDetail,
        }}
        showButton={insideFvillage}
      />
    </div>
  )
}

const translateNamespace = 'components.TopIndexFrame.propertyList'

export const PropertyList: React.FC = () => {
  const navigate = useNavigate()
  const { properties } = useProperties()
  const { t } = useTranslation()
  const fvillageProperties = properties
    .filter((property) => property.insideFvillage)
    .slice(0, 4)
  const partnerProperties = properties
    .filter((property) => !property.insideFvillage)
    .slice(0, 6)

  return (
    <React.Fragment>
      <div className={styles.properties}>
        {fvillageProperties.map(
          ({
            id,
            slug,
            name,
            descriptionDetail,
            images,
            access,
            pricing,
            shortName,
          }) =>
            name &&
            slug && (
              <Card
                name={name}
                description={descriptionDetail ?? ''}
                descriptionDetail={descriptionDetail ?? ''}
                images={images}
                access={access}
                pricing={pricing}
                property={slug}
                className={styles.property}
                key={id}
                shortName={shortName}
                direction="horizontal"
                showFitScreenIcon
                insideFvillage
              />
            )
        )}
      </div>
      <div className={styles.partners}>
        {partnerProperties.map(
          ({
            id,
            slug,
            name,
            description,
            descriptionDetail,
            images,
            access,
            pricing,
            shortName,
          }) =>
            name &&
            slug && (
              <Card
                name={name}
                description={description ?? ''}
                descriptionDetail={descriptionDetail ?? ''}
                images={images}
                access={access}
                pricing={pricing}
                property={slug}
                className={styles.partner}
                key={id}
                shortName={shortName}
                showFitScreenIcon
                insideFvillage={false}
              />
            )
        )}
      </div>

      <NavigateLinkButton
        text={t(`${translateNamespace}.viewFacilityList`)}
        onClick={() => navigate(PATH.list)}
      />
    </React.Fragment>
  )
}
