import { useCallback, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import {
  DEFAULT_ADULTS,
  DEFAULT_AREA,
  DEFAULT_BUDGET_MAX,
  DEFAULT_BUDGET_MIN,
  DEFAULT_CHILDREN,
  DEFAULT_INFANTS,
  DEFAULT_ROOMS,
  DEFAULT_TRANSACTION_ID,
  PAGE_SIZE,
} from '../../constants'
import { addJstDate, getDefaultCheckInDate } from '../../utils'

export type SearchParam = {
  checkInDate?: string
  checkOutDate?: string
  roomCount?: number
  numberOfAdults?: number
  numberOfChildren?: number
  numberOfInfants?: number
  startDate?: string // Schedule start
  page?: number
  order?: string // reservation api's order
  propertyOrder?: string // property search api's order
  roomOrder?: string // room api's order
  planOrder?: string // plan api's order
  showAll?: boolean
  facility?: boolean
  transaction?: number
  areas?: string[] // property search
  maxPrice?: number
  minPrice?: number
  equipments?: string[]
}

type Required<T> = {
  [P in keyof T]-?: T[P]
}

export const updateSearchParams = (
  searchParams: URLSearchParams,
  {
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomCount,
    startDate,
    page,
    order,
    propertyOrder,
    roomOrder,
    planOrder,
    showAll,
    facility,
    transaction,
    areas,
    maxPrice,
    minPrice,
    equipments,
  }: SearchParam
) => {
  if (checkInDate) {
    searchParams.set('check_in', checkInDate)
  }
  if (checkOutDate) {
    searchParams.set('check_out', checkOutDate)
  }
  if (typeof numberOfAdults !== 'undefined') {
    searchParams.set('adults', numberOfAdults.toString())
  }
  if (typeof numberOfChildren !== 'undefined') {
    searchParams.set('children', numberOfChildren.toString())
  }
  if (typeof numberOfInfants !== 'undefined') {
    searchParams.set('infants', numberOfInfants.toString())
  }
  if (typeof roomCount !== 'undefined') {
    searchParams.set('rooms', roomCount.toString())
  }
  if (startDate) {
    searchParams.set('start_date', startDate)
  } else {
    searchParams.delete('start_date')
  }
  if (typeof page !== 'undefined') {
    searchParams.set('page', page.toString())
  }
  if (typeof order === 'string') {
    if (order) {
      searchParams.set('order', order)
    } else {
      searchParams.delete('order')
    }
  }
  if (typeof propertyOrder === 'string') {
    if (propertyOrder) {
      searchParams.set('property_order', propertyOrder)
    } else {
      searchParams.delete('property_order')
    }
  }
  if (roomOrder) {
    searchParams.set('room_order', roomOrder)
  }
  if (planOrder) {
    searchParams.set('plan_order', planOrder)
  }
  if (typeof showAll !== 'undefined') {
    searchParams.set('show_all', showAll.toString())
  }
  if (typeof facility !== 'undefined') {
    searchParams.set('facility', facility.toString())
  }
  if (typeof transaction !== 'undefined') {
    searchParams.set('transaction', transaction.toString())
  }
  if (areas) {
    if (!areas.length) {
      searchParams.delete('area')
    } else {
      areas.forEach((area, i) =>
        i === 0
          ? searchParams.set('area', area)
          : searchParams.append('area', area)
      )
    }
  }
  if (typeof maxPrice === 'number') {
    if (maxPrice) {
      searchParams.set('max_price', maxPrice.toString())
    } else {
      searchParams.delete('max_price')
    }
  }
  if (typeof minPrice === 'number') {
    if (minPrice) {
      searchParams.set('min_price', minPrice.toString())
    } else {
      searchParams.delete('min_price')
    }
  }
  if (equipments) {
    if (!equipments.length) {
      searchParams.delete('equipment')
    } else {
      equipments.forEach((equipment, i) =>
        i === 0
          ? searchParams.set('equipment', equipment)
          : searchParams.append('equipment', equipment)
      )
    }
  }

  return searchParams
}

const useQueryParams = (
  searchParams: URLSearchParams,
  pathname: string
): Required<SearchParam> =>
  useMemo(() => {
    const checkInDate = getDefaultCheckInDate(pathname)

    const checkOutDate =
      searchParams.get('facility') === 'true'
        ? checkInDate
        : addJstDate(checkInDate, 1)

    return {
      checkInDate: searchParams.get('check_in') ?? checkInDate,
      checkOutDate: searchParams.get('check_out') ?? checkOutDate,
      // Default room number
      roomCount: searchParams.get('rooms')
        ? Number(searchParams.get('rooms'))
        : DEFAULT_ROOMS,
      // Default number of adults
      numberOfAdults: searchParams.get('adults')
        ? Number(searchParams.get('adults'))
        : DEFAULT_ADULTS,
      // Default number of children
      numberOfChildren: searchParams.get('children')
        ? Number(searchParams.get('children'))
        : DEFAULT_CHILDREN,
      // Default number of infants
      numberOfInfants: searchParams.get('infants')
        ? Number(searchParams.get('infants'))
        : DEFAULT_INFANTS,
      startDate:
        searchParams.get('start_date') ?? getDefaultCheckInDate(pathname),
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
      order: searchParams.get('order') ?? '',
      propertyOrder: searchParams.get('property_order') ?? '',
      roomOrder: searchParams.get('room_order') ?? '',
      planOrder: searchParams.get('plan_order') ?? '',
      showAll: searchParams.get('show_all') === 'true',
      facility: searchParams.get('facility') === 'true',
      transaction: searchParams.get('transaction')
        ? Number(searchParams.get('transaction'))
        : DEFAULT_TRANSACTION_ID,
      areas:
        searchParams.getAll('area').length > 0
          ? searchParams.getAll('area')
          : DEFAULT_AREA,
      maxPrice: searchParams.get('max_price')
        ? Number(searchParams.get('max_price'))
        : DEFAULT_BUDGET_MAX,
      minPrice: searchParams.get('min_price')
        ? Number(searchParams.get('min_price'))
        : DEFAULT_BUDGET_MIN,
      equipments: searchParams.getAll('equipment'),
    }
  }, [searchParams, pathname])

export const useSearchQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  const queryParams = useQueryParams(searchParams, location.pathname)

  const onUpdateSearchParams = useCallback(
    (params: SearchParam, replace = false) => {
      setSearchParams(updateSearchParams(searchParams, params), {
        replace,
      })
    },
    [searchParams, setSearchParams]
  )

  const offset = useMemo(
    () => (queryParams.page <= 0 ? 0 : queryParams.page - 1) * PAGE_SIZE,
    [queryParams.page]
  )

  return { params: queryParams, onUpdateSearchParams, offset }
}
