import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './PropertySearchFilterModal.module.scss'

import { useSearchQueryParams } from '../../../hooks'
import { AREA_LIST } from '../../../utils'
import {
  Button,
  Checkbox,
  Flex,
  FlexBox,
  Modal,
  Radio,
  RadioGroup,
  Text,
} from '../../basics'
import { BudgetSelect } from '../../contents'

export type SearchFilterValues = {
  area: string
  facilities: string[]
  minPrice: string
  maxPrice: string
}

type PropertySearchFilterModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: (values: SearchFilterValues) => void
}

const translateNamespace = 'components.PropertySearchFilterModal'
export const PropertySearchFilterModal: React.FC<
  PropertySearchFilterModalProps
> = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation()
  const { params } = useSearchQueryParams()
  const {
    areas: areasParams,
    equipments,
    maxPrice: maxPriceParams,
    minPrice: minPriceParams,
  } = params
  const [area, setArea] = useState(
    AREA_LIST.every((a) => areasParams.includes(a)) ? 'all' : areasParams[0]
  )
  const [selectedFacilities, setSelectedFacilities] =
    useState<string[]>(equipments)
  const [minPrice, setMinPrice] = useState(
    minPriceParams ? `${minPriceParams}` : ''
  )
  const [maxPrice, setMaxPrice] = useState(
    maxPriceParams ? `${maxPriceParams}` : ''
  )

  const changeFacility = useCallback(
    (value: string) => {
      if (selectedFacilities.includes(value)) {
        setSelectedFacilities((prevState) =>
          prevState.filter((prevValue) => prevValue !== value)
        )
        return
      }
      setSelectedFacilities((prevState) => [...prevState, value])
    },
    [selectedFacilities]
  )

  const facilities = [
    {
      label: t(`${translateNamespace}.parking`),
      value: 'parking',
    },
    {
      label: t(`${translateNamespace}.wifi`),
      value: 'free_wifi',
    },
    // {
    //   label: t(`${translateNamespace}.smoking`),
    //   value: 'smoking',
    // },
    // {
    //   label: t(`${translateNamespace}.nonSmoking`),
    //   value: 'no_smoking',
    // },
    {
      label: t(`${translateNamespace}.hotSpring`),
      value: 'spa',
    },
    {
      label: t(`${translateNamespace}.restaurant`),
      value: 'restaurant',
    },
  ]

  const areas = [
    { label: t(`${translateNamespace}.allAreas`), value: 'all' },
    { label: t(`${translateNamespace}.fvillage`), value: 'fvillage' },
    { label: t(`${translateNamespace}.sapporo`), value: 'sapporo' },
    { label: t(`${translateNamespace}.chitose`), value: 'chitose' },
    { label: t(`${translateNamespace}.kitahiroshima`), value: 'kitahiroshima' },
  ]

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`${translateNamespace}.title`)}
      leftButton={
        <Button htmlType="button" variant="secondary" onClick={onClose}>
          {t(`${translateNamespace}.close`)}
        </Button>
      }
      centerButton={
        <Button
          htmlType="button"
          outline
          variant="secondary"
          onClick={() => {
            setArea('all')
            setSelectedFacilities([])
            setMaxPrice('')
            setMinPrice('')
          }}
        >
          {t(`${translateNamespace}.clear`)}
        </Button>
      }
      rightButton={
        <Button
          htmlType="button"
          onClick={() =>
            onConfirm({
              area,
              facilities: selectedFacilities,
              minPrice,
              maxPrice,
            })
          }
        >
          {t(`${translateNamespace}.confirm`)}
        </Button>
      }
    >
      <Flex optionClass={styles.wrapper}>
        <FlexBox>
          <Text size={16} weight="bold">
            {t(`${translateNamespace}.area`)}
          </Text>
          <RadioGroup
            isRadioButton
            direction="horizontal"
            onChange={(e) => setArea(e.target.value)}
            value={area}
            className={styles.areaRadioGroup}
          >
            {areas.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </FlexBox>

        <FlexBox>
          <Text size={16} weight="bold">
            {t(`${translateNamespace}.facility`)}
          </Text>
          <Flex optionClass={styles.facilityWrapper}>
            {facilities.map(({ label, value }) => (
              <FlexBox key={value}>
                <Checkbox
                  isCustom
                  name={value}
                  checked={selectedFacilities.includes(value)}
                  label={label}
                  onChange={() => changeFacility(value)}
                />
              </FlexBox>
            ))}
          </Flex>
        </FlexBox>

        <FlexBox>
          <Text size={16} weight="bold">
            {t(`${translateNamespace}.reservationConditions`)}
          </Text>
          <BudgetSelect
            className={styles.reservationConditions}
            budgetMax={maxPrice}
            budgetMin={minPrice}
            onBudgetMinChange={setMinPrice}
            onBudgetMaxChange={setMaxPrice}
          />
        </FlexBox>
      </Flex>
    </Modal>
  )
}
