import React from 'react'

import clsx from 'clsx'

import * as styles from './Hero.module.scss'

type HeroProps = {
  imageUrl?: string
  videoUrl?: string
  title?: string
  className?: string
  isWebm?: boolean
}

export const Hero: React.FC<HeroProps> = ({
  imageUrl,
  videoUrl,
  title,
  className,
  isWebm = false,
}) => (
  <React.Fragment>
    {imageUrl && (
      <div className={clsx(styles.imageWrapper, className)}>
        <p className={clsx(styles.title)}>{title}</p>
        <img
          src={imageUrl}
          alt="hero"
          key={imageUrl}
          width="100%"
          height="100%"
        />
      </div>
    )}

    {videoUrl && (
      <video
        autoPlay
        muted
        loop
        playsInline
        width="100%"
        className={className}
        key={videoUrl}
      >
        <source
          src={videoUrl}
          type={isWebm ? 'video/webm; codecs="vp8, vorbis"' : 'video/mp4'}
        />
      </video>
    )}
  </React.Fragment>
)
