import React, { useState } from 'react'

import * as styles from './PropertyDetail.module.scss'

import { usePropertyDetailApi, usePropertyProvider } from '../../../../hooks'
import { Button, MaskIcon } from '../../../basics'
import { Loader } from '../../../contents'
import { Access } from '../Access'
import { Activity } from '../Activity'
import { Amenity } from '../Amenity'
import { CatchCopy } from '../CatchCopy'
import { Facility } from '../Facility'
import { Faq } from '../Faq'
import { Guidline } from '../Guidline'
import { Information } from '../Information'
import { Meal } from '../Meal'
import { Pickup } from '../Pickup'
import { RoomList } from '../RoomList'
import { Schedule } from '../Schedule'

export const PropertyDetail: React.FC = () => {
  // always showMore is true
  const [showMore, setShowMore] = useState(true)
  const { slug } = usePropertyProvider()
  const { data, isLoading } = usePropertyDetailApi(slug)

  if (isLoading) return <Loader />

  return (
    <React.Fragment>
      <div className={styles.centerArea}>
        <CatchCopy />
        <Schedule />
      </div>

      {!!data?.pickups?.length && (
        <div className={styles.pickupContainer}>
          <div className={styles.centerArea}>
            <Pickup items={data.pickups} />
          </div>
        </div>
      )}

      <div className={styles.centerArea}>
        <div className={styles.roomListContainer}>
          <RoomList />
        </div>

        {!!data?.amenities?.length && <Amenity amenities={data.amenities} />}

        {!!data?.guidelines?.length && (
          <Guidline guidelines={data.guidelines} />
        )}

        {!!data?.access?.length && <Access access={data.access} />}

        {!showMore && (
          <Button
            htmlType="button"
            variant="primary"
            className={styles.viewMoreBtn}
            onClick={() => setShowMore(true)}
          >
            <span className={styles.viewMoreBtnText}>もっと見る</span>
            <span className={styles.viewMoreBtnIcon}>
              <MaskIcon size={24} url="/img/icon/icon-arrow-white.svg" />
            </span>
          </Button>
        )}

        {showMore && (
          <React.Fragment>
            {!!data?.meals?.length && <Meal meals={data.meals} />}
            {!!data?.activities?.length && (
              <Activity activities={data.activities} />
            )}
            {!!data?.spaces?.length && <Facility facilities={data.spaces} />}
          </React.Fragment>
        )}

        {!!data?.information?.length && (
          <Information information={data?.information} />
        )}
      </div>

      {!!data?.faqs?.length && (
        <div className={styles.faqsContainer}>
          <div className={styles.centerArea}>
            <Faq faqs={data?.faqs} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
