import React, { useEffect, useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import * as styles from './PropertyListFrame.module.scss'

import {
  Hero,
  Layout,
  Loader,
  NavigateLinkButton,
  NotFound,
  Notification,
  Pagination,
  PropertyList,
  PropertyToolbar,
} from '../../components'
import { Order, PAGE_SIZE } from '../../constants'
import {
  useNotificationsApi,
  useProperties,
  useSearchQueryParams,
} from '../../hooks'
import { filterAreas, filterEquipments } from '../../utils'

const translateNamespace = 'frames.PropertyListFrame'
export const PropertyListFrame: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    params: { areas, equipments, minPrice, maxPrice, propertyOrder, page },
    onUpdateSearchParams,
    offset,
  } = useSearchQueryParams()
  const [isPending, startTransition] = useTransition()

  const key = JSON.stringify({
    areas,
    equipments,
    minPrice,
    maxPrice,
    offset,
    order: propertyOrder,
  })
  const {
    properties: data,
    isLoading,
    data: { count },
  } = useProperties({
    // Use key to differentiate the cache in useProperties under the layout
    offset,
    limit: PAGE_SIZE,
    key: `/hotels?${key}`,
    hotelAreas: filterAreas(areas),
    minPrice: searchParams.get('min_price') ? minPrice : undefined,
    maxPrice: searchParams.get('max_price') ? maxPrice : undefined,
    order: searchParams.get('property_order')
      ? (propertyOrder as Order)
      : undefined,
    equipments: filterEquipments(equipments),
  })
  const [totalCount, setTotalCount] = useState(count)
  const { data: notifications } = useNotificationsApi()

  const properties = data.map((p) => ({
    id: p.id,
    slug: p.slug,
    name: p.name,
    shortName: p.shortName,
    description: p.description,
    descriptionDetail: p.descriptionDetail,
    images: p.images,
    access: p.access,
    pricing: p.pricing,
    insideFvillage: p.insideFvillage,
  }))

  useEffect(() => {
    // Save it to prevent the pagination from displaying blank when re-requesting.
    if (typeof count === 'number') setTotalCount(count)
  }, [count])

  return (
    <Layout>
      <Hero imageUrl="/img/hero.png" title={t(`${translateNamespace}.title`)} />
      <div className={styles.centerArea}>
        {notifications && notifications?.length > 0 && (
          <div className={styles.notificationArea}>
            <Notification notifications={notifications} />
          </div>
        )}
        <PropertyToolbar
          onFilterChange={(values) => {
            const params = {
              areas: filterAreas([values.area]),
              minPrice: Number(values.minPrice),
              maxPrice: Number(values.maxPrice),
              equipments: values.facilities.filter((item) => item),
              propertyOrder,
            }
            onUpdateSearchParams(
              searchParams.get('page') ? { ...params, page: 1 } : params
            )
          }}
          onStartTransition={startTransition}
          onSortChange={(value: string) => {
            const params = {
              propertyOrder: value === 'distance' ? 'distance' : 'order',
            }
            onUpdateSearchParams(
              searchParams.get('page') ? { ...params, page: 1 } : params
            )
          }}
          showSort={false}
        />

        <div style={{ minHeight: '57vh' }}>
          {isLoading || isPending || !properties ? (
            <div className={styles.loading}>
              <Loader />
            </div>
          ) : (
            <div>
              {properties.length > 0 ? (
                <PropertyList properties={properties} />
              ) : (
                <div style={{ margin: '4rem 0' }}>
                  <NotFound
                    title={t(`empty.searchNotFound.attention`)}
                    description1={t(`empty.searchNotFound.direction`)}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {!!totalCount && (
          <Pagination
            pageSize={PAGE_SIZE}
            totalPage={totalCount}
            currentPage={page}
            onChange={(p) => onUpdateSearchParams({ page: p, propertyOrder })}
          />
        )}

        <div className={styles.redirectButton}>
          <NavigateLinkButton
            arrow="left"
            text={t(`${translateNamespace}.toTop`)}
            onClick={() => navigate('/')}
          />
        </div>
      </div>
    </Layout>
  )
}
