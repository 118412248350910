import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './SearchItem.module.scss'

import { HotelRoomResponse } from '../../../apis/fvillage'
import { PATH } from '../../../constants'
import { useCreateNextPath, usePublication } from '../../../hooks'
import { ButtonWrapper as Button, Col, Container, Row } from '../../basics'
import { PropertyModal } from '../../modals'
import { RoomSlideShow } from '../RoomSlideShow'

type SearchItemProps = HotelRoomResponse

const translateNamespace = 'components.SearchItem'

export const SearchItem: React.FC<SearchItemProps> = ({
  publications,
  images = [],
  rooms = [],
  slug,
}) => {
  const { t, i18n } = useTranslation()
  const [openedProperty, setOpenedProperty] = useState(false)
  const publication = usePublication(i18n.language, publications)

  const { onCreateNextPath } = useCreateNextPath()
  const navigate = useNavigate()

  // react-grid-system's useScreenClass could not correctly determine
  // when drawing in chrome's devTools mobile mode,
  // so use react-use's useMedia to determine if the screen is mobile or not.
  // (Detect xl even if screen width is 390px)

  return (
    <Fragment>
      <PropertyModal
        isOpen={!!openedProperty}
        onClose={() => setOpenedProperty(false)}
        property={{
          images: images?.map(({ url }) => url ?? '') ?? [],
          title: publication?.shortName ?? '',
          access: publication?.access,
          pricing: publication?.pricing,
          property: slug,
          children: publication?.descriptionDetail,
        }}
        showButton={false}
      />
      <Container>
        <Row>
          <Col>
            <h2 className={styles.title}>{publication?.name}</h2>
            <div className={styles.desc}>
              <p>
                {publication &&
                  publication.description &&
                  publication.description}
              </p>
              <Button
                htmlType="button"
                outline
                size="smallWide"
                variant="secondary"
                position="center"
                onClick={() => setOpenedProperty(true)}
              >
                {t(`${translateNamespace}.detail`)}
              </Button>
            </div>
            <div className={styles.button}>
              <Button
                htmlType="button"
                variant="primary"
                size="full"
                position="right"
                onClick={() => {
                  navigate(
                    onCreateNextPath(PATH.rooms, {
                      pathParams: {
                        property: slug,
                      },
                    })
                  )
                }}
              >
                {t(`${translateNamespace}.selectProperty`)}
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fragment>
              {rooms.length > 0 && (
                <h3 className={styles.roomTitle}>
                  {t(`${translateNamespace}.room`)}
                </h3>
              )}
              <RoomSlideShow rooms={rooms} property={slug ?? ''} />
            </Fragment>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
