import React, { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './RoomItem.module.scss'

import {
  ImageResponse,
  PublicationResponse,
  RoomResponse,
} from '../../../apis/fvillage'
import { PATH } from '../../../constants'
import {
  useCreateNextPath,
  useImages,
  usePropertyProvider,
  usePublication,
  useRoomFeature,
  useSearchQueryParams,
} from '../../../hooks'
import { formatRoomSizeUnit, formatRoomType } from '../../../utils'
import {
  BaseText,
  ButtonWrapper as Button,
  Col,
  Container,
  Icon,
  Row,
  SlideShow,
} from '../../basics'
import { GalleryModal, RoomDetailModal } from '../../modals'

type RoomItemProps = RoomResponse

type UseDefaultValuesProps = {
  feature: string
  images: ImageResponse[] | undefined
  publications: PublicationResponse[] | undefined
}

const translateNamespace = 'components.RoomItem'

const useDefaultValues = ({
  feature,
  images,
  publications,
}: UseDefaultValuesProps) => {
  const { i18n } = useTranslation()

  const publication = usePublication(i18n.language, publications)
  const { canSmoke, petsAllowed, accessible } = useRoomFeature(feature)
  const itemImages = useImages(images)

  return useMemo(
    () => ({
      canSmoke,
      petsAllowed,
      accessible,
      itemImages,
      roomCategory: publication?.name ?? '',
    }),
    [canSmoke, petsAllowed, accessible, itemImages, publication?.name]
  )
}

export const RoomItem: React.FC<RoomItemProps> = ({
  feature,
  images,
  maxPax,
  minPax,
  minPrice,
  publications,
  roomSize,
  roomSizeUnit,
  roomType,
  slug,
  canReserve,
}) => {
  const { t, i18n } = useTranslation()
  const { isHotel } = usePropertyProvider()
  const [isOpenDetailModal, toggleOpenDetailModal] = useState(false)
  const [isOpenGalleryModal, toggleGalleryModal] = useState(false)
  const [galleryInitialSlide, setGalleryInitialSlide] = useState(0)
  const publication = usePublication(i18n.language, publications)
  const { params } = useSearchQueryParams()
  const { numberOfAdults, numberOfChildren, numberOfInfants, roomCount } =
    params

  const { onCreateNextPath } = useCreateNextPath()
  const navigate = useNavigate()

  const { canSmoke, petsAllowed, accessible, itemImages, roomCategory } =
    useDefaultValues({
      feature,
      images,
      publications,
    })
  // minPrice 0 means "stop selling", so not available
  const isAvailable = !!slug && !!canReserve && minPrice > 0

  const nextLabel = isHotel
    ? t(`${translateNamespace}.selectRoom`)
    : t(`${translateNamespace}.selectTicket`)

  return (
    <Fragment>
      {isOpenDetailModal && (
        <RoomDetailModal
          isOpen={isOpenDetailModal}
          images={images}
          onClose={() => toggleOpenDetailModal(false)}
          roomCategoryName={publication?.name ?? ''}
          description={publication?.description ?? ''}
          content={publication?.descriptionDetail ?? ''}
          canSmoke={canSmoke}
          petsAllowed={petsAllowed}
          accessible={accessible}
          minPax={minPax}
          maxPax={maxPax}
          roomSize={roomSize}
          roomSizeUnit={roomSizeUnit}
          roomType={roomType}
          isHotel={isHotel ?? false}
        />
      )}
      {isOpenGalleryModal && (
        <GalleryModal
          isOpen={isOpenGalleryModal}
          onClose={() => toggleGalleryModal(false)}
          items={itemImages}
          initialSlide={galleryInitialSlide}
          renderItem={(item) => <img src={item.pc.src} alt={item.alt} />}
        />
      )}
      <Container>
        <Row gutterWidth={20} gutterHeight={10}>
          <Col pc={6}>
            <SlideShow
              items={itemImages}
              renderItem={(item) => (
                <figure>
                  <img
                    src={item.pc.src}
                    alt={item.alt}
                    className="pc"
                    width="600"
                    height="400"
                  />
                  <img
                    src={item.sp.src}
                    alt={item.alt}
                    className="sp"
                    width="600"
                    height="400"
                  />
                </figure>
              )}
              onTap={(swiper) => {
                setGalleryInitialSlide(swiper.activeIndex)
                toggleGalleryModal(true)
              }}
            />
          </Col>
          <Col pc={6}>
            <div className={styles.item}>
              <div className={styles.itemName}>
                <h6>{roomCategory}</h6>
              </div>
              {isHotel && (
                <div className={styles.itemInfo}>
                  <Icon mark="capacity">
                    {t(`${translateNamespace}.capacity`)}:{' '}
                    {minPax !== maxPax
                      ? t(`${translateNamespace}.availableGuestsRange`, {
                          min: minPax,
                          max: maxPax,
                        })
                      : t(`${translateNamespace}.availableGuests`, {
                          max: maxPax,
                        })}
                  </Icon>
                  <Icon mark="size">
                    {t(`${translateNamespace}.roomSize`)}:{' '}
                    {t(`${translateNamespace}.about`, {
                      number: roomSize,
                    })}
                    {formatRoomSizeUnit(roomSizeUnit)}
                  </Icon>
                  <Icon mark="bed">
                    {t(`${translateNamespace}.roomType`)}:{' '}
                    {formatRoomType(roomType)}
                  </Icon>
                  {canSmoke ? (
                    <Icon mark="smoke">
                      {t(`${translateNamespace}.smokingAllowed`)}
                    </Icon>
                  ) : (
                    <Icon mark="smokeOff">
                      {t(`${translateNamespace}.nonSmoking`)}
                    </Icon>
                  )}
                  {petsAllowed && (
                    <Icon mark="dog">
                      {t(`${translateNamespace}.petsAllowed`)}
                    </Icon>
                  )}
                  {accessible && (
                    <Icon mark="wheelchair">
                      {t(`${translateNamespace}.accessible`)}
                    </Icon>
                  )}
                </div>
              )}
              {/* {isAvailable && minPrice > 0 && ( */}
              <div className={styles.itemPrice}>
                <div className={styles.roomsAndGuests}>
                  <BaseText color="gray3" size={12}>
                    {t(`${translateNamespace}.guests`, {
                      numberOfAdults,
                      numberOfChildren,
                      numberOfInfants,
                    })}
                    {isHotel &&
                      `・${t(`${translateNamespace}.rooms`, {
                        rooms: roomCount,
                      })}`}
                  </BaseText>
                </div>
                <div className={styles.price}>
                  {minPrice > 0 ? (
                    <React.Fragment>
                      {t(`${translateNamespace}.price`)}
                      <b className={styles.num}>{minPrice.toLocaleString()}</b>
                      {t(`${translateNamespace}.yen`)}
                    </React.Fragment>
                  ) : (
                    <b className={styles.notAvailable}>
                      {t('components.ScheduleTable.notAvailable')}
                    </b>
                  )}
                </div>
              </div>
              {/* )} */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.desc}>
              <p>
                {publication &&
                  publication.description &&
                  publication.description}
              </p>
              <Button
                htmlType="button"
                outline
                size="smallWide"
                variant="secondary"
                position="center"
                onClick={() => toggleOpenDetailModal(true)}
              >
                {t(`${translateNamespace}.detail`)}
              </Button>
            </div>
            <div className={styles.button}>
              <Button
                htmlType="button"
                variant="primary"
                size="full"
                position="right"
                disabled={!isAvailable}
                onClick={() => {
                  navigate(
                    onCreateNextPath(PATH.plans, {
                      pathParams: { room: slug },
                    })
                  )
                }}
              >
                {isAvailable
                  ? nextLabel
                  : t(`${translateNamespace}.notAvailable`)}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
