import React from 'react'
import { useTranslation } from 'react-i18next'

import dompurify from 'dompurify'

import * as styles from './Information.module.scss'

import { HotelDetail } from '../../../../apis/fvillage'
import { getPublication } from '../../../../hooks'
import { Accordion } from '../../../basics'

type InformationProps = {
  information: HotelDetail[]
}

const translateNamespace = 'components.Information'
export const Information: React.FC<InformationProps> = ({ information }) => {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.information}>
      <h2>{t(`${translateNamespace}.title`)}</h2>

      {information.map(({ publications }, index) => {
        const publication = getPublication(i18n.language, publications ?? [])

        if (!publication) return null

        return (
          <Accordion
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            pcSize={20}
            spSize={14}
            subTitle=""
            accordionIcon="arrow"
            title={`${String(index + 1).padStart(2, '0')} ${publication?.name}`}
            className={styles.accordionContainer}
          >
            <div
              className={styles.accordionContent}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(
                  (publication?.descriptionDetail ?? '')
                    .replace(/<\/div><div>/gi, '')
                    .replace(/<br\s*\/?>/gi, '\r\n')
                    .trim(),
                  {
                    ADD_ATTR: ['target'],
                  }
                ),
              }}
            />
          </Accordion>
        )
      })}
    </div>
  )
}
