import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'

export const usePropertyDetailAvailabilitiesApi = (
  property?: string,
  fromDate?: string,
  toDate?: string
) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    property
      ? `/hotels/${property}/availabilities${JSON.stringify({
          fromDate,
          toDate,
        })}`
      : null,
    property
      ? () =>
          propertyApi
            .getHotelAvailabilities(property, fromDate, toDate, options)
            .then((res) => res.data)
      : () => undefined,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
