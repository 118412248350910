import { useMemo } from 'react'

import i18n from '../../i18n'
import { UsePropertiesApiProps, usePropertiesApi } from '../usePropertiesApi'
import { getPublication } from '../usePublication'

export const useProperties = (props?: UsePropertiesApiProps) => {
  const {
    data: res,
    isLoading,
    mutate,
  } = usePropertiesApi({
    isHotel: true,
    ...props,
  })
  const { language } = i18n

  const properties = res?.results

  const data = useMemo(() => {
    if (!Array.isArray(properties)) {
      return []
    }

    return properties.map((property) => {
      const publication = getPublication(language, property.publications ?? [])
      const images = Array.isArray(property.images)
        ? property.images
            .filter(({ isHeroPc, isHeroSp }) => !isHeroPc && !isHeroSp)
            .map((image) => image.url ?? '')
        : []

      return {
        ...publication,
        images,
        id: property.id,
        slug: property.slug,
        officialUrl: property.officialUrl,
        insideFvillage: property.insideFvillage,
      }
    })
  }, [properties, language])

  return { properties: data, isLoading, mutate, data: res ?? {} }
}
