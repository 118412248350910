import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PropertyList } from './PropertyList'
import { ReservationButton } from './ReservationButton'
import * as styles from './TopIndex.module.scss'

import {
  Banner,
  BannerHurusatoNouzeiBig,
  BannerHurusatoNouzeiSmall,
  Hero,
  Layout,
  NavigateLinkButton,
  News,
  Notification,
  PropertySearchPanel,
  ScheduleTable,
} from '../../components'
import { PATH } from '../../constants'
import {
  useCreateNextPath,
  useNewsApi,
  useNotificationsApi,
  useSearchQueryParams,
} from '../../hooks'

const translateNamespace = 'components.TopIndexFrame.ScheduleTable'

export const TopIndexFrame = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const { i18n } = useTranslation()
  const { data: notifications } = useNotificationsApi()
  const { data: news } = useNewsApi()

  return (
    <Layout>
      <div className={styles.topBannerArea}>
        <Hero
          videoUrl={`/videos/top_movie_sp_${i18n.language}.webm`}
          className={styles.sp}
          isWebm
        />
        <Hero
          videoUrl={`/videos/top_movie_pc_${i18n.language}.mp4`}
          className={styles.pc}
        />
        <div className={styles.propertySearchPanel}>
          <PropertySearchPanel
            showAreaSearch
            onSearch={({ areas, schedule: { endDate, startDate } = {} }) => {
              navigate(
                onCreateNextPath(PATH.search, {
                  queryParams: {
                    areas,
                    checkInDate: startDate,
                    checkOutDate: endDate,
                  },
                })
              )
            }}
          />
        </div>
        <Banner
          image="/img/banner.png"
          link="https://www.hkdballpark.com/news/636/"
          position={{ right: 60, bottom: 290 }}
          className={styles.bannerCard}
        />
      </div>

      {((notifications && notifications?.length > 0) ||
        (news && news?.contentJa)) && (
        <div className={styles.notificationArea}>
          {notifications && notifications.length > 0 && (
            <Notification notifications={notifications} />
          )}
          {news && news.contentJa && <News news={news} />}
        </div>
      )}
      <div className={styles.centerArea}>
        <PropertyList />
      </div>
      <div className={styles.centerArea}>
        <div className={styles.scheduleTableArea}>
          <ScheduleTable />
        </div>
        <div className={styles.viewAvailabilityList}>
          <NavigateLinkButton
            text={t(`${translateNamespace}.viewAvailabilityList`)}
            onClick={() => {
              navigate(
                onCreateNextPath(PATH.schedule, {
                  queryParams: {
                    startDate: queryParams?.startDate ?? undefined,
                  },
                })
              )
            }}
          />
        </div>
        <div className={styles.bannerArea}>
          <div className={styles.pc}>
            <BannerHurusatoNouzeiBig />
          </div>
          <div className={styles.sp}>
            <BannerHurusatoNouzeiSmall />
          </div>
        </div>
      </div>
      <ReservationButton />
    </Layout>
  )
}
