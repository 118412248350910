import { useMemo } from 'react'

import { HotelPublicationImage } from '../../apis/fvillage'

export const useHeroImage = (images: HotelPublicationImage[]) =>
  useMemo(() => {
    const commonImages = images.filter(
      ({ isHeroPc, isHeroSp }) =>
        (isHeroPc && isHeroSp) || (!isHeroPc && !isHeroSp)
    )
    const pc = images.find(({ isHeroPc, isHeroSp }) => isHeroPc && !isHeroSp)
    const sp = images.find(({ isHeroPc, isHeroSp }) => !isHeroPc && isHeroSp)

    return {
      images: commonImages,
      image: commonImages.at(0) ? commonImages.at(0)?.url : undefined,
      pc: pc ? pc.url : undefined,
      sp: sp ? sp.url : undefined,
    }
  }, [images])
