import React from 'react'

import { isAxiosError } from 'axios'

import { PropertyContext } from './PropertyContext'

import { PATH } from '../../../constants'
import { usePropertyApi } from '../../../hooks/usePropertyApi'
import { useRouteParams } from '../../../hooks/useRouteParams'

type PropertyProviderProps = {
  children: React.ReactNode
}

export const PropertyProvider: React.FC<PropertyProviderProps> = ({
  children,
}) => {
  const { property } = useRouteParams()
  const { data, isLoading, error } = usePropertyApi(property)
  if (error && isAxiosError(error)) {
    // If any error raised, redirect to 404
    // When we use react-router-dom's useNavigate, following Warning
    // has been shown.
    // Use window.location.assign since it only displays the 404 page,
    //
    // ```
    // Warning: Cannot update a component (`BrowserRouter`) while rendering
    // a different component (`PropertyProvider`).
    // To locate the bad setState() call inside `PropertyProvider`,
    // follow the stack trace as described in
    // https://reactjs.org/link/setstate-in-render
    // ```
    window.location.assign(PATH.errors.notFoundError)
  }
  if (isLoading || !data) {
    return null
  }

  return (
    <PropertyContext.Provider value={data}>{children}</PropertyContext.Provider>
  )
}
