import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import * as styles from './OptionPlanPanel.module.scss'

import {
  usePlanApi,
  usePropertyApi,
  usePublication,
  useRoomApi,
  useRouteParams,
  useSearchQueryParams,
} from '../../../hooks'
import { formatJstDate } from '../../../utils'
import { Col, Container, Row, Text } from '../../basics'
import { Panel } from '../Panel'

const translateNamespace = 'components.Panel'

type PanelItemProps = {
  label: React.ReactNode
  value: React.ReactNode
}

const PanelItem: React.FC<PanelItemProps> = ({ label, value }) => (
  <div className={styles.panelItem}>
    <Container>
      <Row>
        <Col pc={12} sp={12}>
          <Text size={12} pcAlign="left" spAlign="left">
            {label}
          </Text>
        </Col>
        <Col pc={12} sp={12}>
          <Text size={14} weight="bold" pcAlign="left" spAlign="left">
            {value}
          </Text>
        </Col>
      </Row>
    </Container>
  </div>
)

export const OptionPlanPanel: React.FC = () => {
  const { t, i18n } = useTranslation()
  const {
    property: propertySlug,
    room: roomSlug,
    plan: planSlug,
  } = useRouteParams()
  const { params } = useSearchQueryParams()
  const {
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomCount,
  } = params

  const { data: property } = usePropertyApi(propertySlug ?? '')
  const { data: roomResponse } = useRoomApi({
    property: propertySlug,
    room: roomSlug,
  })
  const { data: planResponse } = usePlanApi({
    property: propertySlug,
    room: roomSlug,
    plan: planSlug,
  })

  const publication = usePublication(i18n.language, property?.publications)
  const room = usePublication(i18n.language, roomResponse?.publications)
  const plan = usePublication(i18n.language, planResponse?.publications)

  const items = [
    <PanelItem
      label={t(`${translateNamespace}.roomType`)}
      value={room?.name ?? ''}
    />,
    <PanelItem
      label={t(`${translateNamespace}.checkIn`)}
      value={formatJstDate(
        checkInDate,
        i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
      )}
    />,
    <PanelItem
      label={t(`${translateNamespace}.checkOut`)}
      value={formatJstDate(
        checkOutDate,
        i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
      )}
    />,
    <PanelItem
      label={t(`${translateNamespace}.roomCount`)}
      value={roomCount + t(`${translateNamespace}.roomUnit`)}
    />,
    <PanelItem
      label={t(`${translateNamespace}.peopleCount`)}
      value={
        <Trans
          i18nKey={`${translateNamespace}.guests`}
          value={{
            numberOfAdults,
            numberOfChildren,
            numberOfInfants,
          }}
        >
          {{ numberOfAdults }} adults
          <br />
          {{ numberOfChildren }} children
          <br />
          {{ numberOfInfants }} infants
        </Trans>
      }
    />,
  ]

  if (plan) {
    items.splice(
      1,
      0,
      <PanelItem label={t(`${translateNamespace}.plan`)} value={plan.name} />
    )
  }

  return (
    <Panel
      cover={{
        name: publication?.name ?? '',
        image: property?.images?.at(0)?.url ?? '/img/hotel01.jpg',
        desc: plan
          ? t(`${translateNamespace}.action.option`)
          : t(`${translateNamespace}.action.plan`),
      }}
      label={t(`${translateNamespace}.label.room`)}
      items={items}
    />
  )
}
