import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { utcToZonedTime } from 'date-fns-tz'

import * as styles from './ScheduleModal.module.scss'

import { TIMEZONE } from '../../../constants'
import { BaseText, Button, Calendar, Modal, Text } from '../../basics'

type ScheduleModalProps = {
  isOpen: boolean
  onClose: () => void
  checkInDate?: Date
  checkOutDate?: Date
  onSearch: (startDate: Date, endDate: Date) => void
}

const translateNamespace = 'components.ScheduleModal'

export const ScheduleModal: React.FC<ScheduleModalProps> = ({
  isOpen,
  onClose,
  checkInDate,
  checkOutDate,
  onSearch,
}) => {
  const { t } = useTranslation()

  const [checkIn, setCheckIn] = useState<Date | undefined>(
    checkInDate ? utcToZonedTime(checkInDate, TIMEZONE) : undefined
  )
  const [checkOut, setCheckOut] = useState<Date | undefined>(
    checkOutDate ? utcToZonedTime(checkOutDate, TIMEZONE) : undefined
  )
  const invalidDate = useMemo(
    () => checkIn && checkOut && checkIn?.getTime() === checkOut?.getTime(),
    [checkIn, checkOut]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`${translateNamespace}.title`)}
      subTitle={<Text>{t(`${translateNamespace}.subTitle`)}</Text>}
      leftButton={
        <Button htmlType="button" variant="secondary" onClick={onClose}>
          {t(`${translateNamespace}.close`)}
        </Button>
      }
      rightButton={
        <Button
          htmlType="button"
          disabled={!checkIn || !checkOut || invalidDate}
          onClick={() => checkIn && checkOut && onSearch(checkIn, checkOut)}
        >
          {t(`${translateNamespace}.confirm`)}
        </Button>
      }
    >
      <div className={styles.calendar}>
        <Calendar
          startDate={checkIn}
          endDate={checkOut}
          onChange={(startDate, endDate) => {
            setCheckIn(startDate)
            setCheckOut(endDate)
          }}
        />
      </div>
      {invalidDate && (
        <div className={styles.error}>
          <BaseText color="red1" pcAlign="center" size={14}>
            {t(`${translateNamespace}.invalidDate`)}
          </BaseText>
        </div>
      )}
    </Modal>
  )
}
