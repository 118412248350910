import React from 'react'

import clsx from 'clsx'

type LogoSimpleImageProps = {
  color: string
  className: string
}

export const LogoSimpleImage: React.FC<LogoSimpleImageProps> = ({
  color,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="62"
    viewBox="0 0 62 62"
    className={clsx(color, className)}
  >
    <g transform="translate(-3736 8146)">
      <g transform="translate(3753.597 -8130.01)">
        <path
          d="M58.332,77.917l-3.817-3.025,2.3,4.177-7.778,1.164,8.245,1.1-2.7,4.861,5.027-3.813L64.641,86.2l-2.7-4.861,8.246-1.1-7.778-1.164,2.3-4.177L60.9,77.917l-1.283-9.182Z"
          transform="translate(-35.989 -50.44)"
          fill="#008dd5"
        />
        <path
          d="M4.175,0,0,29.782H6.284L7.962,17.645h8.865l.646-4.6H8.608L9.77,4.648H19.926L21.26,0Z"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </g>
  </svg>
)
