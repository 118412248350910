import React from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './Facility.module.scss'

import { HotelDetail } from '../../../../apis/fvillage'
import { getPublication } from '../../../../hooks'
import { Button, Flex, FlexBox, Text } from '../../../basics'

type FacilityProps = {
  facilities: HotelDetail[]
}

const translateNamespace = 'components.Facility'

export const Facility: React.FC<FacilityProps> = ({ facilities }) => {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.facilityContainer}>
      <h2>{t(`${translateNamespace}.title`)}</h2>

      <div className={styles.facilityContent}>
        {facilities.map(({ images, publications }, index) => {
          const publication = getPublication(i18n.language, publications ?? [])
          const viewMoreUrl = publication?.url
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Flex key={index} optionClass={styles.facilityContainerRow}>
              <img src={images?.length ? images[0].url : ''} alt="facility" />

              <Flex optionClass={styles.facilityDetail}>
                <FlexBox>
                  <Text pcSize={24} spSize={18} weight="bold">
                    {publication?.name}
                  </Text>
                  <div className={styles.rowGap} />
                  <Text pcSize={16} spSize={14}>
                    {publication?.description}
                  </Text>
                </FlexBox>
                {viewMoreUrl && (
                  <FlexBox optionClass={styles.facilityDetailButton}>
                    <a
                      href={viewMoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button htmlType="button" variant="primary" bold outline>
                        {t(`${translateNamespace}.viewMore`)}
                        <span className={styles.arrowRight} />
                      </Button>
                    </a>
                  </FlexBox>
                )}
              </Flex>
            </Flex>
          )
        })}
      </div>
    </div>
  )
}
