import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'
import { Area, Equipment, Order } from '../../constants'

export type UsePropertiesApiProps = {
  key?: string
  offset?: number
  limit?: number
  isHotel?: boolean
  insideFvillage?: boolean
  hotelAreas?: Area[]
  equipments?: Equipment[]
  minPrice?: number
  maxPrice?: number
  order?: Order
  withPagination?: boolean
}

export const usePropertiesApi = ({
  // Use key to differentiate the cache
  key = '/hotels',
  offset,
  limit,
  isHotel = true,
  insideFvillage,
  hotelAreas,
  equipments,
  minPrice,
  maxPrice,
  order,
  withPagination = true,
}: UsePropertiesApiProps) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    key,
    () =>
      propertyApi
        .listProperties(
          offset,
          limit,
          isHotel,
          insideFvillage,
          hotelAreas,
          equipments,
          minPrice,
          maxPrice,
          order,
          withPagination,
          options
        )
        .then((res) => res.data),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
