import { RouteObject } from 'react-router-dom'

import {
  AuthProvider,
  LoginCallback,
  OptionIndexHead,
  PaymentHead,
  PlanIndexHead,
  PropertyProvider,
  RegistrationCompletionHead,
  RoomIndexHead,
} from '../components'
import { PATH, reservationRouteType } from '../constants'
import {
  CancellationCompletionFrame,
  CancellationIndexFrame,
  LoginErrorFrame,
  NotFoundErrorFrame,
  OptionPlanIndexFrame,
  PlanIndexFrame,
  PropertyDetailFrame,
  PropertyListFrame,
  RegistrationCompletionFrame,
  RegistrationConfirmationFrame,
  RegistrationLoadingFrame,
  RegistrationNewFrame,
  ReservationDetailFrame,
  ReservationIndexFrame,
  RoomIndexFrame,
  ScheduleListFrame,
  SearchIndexFrame,
  ServerErrorFrame,
  TopIndexFrame,
} from '../frames'

const OAUTH_PROVIDER = process.env.VITE_OAUTH_PROVIDER || 'fid'
// f-id not allowed to use in localhost.
// So in development and staging env we use Google Oatuh2 instead of f-id.
const loginRoutes: RouteObject =
  OAUTH_PROVIDER === 'google'
    ? {
        path: PATH.login.root,
        children: [
          {
            path: PATH.login.google,
            element: <LoginCallback />,
          },
        ],
      }
    : {
        path: PATH.login.root,
        children: [
          {
            path: PATH.login.fid,
            element: <LoginCallback />,
          },
        ],
      }

const reservationRoutes = Object.values(reservationRouteType).map((type) => ({
  path: PATH.reservations[type].root,
  children: [
    {
      index: true,
      element: (
        <AuthProvider>
          <ReservationIndexFrame reservationType={type} />
        </AuthProvider>
      ),
    },
    {
      path: PATH.reservations[type].history,
      element: (
        <AuthProvider>
          <ReservationIndexFrame reservationType={type} />
        </AuthProvider>
      ),
    },
    {
      path: PATH.reservations[type].reservation,
      element: (
        <AuthProvider>
          <ReservationDetailFrame />
        </AuthProvider>
      ),
    },
    {
      path: PATH.reservations[type].cancel.root,
      children: [
        {
          index: true,
          element: (
            <AuthProvider>
              <CancellationIndexFrame />
            </AuthProvider>
          ),
        },
        {
          path: PATH.reservations[type].cancel.completion,
          element: (
            <AuthProvider>
              <CancellationCompletionFrame />
            </AuthProvider>
          ),
        },
      ],
    },
  ],
}))

export const routes: RouteObject[] = [
  {
    path: PATH.root,
    element: <TopIndexFrame />,
  },
  {
    path: PATH.list,
    element: <PropertyListFrame />,
  },
  {
    path: PATH.schedule,
    element: <ScheduleListFrame />,
  },
  {
    path: PATH.search,
    element: <SearchIndexFrame />,
  },
  {
    path: PATH.property,
    element: (
      <PropertyProvider>
        <PropertyDetailFrame />
      </PropertyProvider>
    ),
  },
  {
    path: PATH.rooms,
    element: (
      <PropertyProvider>
        <RoomIndexHead />
        <RoomIndexFrame />
      </PropertyProvider>
    ),
  },
  {
    path: PATH.plans,
    element: (
      <PropertyProvider>
        <PlanIndexHead />
        <PlanIndexFrame />
      </PropertyProvider>
    ),
  },
  {
    path: PATH.options,
    element: (
      <PropertyProvider>
        <OptionIndexHead />
        <OptionPlanIndexFrame />
      </PropertyProvider>
    ),
  },
  loginRoutes,
  {
    path: PATH.registrations.root,
    children: [
      {
        index: true,
        element: (
          <AuthProvider>
            <PropertyProvider>
              <PaymentHead />
              <RegistrationNewFrame />
            </PropertyProvider>
          </AuthProvider>
        ),
      },
      {
        path: PATH.registrations.confirmation,
        element: (
          <AuthProvider>
            <PropertyProvider>
              <RegistrationConfirmationFrame />
            </PropertyProvider>
          </AuthProvider>
        ),
      },
      {
        path: PATH.registrations.completion,
        element: (
          <AuthProvider>
            <PropertyProvider>
              <RegistrationCompletionHead />
              <RegistrationCompletionFrame />
            </PropertyProvider>
          </AuthProvider>
        ),
      },
      {
        path: PATH.registrations.loading,
        element: (
          <AuthProvider>
            <PropertyProvider>
              <RegistrationLoadingFrame />
            </PropertyProvider>
          </AuthProvider>
        ),
      },
    ],
  },
  ...reservationRoutes,
  {
    path: PATH.errors.serverError,
    element: <ServerErrorFrame />,
  },
  {
    path: PATH.errors.loginError,
    element: <LoginErrorFrame />,
  },
  {
    path: PATH.errors.notFoundError,
    element: <NotFoundErrorFrame />,
  },
]
