import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './SearchIndex.module.scss'

import { HotelRoomResponse } from '../../../apis/fvillage'
import { PATH } from '../../../constants'
import { useCreateNextPath, useSearchQueryParams } from '../../../hooks'
import { Flex, Sort } from '../../basics'
import { Loader, NotFound, ShowAllToggle } from '../../contents'
import { SearchItem } from '../SearchItem'

const translateNamespace = 'components.Sort.list'

type SearchIndexProps = {
  data?: HotelRoomResponse[]
  isLoading?: boolean
  count?: number
}

export const SearchIndex: React.FC<SearchIndexProps> = ({
  count,
  data = [],
  isLoading,
}) => {
  const { onCreateNextPath } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (isLoading) {
    return (
      <Flex optionClass={styles.loader}>
        <Loader />
      </Flex>
    )
  }

  return (
    <React.Fragment>
      <Sort
        count={count ?? 0}
        onChange={(e) => {
          const nextPath = onCreateNextPath(PATH.search, {
            queryParams: { propertyOrder: e.target.value, page: 1 },
          })
          navigate(nextPath)
        }}
        defaultValue={queryParams.propertyOrder ?? 'min_price'}
        noMargin
        showSort
      >
        <option value="min_price">{t(`${translateNamespace}.ascPrice`)}</option>
        <option value="-min_price">
          {t(`${translateNamespace}.descPrice`)}
        </option>
      </Sort>
      <ShowAllToggle withPagination />
      <ul>
        {Array.isArray(data) && data.length > 0 ? (
          data.map((property) => (
            <li className={styles.item} key={property.id}>
              <SearchItem
                id={property.id}
                images={property.images}
                publications={property.publications}
                rooms={property.rooms}
                slug={property.slug}
              />
            </li>
          ))
        ) : (
          <NotFound
            title={t(`empty.searchNotFound.attention`)}
            description1={t(`empty.searchNotFound.direction`)}
          />
        )}
      </ul>
    </React.Fragment>
  )
}
