import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DEFAULT_TRANSACTION_ID, PATH } from '../../../constants'
import {
  useCreateNextPath,
  useProperties,
  useRouteParams,
  useSearchQueryParams,
} from '../../../hooks'
import { ModalProps } from '../../basics'
import { CalendarModal } from '../CalendarModal'
import { PropertyListModal, PropertyListModalItem } from '../PropertyListModal'

type SearchModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>

export const SearchModal: React.FC<SearchModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [propertyModalIsOpen, setPropertyModalIsOpen] = useState(false)
  const [calendarModalIsOpen, setCalendarModalIsOpen] = useState(false)
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()

  useEffect(() => {
    setPropertyModalIsOpen(isOpen)
  }, [isOpen])

  const properties = useProperties().properties.map((property) => ({
    shortName: property.shortName ?? '',
    slug: property.slug ?? '',
    images: property.images,
    insideFvillage: property.insideFvillage,
    access: property.access ?? '',
  }))

  const { property: currentProperty } = useRouteParams()
  const defaultProperty = currentProperty
    ? properties.find((property) => property.slug === currentProperty)
    : properties[0]

  const [selectedProperty, setSelectedProperty] = useState<
    PropertyListModalItem | undefined
  >(defaultProperty)

  const { params } = useSearchQueryParams()

  return (
    <React.Fragment>
      <PropertyListModal
        isOpen={propertyModalIsOpen}
        onClose={() => {
          setPropertyModalIsOpen(false)
          onClose()
        }}
        properties={properties}
        defaultProperty={defaultProperty ?? null}
        key={defaultProperty ? defaultProperty.slug : undefined}
        onNext={(property) => {
          setSelectedProperty(property)
          setPropertyModalIsOpen(false)
          setCalendarModalIsOpen(true)
        }}
      />
      <CalendarModal
        isOpen={calendarModalIsOpen}
        onClose={() => {
          setCalendarModalIsOpen(false)
          onClose()
        }}
        onPrevious={() => {
          setCalendarModalIsOpen(false)
          setPropertyModalIsOpen(true)
        }}
        onSearch={(checkIn, checkOut) => {
          navigate(
            onCreateNextPath(PATH.rooms, {
              pathParams: { property: selectedProperty?.slug },
              queryParams: {
                checkInDate: checkIn,
                checkOutDate: checkOut,
                transaction: DEFAULT_TRANSACTION_ID,
              },
            })
          )
          setCalendarModalIsOpen(false)
        }}
        propertyName={selectedProperty ? selectedProperty.shortName : ''}
        checkInParam={params.checkInDate}
        checkOutParam={params.checkOutDate}
      />
    </React.Fragment>
  )
}
