import { stringify } from 'querystring'

import useSWR from 'swr'

import { options, propertyApi } from '../../apis/suitebookClient'
import { Order } from '../../constants'
import { filterAreas } from '../../utils'

const isOrder = (value: string | undefined): value is Order => {
  switch (value) {
    case 'order':
    case 'distance':
      return true
    default:
      return false
  }
}

export const usePropertyAvailabilitiesApi = (params?: {
  fromDate: string
  toDate: string
  offset?: number
  limit?: number
  insideFvillage?: boolean
  areas?: string[]
  propertyOrder?: string
  withPagination?: boolean
}) => {
  const { data, isLoading, isValidating, error, mutate } = useSWR(
    `/hotels/availabilities?${stringify(params)}`,
    () =>
      propertyApi
        .getPropertyAvailabilities(
          params?.offset,
          params?.limit,
          params?.fromDate,
          params?.toDate,
          undefined, // insideFvillage set always undefined
          filterAreas(params?.areas),
          isOrder(params?.propertyOrder) ? params?.propertyOrder : undefined,
          params?.withPagination ?? true,
          options
        )
        .then((res) => res.data),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
