import React from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './Amenity.module.scss'

import { HotelDetail } from '../../../../apis/fvillage'
import { getPublication } from '../../../../hooks'
import { Flex, FlexBox, MuiIcon, Text } from '../../../basics'

type AmenityProps = {
  amenities: HotelDetail[]
}

const translateNamespace = 'components.Amenity'
export const Amenity: React.FC<AmenityProps> = ({ amenities }) => {
  const { t, i18n } = useTranslation()

  const description = amenities
    .map(
      ({ publications }) =>
        getPublication(i18n.language, publications ?? [])?.description
    )
    .find((des) => des)

  return (
    <div className={styles.amenityContainer}>
      <h1>{t(`${translateNamespace}.title`)}</h1>

      <Text spSize={14} pcSize={16}>
        {description}
      </Text>

      <Flex optionClass={styles.amenityItems}>
        {amenities.map(({ publications }, index) => {
          const publication = getPublication(i18n.language, publications ?? [])
          return (
            // eslint-disable-next-line react/no-array-index-key
            <FlexBox key={index} optionClass={styles.amenityItem}>
              <MuiIcon size="3rem" variable={publication?.icon} />
              <Text pcSize={14} spSize={12}>
                {publication?.name}
              </Text>
            </FlexBox>
          )
        })}
      </Flex>
    </div>
  )
}
