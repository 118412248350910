import { useMemo } from 'react'

import { addDays, endOfMonth, startOfMonth } from 'date-fns'
import { format } from 'date-fns-tz'

export const useScheduleDate = (fromDate: Date) =>
  useMemo(() => {
    const firstDay = startOfMonth(fromDate)
    const lastDay = endOfMonth(fromDate)

    // For Sunday-start calendars:
    // Move back to the Sunday of the week that includes the 1st of the month
    const calendarStartDate = addDays(firstDay, -firstDay.getDay())
    // Move forward to the Saturday of the week that includes the last day of the month
    const daysToAdd = 6 - lastDay.getDay()
    const calendarEndDate = addDays(lastDay, daysToAdd)

    return {
      fromDate: format(calendarStartDate, 'yyyy-MM-dd'),
      toDate: format(calendarEndDate, 'yyyy-MM-dd'),
    }
  }, [fromDate])
