import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import './Navigation.scss'
import { RoomItem } from './RoomItem'
import * as styles from './RoomList.module.scss'

import {
  useCreateNextPath,
  useRoomsApi,
  useSearchQueryParams,
} from '../../../../hooks'
import { CardSlideShow, MaskIcon } from '../../../basics'

const translateNamespace = 'components.RoomList'

const CustomSwiperNavigation: React.FC = () => (
  <React.Fragment>
    <button
      type="button"
      className={clsx('slide-prev', 'navigateBtn', 'prevBtn')}
    >
      <MaskIcon pcSize={28} url="/img/icon/icon-arrow-white.svg" />
    </button>

    <button
      type="button"
      className={clsx('slide-next', 'navigateBtn', 'nextBtn')}
    >
      <MaskIcon pcSize={28} url="/img/icon/icon-arrow-white.svg" />
    </button>
  </React.Fragment>
)

export const RoomList: React.FC = () => {
  const { t } = useTranslation()
  const {
    params: { property },
  } = useCreateNextPath()
  const { params: queryParams } = useSearchQueryParams()
  const { data } = useRoomsApi({
    property,
    ...queryParams,
    showAllRooms: true,
  })

  if (!data?.length) return null

  // The style might look a bit odd when looping slides with fewer than 3.
  const rooms = data.length < 3 ? [...data, ...data] : data

  return (
    <div className={styles.roomList}>
      <h2>{t(`${translateNamespace}.title`)}</h2>

      <div className={styles.slideContainer}>
        <CardSlideShow
          navigation={{ nextEl: '.slide-next', prevEl: '.slide-prev' }}
        >
          {rooms.map((room) => (
            <div key={room.id} className={styles.card}>
              <RoomItem room={room} />
            </div>
          ))}
        </CardSlideShow>

        <CustomSwiperNavigation />
      </div>
    </div>
  )
}
