import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './NumberOfGuestsModal.module.scss'

import {
  Button,
  Flex,
  FlexBox,
  Modal,
  ModalProps,
  Option,
  Select,
  Text,
} from '../../basics'

const translateNamespace = 'components.NumberOfGuestsModal'

type NumberOfGuestsModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  adultParam?: number
  childParam?: number
  infantParam?: number
  onSearch: (adults: number, children: number, infants: number) => void
}

export const NumberOfGuestsModal: React.FC<NumberOfGuestsModalProps> = ({
  isOpen,
  onClose,
  onSearch,
  adultParam = 2,
  childParam = 0,
  infantParam = 0,
}) => {
  const { t } = useTranslation()
  const [adult, setAdult] = useState(adultParam)
  const [child, setChild] = useState(childParam)
  const [infant, setInfant] = useState(infantParam)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`${translateNamespace}.selectNumberOfGuests`)}
      subTitle={
        <Text>{t(`${translateNamespace}.selectNumberOfGuestDescription`)}</Text>
      }
      leftButton={
        <Button htmlType="button" variant="secondary" onClick={onClose}>
          {t(`${translateNamespace}.close`)}
        </Button>
      }
      rightButton={
        <Button
          htmlType="button"
          onClick={() => onSearch(adult, child, infant)}
        >
          {t(`${translateNamespace}.confirm`)}
        </Button>
      }
    >
      <div className={styles.contents}>
        <Flex optionClass={styles.forms}>
          <FlexBox>
            <span>{t(`${translateNamespace}.adultWithAge`)} </span>
          </FlexBox>
          <FlexBox>
            <Select
              onChange={(e) => setAdult(Number(e.target.value))}
              value={adult}
            >
              {[...Array(7)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Option value={i + 1} key={`room-modal-adult${i}`}>
                  {i + 1} {t(`${translateNamespace}.guestUnit`)}
                </Option>
              ))}
            </Select>
          </FlexBox>
        </Flex>

        <Flex optionClass={styles.forms}>
          <FlexBox>
            <span>{t(`${translateNamespace}.childWithAge`)}</span>
          </FlexBox>
          <FlexBox>
            <Select
              onChange={(e) => setChild(Number(e.target.value))}
              value={child}
            >
              {[...Array(6)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Option value={i} key={`room-modal-child${i}`}>
                  {i} {t(`${translateNamespace}.guestUnit`)}
                </Option>
              ))}
            </Select>
          </FlexBox>
        </Flex>

        <Flex optionClass={styles.forms}>
          <FlexBox>
            <span>{t(`${translateNamespace}.infantWithAge`)}</span>
          </FlexBox>
          <FlexBox>
            <Select
              onChange={(e) => setInfant(Number(e.target.value))}
              value={infant}
            >
              {[...Array(6)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Option value={i} key={`room-modal-infant${i}`}>
                  {i} {t(`${translateNamespace}.guestUnit`)}
                </Option>
              ))}
            </Select>
          </FlexBox>
        </Flex>
      </div>
    </Modal>
  )
}
