import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './Select.module.scss'

const translateNamespace = 'components.Input'

type OptionProps = {
  value: string | number
  disabled?: boolean
  children: React.ReactNode
}

export type SelectProps = {
  name?: string
  value?: string | number
  label?: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  children: React.ReactNode
  disabled?: boolean
  required?: boolean
  isBorderBottom?: boolean
  isBold?: boolean
  size?: 'medium' | 'small'
  color?: 'black' | 'blue'
  round?: boolean
  hasArrow?: boolean
}

// TODO: tabindex
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      value,
      label,
      onChange,
      children,
      disabled,
      required,
      isBorderBottom,
      size = 'medium',
      color = 'black',
      isBold,
      round,
      hasArrow = true,
    },
    ref
  ) => {
    const id = `select-${name}`
    const { t } = useTranslation()
    return (
      <dl>
        {typeof label !== 'undefined' && (
          <dt className={clsx(styles.labelText)}>
            <label htmlFor={id}>{label}</label>
            {required && (
              <span className={styles.requiredAsterisk}>
                {t(`${translateNamespace}.required`)}
              </span>
            )}
          </dt>
        )}
        <dd>
          <span
            className={clsx(styles.select, color === 'blue' && styles.blue)}
            data-hasarrow={hasArrow}
          >
            <select
              className={clsx(
                isBorderBottom && styles.isBorderBottom,
                size === 'small' && styles.small,
                isBold && styles.bold,
                round && styles.round
              )}
              name={name}
              id={id}
              onChange={onChange}
              ref={ref}
              value={value}
              disabled={disabled}
            >
              {children}
            </select>
          </span>
        </dd>
      </dl>
    )
  }
)

export const Option = forwardRef<HTMLOptionElement, OptionProps>(
  (props, ref) => (
    <option value={props.value} ref={ref} disabled={props.disabled}>
      {props.children}
    </option>
  )
)
