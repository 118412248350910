import React from 'react'

import clsx from 'clsx'

import * as styles from './Sort.module.scss'
import { SortIcon } from './SortIcon'
import { SortResult } from './SortResult'
import { SortSelect, SortSelectProps } from './SortSelect'

type SortProps = SortSelectProps & {
  count: number
  noMargin?: boolean
  showSort?: boolean
}

export const Sort: React.FC<SortProps> = ({
  count,
  defaultValue,
  children,
  noMargin,
  onChange,
  showSort = true,
}) => (
  <section className={clsx(styles.sortContainer, noMargin && styles.noMargin)}>
    <SortResult count={count} />
    <div className={styles.sortStatus}>
      <SortIcon />
      {showSort && (
        <SortSelect onChange={onChange} defaultValue={defaultValue}>
          {children}
        </SortSelect>
      )}
    </div>
  </section>
)
