import React from 'react'

import clsx from 'clsx'

import * as styles from './Icon.module.scss'

type HotelIconProps =
  | 'capacity'
  | 'meal'
  | 'smoke'
  | 'smokeOff'
  | 'size'
  | 'dog'
  | 'bed'
  | 'wheelchair'
  | 'hotspring'

type MiscIconProps =
  | 'plus'
  | 'close'
  | 'time'
  | 'info'
  | 'error'
  | 'check'
  | 'arrow'
  | 'filter'
  | 'circle'
  | 'bar'
  | 'cross'
  | 'triangle'

type CreditCardIconProps =
  | 'amex'
  | 'diners'
  | 'discover'
  | 'jcb'
  | 'master'
  | 'visa'
  | 'security'

type IconProps = {
  mark: HotelIconProps | MiscIconProps | CreditCardIconProps
  children?: React.ReactNode
}

export const Icon = ({ mark, children }: IconProps) => (
  <div className={styles.iconWrapper}>
    <span className={clsx(styles.iconElm, styles[mark])} />
    {children}
  </div>
)
