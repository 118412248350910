import React from 'react'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import * as styles from './PropertyCard.module.scss'

import Bed from '../../../assets/bed.svg'
import Commute from '../../../assets/commute.svg'
import { PATH } from '../../../constants'
import { useCreateNextPath } from '../../../hooks'
import { BaseText, MuiIcon, SlideShow } from '../../basics'

export type PropertyCardProps = {
  images: string[]
  title: string
  description?: string
  access?: string
  pricing?: string
  children?: React.ReactNode
  property?: string
  canNavigate?: boolean
  direction?: 'horizontal' | 'vertical'
  className?: string
  isTapAllowed?: boolean
  showFitScreenIcon?: boolean
  onModalOpen?: () => void
}

const PropertyContent: React.FC<
  Pick<
    PropertyCardProps,
    'description' | 'title' | 'children' | 'access' | 'pricing'
  > & { isHorizontal: boolean; openModal?: () => void }
> = ({
  description,
  title,
  access,
  children,
  openModal,
  isHorizontal,
  pricing,
}) => (
  <React.Fragment>
    {description && (
      <span className={styles.description} data-horizontal={isHorizontal}>
        {description}
        <hr className={styles.descriptionHr} data-horizontal={isHorizontal} />
      </span>
    )}
    <h2
      /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */
      role="button"
      className={styles.contentTitle}
      style={{ cursor: openModal ? 'pointer' : 'auto' }}
      tabIndex={0}
      onClick={openModal}
      onKeyDown={(e) => {
        if (e.key === ' ') {
          openModal?.()
        }
      }}
    >
      {title}
    </h2>
    {children && <div className={styles.desc}>{children}</div>}
    <div className={styles.items}>
      <img src={Commute} alt="" />
      <BaseText color="gray1" size={10}>
        {access}
      </BaseText>
    </div>
    {pricing && (
      <div className={styles.items}>
        <img src={Bed} alt="" />
        <BaseText color="gray1" size={10}>
          {pricing}
        </BaseText>
      </div>
    )}
  </React.Fragment>
)

export const PropertyCard: React.FC<PropertyCardProps> = ({
  title,
  description,
  images,
  access,
  pricing,
  children,
  property,
  canNavigate = false,
  direction = 'vertical',
  isTapAllowed = true,
  showFitScreenIcon = false,
  className,
  onModalOpen,
}) => {
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()
  const isHorizontal = direction === 'horizontal'
  const nextPath = onCreateNextPath(PATH.rooms, {
    pathParams: { property },
  })

  return (
    <div
      className={clsx(
        isHorizontal && styles.horizontal,
        !isHorizontal && styles.propertyVerticalCard,
        className
      )}
    >
      <div className={clsx(isHorizontal && styles.slideShowHorizontal)}>
        <SlideShow<string>
          bordered={false}
          // Temporarily only show the first image
          items={images ? [images[0]] : []}
          renderItem={(item, i) => (
            <div className={styles.slideShowImgContainer}>
              {showFitScreenIcon && (
                <MuiIcon
                  variable="fit_screen"
                  className={styles.fitScreen}
                  aria-label="Click to view in full screen"
                />
              )}
              <img
                className={clsx(styles.img, !isTapAllowed && styles.modalImg)}
                src={item}
                alt={`slide ${i}`}
              />
            </div>
          )}
          onTap={() => {
            if (!isTapAllowed) return
            onModalOpen?.()
          }}
        />
      </div>
      <div
        className={clsx(styles.navigation, styles.title)}
        data-horizontal={isHorizontal}
        onClick={() => canNavigate && navigate(nextPath)}
        onKeyUp={(e) => {
          if (canNavigate) {
            if (e.keyCode === 13) {
              navigate(nextPath)
            }
          }
        }}
        role="link"
        tabIndex={0}
      >
        <PropertyContent
          title={title}
          description={description}
          access={access}
          pricing={pricing}
          isHorizontal={isHorizontal}
          openModal={isTapAllowed ? onModalOpen : undefined}
        >
          {children}
        </PropertyContent>
      </div>
    </div>
  )
}
