import { useMemo } from 'react'

import clsx from 'clsx'

import * as styles from './MaskIcon.module.scss'

export const iconSize = [20, 24, 28, 32, 36, 40, 44, 48] as const

type IconSize = (typeof iconSize)[number]

type MaskIconProps = {
  url: string
  size?: IconSize
  pcSize?: IconSize
  spSize?: IconSize
}

export const MaskIcon = ({
  url,
  size = 20,
  pcSize = 20,
  spSize = 20,
}: MaskIconProps) => {
  const { classSize, classPcSize, classSpSize } = useMemo(
    () => ({
      classSize: size && styles[`size${size}`],
      classPcSize: pcSize && styles[`pcSize${pcSize}`],
      classSpSize: spSize && styles[`spSize${spSize}`],
    }),
    [size, pcSize, spSize]
  )

  return (
    <span
      className={clsx(
        styles.maskIconWrapper,
        classSize,
        classPcSize,
        classSpSize
      )}
      style={{
        WebkitMaskImage: `url(${url})`,
        maskImage: `url(${url})`,
      }}
    />
  )
}
