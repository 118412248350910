import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal, { Styles } from 'react-modal'

import clsx from 'clsx'

import * as styles from './Modal.module.scss'

import { Flex, FlexBox } from '../Flex'
import { Text } from '../Text'

const translateNamespace = 'components.Modal'
const closeTransitionDuration = 60

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  leftButton?: React.ReactNode
  centerButton?: React.ReactNode
  rightButton?: React.ReactNode
  title?: React.ReactNode
  subTitle?: React.ReactNode
  footer?: React.ReactNode
  onPrevious?: () => void
  style?: Styles
  isWide?: boolean
  children: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  leftButton,
  centerButton,
  rightButton,
  title,
  subTitle,
  footer,
  onPrevious,
  style,
  isWide,
  children,
}) => {
  const [isOpenLocal, setIsOpenLocal] = useState(false)
  const [isButtonClose, setIsButtonClose] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsOpenLocal(isOpen)
    if (isOpen) {
      // Lock body scroll
      document.body.style.overflow = 'hidden'
    }
    return () => {
      setIsButtonClose(false)

      // Unlock body scroll
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  ReactModal.setAppElement('#app')

  return (
    <ReactModal
      isOpen={isOpenLocal}
      shouldCloseOnOverlayClick
      onAfterClose={() => {
        if (isButtonClose && onPrevious) {
          return onPrevious()
        }
        return onClose()
      }}
      onRequestClose={() => setIsOpenLocal(false)}
      className={{
        base: clsx(styles.modalBase, isWide && styles.modalWide),
        afterOpen: styles.modalOpen,
        beforeClose: styles.modalClose,
      }}
      overlayClassName={{
        base: styles.overlayBase,
        afterOpen: styles.overlayOpen,
        beforeClose: styles.overlayClose,
      }}
      closeTimeoutMS={closeTransitionDuration}
      style={style}
    >
      <button
        type="button"
        className={styles.previousButton}
        onClick={() => {
          setIsOpenLocal(false)
          setIsButtonClose(true)
        }}
      >
        <Text size={14}>
          <span className={styles.previousArrow} />
          {onPrevious
            ? t(`${translateNamespace}.back`)
            : t(`${translateNamespace}.close`)}
        </Text>
      </button>
      {(title || subTitle) && (
        <div className={styles.header}>
          {title && (
            <div className={styles.title}>
              <Text align="center" size={16} weight="bold">
                {title}
              </Text>
            </div>
          )}
          {subTitle && (
            <div className={styles.subTitle}>
              <Text align="center" size={12}>
                {subTitle}
              </Text>
            </div>
          )}
        </div>
      )}
      <div className={styles.main}>{children}</div>
      <div className={styles.footer}>
        {footer && <div className={styles.footer}>{footer}</div>}
        {leftButton && rightButton ? (
          <Flex optionClass={styles.buttons}>
            <FlexBox optionClass={styles.button}>{leftButton}</FlexBox>
            {centerButton && (
              <FlexBox optionClass={styles.button}>{centerButton}</FlexBox>
            )}
            <FlexBox optionClass={styles.button}>{rightButton}</FlexBox>
          </Flex>
        ) : null}
      </div>
    </ReactModal>
  )
}
