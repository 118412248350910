/**
 * We define that the pagination consists of 3 blocks.
 * The part closed with '||' express each block.
 * 1. The left block of the one: 《 |1 2 3 4 5| ... 20 》
 * 2. The middle block of the one: 《 1 ... |8 9 10 11 12| ... 20 》
 * 3. The last block of the one: 《 1 ... |16 17 18 19 20| 》
 */

import { useMemo } from 'react'

/**
 * The Number of values displayed in middle of the pagination.
 *
 * 5:《 1 ... |5 6 7 8 9| ... 20 》
 * 7:《 1 ... |4 5 6 7 8 9 10| ... 20 》
 * 9:《 1 ... |3 4 5 6 7 8 9 10 11| ... 20 》
 */
const betweenDisplayedPage = 5

/**
 * We need to know if the pagination is simple or abbreviated.
 * It should be arrayed without abbreviation: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
 * It should be arrayed with abbreviation: [1,'...',8,9,10,11,12,'...',20]
 *
 * If we abbreviate the one, we need to know the count of elements including abbreviation.
 * The part closed with '||' express each index.
 * Index for the sides: 《 |1| ... 5 6 7 8 9 ... |20| 》
 * Index for dotted line: 《 1 |...| 5 6 7 8 9 |...| 20 》
 * So, the sum (these indexes and betweenDisplayedPage) is as many as elements in array.
 * Then, it's the checker if the pagination is simple or abbreviated.
 */
const sideIndexCount = 2
const abbrIndexCount = 2
const displayedIndexCount =
  betweenDisplayedPage + abbrIndexCount + sideIndexCount

/**
 * First number in left block
 *
 * 1 in 《 |1| 2 3 4 5 6 7 ... 20 》
 */
const leftBlock1stNum = 1

/**
 * Last number in left block
 *
 * 7 in 《 1 2 3 4 5 6 |7| ... 20 》
 *
 * ************************************************************
 * leftBlockEndNum is given like below.
 * (betweenDisplayedPage = 3) => leftBlockEndNum = 5: 《 1 2 3 4 |5|  ... [ 10 11 12 ] ... 20 》
 * (betweenDisplayedPage = 5) => leftBlockEndNum = 7: 《 1 2 3 4 5 6 |7| ... [ 5 elements ] ... 20 》
 * (betweenDisplayedPage = 7) => leftBlockEndNum = 9: 《 1 2 3 4 5 6 7 8 |9| ... [ 7 elements ] ... 20 》
 * ∴ leftBlockEndNum = betweenDisplayedPage + 2
 * ************************************************************
 */
const leftBlockEndNum = betweenDisplayedPage + 2

const isRangeValid = (currentPage: number, paginationCount: number) =>
  // Positive integer
  paginationCount > 0 &&
  currentPage > 0 &&
  // Within the scope of the pagination
  currentPage <= paginationCount

const isSimplePagination = (paginationCount: number) =>
  paginationCount > 0 && paginationCount <= displayedIndexCount

const useConfigPagination = (paginationCount: number) =>
  useMemo(() => {
    /**
     * First number in right block
     *
     * 《 1 ... |14| 15 16 17 18 19 20 》
     *
     * ************************************************************
     * rightBlock1stNum is given like below.
     * (betweenDisplayedPage = 3) => rightBlock1stNum = 16: 《 1 ... |16| 17 18 19 20 》
     * (betweenDisplayedPage = 5) => rightBlock1stNum = 14: 《 1 ... |14| 15 16 17 18 19 20 》
     * (betweenDisplayedPage = 7) => rightBlock1stNum = 12: 《 1 ... |12| 13 14 15 16 17 18 19 20 》
     * ∴ rightBlock1stNum = paginationCount(=20) - ( betweenDisplayedPage + 1 )
     * ************************************************************
     * Definitions above are given by an arithmetic progression (Also 等差数列 in Japanese).
     */
    const rightBlock1stNum = paginationCount - (betweenDisplayedPage + 1)

    /**
     * Last number in right block
     *
     * 《 1 ... 14 15 16 17 18 19 |20| 》
     */
    const rightBlockEndNum = paginationCount

    return {
      rightBlock1stNum,
      rightBlockEndNum,
    }
  }, [paginationCount])

export const usePagination = (
  totalPage: number,
  pageSize: number,
  currentPage: number
) => {
  const paginationCount = Math.ceil(totalPage / pageSize)
  const { rightBlock1stNum, rightBlockEndNum } =
    useConfigPagination(paginationCount)

  return useMemo(() => {
    if (!isRangeValid(currentPage, paginationCount)) {
      return { pages: [], paginationCount: null }
    }

    if (isSimplePagination(paginationCount)) {
      // Generate [1, 2, 3, 4, 5, 6]
      return {
        pages: Array.from({ length: paginationCount }, (_, i) => i + 1),
        paginationCount,
      }
    }

    if (leftBlock1stNum <= currentPage && currentPage < leftBlockEndNum - 1) {
      const leftBlockSequence = Array.from(
        { length: leftBlockEndNum - leftBlock1stNum + 1 },
        (_, i) => leftBlock1stNum + i
      )
      // Generate [1, 2, 3, 4, 5, 6, 7, '...', 20]
      return {
        pages: [leftBlockSequence, '...', rightBlockEndNum],
        paginationCount,
      }
    }

    if (
      leftBlockEndNum - 1 <= currentPage &&
      currentPage <= rightBlock1stNum + 1
    ) {
      const centerBlockSequence = Array.from(
        { length: betweenDisplayedPage },
        (_, i) => currentPage - (betweenDisplayedPage - 1) / 2 + i
      )
      // Generate [1, '...', 5, 6, 7, 8, 9, '...', '20']
      return {
        pages: [
          leftBlock1stNum,
          '...',
          centerBlockSequence,
          '...',
          rightBlockEndNum,
        ],
        paginationCount,
      }
    }

    if (rightBlock1stNum + 1 < currentPage && currentPage <= rightBlockEndNum) {
      const rightBlockSequence = Array.from(
        { length: rightBlockEndNum - rightBlock1stNum + 1 },
        (_, i) => rightBlock1stNum + i
      )
      // Generate [1, '...', 14, 15, 16, 17, 18, 19, 20]
      return {
        pages: [leftBlock1stNum, '...', rightBlockSequence],
        paginationCount,
      }
    }

    return { pages: [], paginationCount: null }
  }, [paginationCount, currentPage, rightBlock1stNum, rightBlockEndNum])
}
