import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import * as styles from './SearchIndexFrame.module.scss'

import {
  AreaSearchPanel,
  BannerHurusatoNouzeiSmall,
  LoginModal,
  MainColumn,
  MultiColumn,
  NavigateLinkButton,
  Pagination,
  SearchIndex,
  SearchLayout,
  SearchMainContent,
  SideColumn,
} from '../../components'
import { PAGE_SIZE, PATH } from '../../constants'
import {
  usePropertiesSearchApi,
  useSearchQueryParams,
  useStorageEnabled,
} from '../../hooks'

const translateNamespace = 'frames.SearchIndexFrame'

export const SearchIndexFrame = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const storageEnabled = useStorageEnabled()
  const {
    params: queryParams,
    offset,
    onUpdateSearchParams,
  } = useSearchQueryParams()

  // Area search is slow, so while loading data,
  // ReactModal displays the following error when you press the
  // AreaSearchPanel's change condition button.
  //
  // To prevent this behavior, fetch data in this component and pass the data
  // to each component.
  //
  // Error message
  //   `Cannot register modal instance that's already open`
  const { data, count, isLoading } = usePropertiesSearchApi({
    ...queryParams,
    offset,
    limit: PAGE_SIZE,
    propertyOrder: queryParams.propertyOrder,
  })

  return (
    <SearchLayout>
      {!storageEnabled && <LoginModal isOpen onClose={() => {}} nextPath="#" />}
      <SearchMainContent>
        <MultiColumn>
          <SideColumn>
            <AreaSearchPanel disabled={isLoading} />
            <div className={clsx(styles.bannerArea)}>
              <BannerHurusatoNouzeiSmall />
            </div>
          </SideColumn>
          <MainColumn>
            <div className={styles.searchIndex}>
              <SearchIndex data={data} isLoading={isLoading} count={count} />
            </div>
            <div className={styles.pagination}>
              <Pagination
                pageSize={PAGE_SIZE}
                totalPage={count ?? 0}
                currentPage={queryParams.page}
                onChange={(p) => onUpdateSearchParams({ page: p }, true)}
              />
            </div>
          </MainColumn>
        </MultiColumn>
      </SearchMainContent>

      <div className={styles.redirectButton}>
        <NavigateLinkButton
          arrow="left"
          text={t(`${translateNamespace}.backToTop`)}
          onClick={() => navigate(PATH.root)}
        />
      </div>
    </SearchLayout>
  )
}
