import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './HeaderLogoImage.module.scss'

type LogoImageProps = {
  color: string
  className?: string
}

const translateNamespace = 'components.Header.logo'

export const LogoImage: React.FC<LogoImageProps> = ({ color, className }) => {
  const { t } = useTranslation()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 200.122 47.831"
      className={clsx(color, className)}
    >
      <g
        id="logo-group1"
        data-name="logo-group1"
        transform="translate(-48.001 -48.843)"
      >
        <title>{t(`${translateNamespace}.title`)}</title>
        <desc>{t(`${translateNamespace}.desc`)}</desc>
        <g
          id="logo-group2"
          data-name="logo-group2"
          transform="translate(48.001 48.843)"
        >
          <g
            id="logo-group3"
            data-name="logo-group3"
            transform="translate(0 0)"
          >
            <path
              id="logo-path1"
              data-name="logo-path1"
              d="M135.153,418.773l2.374-4.3-3.931,3.117-1.322-9.461-1.321,9.461-3.931-3.117,2.375,4.3-8.015,1.2,8.5,1.137-2.783,5.009,5.179-3.927,5.181,3.927-2.783-5.009,8.494-1.137Z"
              transform="translate(-107.116 -389.031)"
              className={styles.logoStarBlue}
            />
            <path
              id="path-logo2"
              data-name="path-logo2"
              d="M92.767,341.392l-1.375,4.789H80.929l-1.2,8.645h9.133L88.2,359.57H79.067l-1.729,12.5H70.866l4.3-30.679Z"
              transform="translate(-70.866 -341.143)"
            />
            <path
              id="path-logo3"
              data-name="path-logo3"
              d="M208.739,366.622l-2.609-25.295h-6.674l4.687,30.71h7.78l13-30.71h-6.676Z"
              transform="translate(-163.143 -341.097)"
            />
            <path
              id="path-logo4"
              data-name="path-logo4"
              d="M291.831,372.04h6.454l4.286-30.708h-6.452Z"
              transform="translate(-229.432 -341.1)"
            />
            <path
              id="path-logo5"
              data-name="path-logo5"
              d="M348.372,341.329h-6.456l-4.286,30.708h17.682l1.415-5.281H344.789Z"
              transform="translate(-262.297 -341.098)"
            />
            <path
              id="path-logo6"
              data-name="path-logo6"
              d="M426.95,341.329H420.5l-4.288,30.708h17.684l1.414-5.281H423.368Z"
              transform="translate(-318.685 -341.098)"
            />
            <path
              id="path-logo7"
              data-name="path-logo7"
              d="M505.758,341.327l-13.573,30.71h6.674l2.653-6.88h9.5l.752,6.88h6.764l-4.951-30.71Zm-2.388,18.994,5.571-14.465,1.546,14.465Z"
              transform="translate(-373.207 -341.096)"
            />
            <path
              id="path-logo8"
              data-name="path-logo8"
              d="M700.632,372.04h18.081l.707-4.793H707.749l1.193-8.476h9.505l.576-4.7h-9.461l1.106-7.946h10.92l1.324-4.791H704.919Z"
              transform="translate(-522.789 -341.1)"
            />
            <path
              id="path-logo9"
              data-name="path-logo9"
              d="M621.806,371.45h-3.867l-.82-3.011a9.047,9.047,0,0,1-5.862,3.61c-4.592.5-7.822-.855-9.836-3.587s-2.805-6.55-2.155-11.871a20.216,20.216,0,0,1,2.978-8.689,15.316,15.316,0,0,1,13.56-7.385,9.773,9.773,0,0,1,4.9,1.329,11.061,11.061,0,0,1,4.106,4.485l-4.568,2.45a5.485,5.485,0,0,0-5.6-3.394,7.7,7.7,0,0,0-5.326,2.76q-2.5,2.79-3.2,8.485c-.492,4.015-.147,6.671.736,8.418.814,1.607,3.148,2.88,6.314,1.834a7.134,7.134,0,0,0,4.49-5.387c.193-1.126.4-2.188.4-2.188h-5.368l-.086-4.769h11.563Z"
              transform="translate(-449.889 -340.509)"
            />
          </g>
          <g
            id="logo-group4"
            data-name="logo-group4"
            transform="translate(50.031 40.148)"
          >
            <path
              id="logo-path10"
              data-name="logo-path10"
              d="M252.787,486.314H249.25l.449-3.254h-.614l-1.053,7.457h.625l.514-3.64h3.537l-.514,3.64h.625l1.043-7.457h-.625Z"
              transform="translate(-248.031 -482.952)"
            />
            <path
              id="logo-path11"
              data-name="logo-path11"
              d="M276.149,482.68a2.9,2.9,0,0,0-2.049.75,4.339,4.339,0,0,0-1.165,1.827,6.7,6.7,0,0,0-.362,2.128,3.238,3.238,0,0,0,.682,2.195,2.379,2.379,0,0,0,1.877.782,2.934,2.934,0,0,0,2.059-.745,4.216,4.216,0,0,0,1.154-1.829,7.024,7.024,0,0,0,.351-2.165,3.191,3.191,0,0,0-.677-2.179,2.385,2.385,0,0,0-1.871-.765m-1.006,7.121a1.775,1.775,0,0,1-1.418-.6,2.7,2.7,0,0,1-.516-1.8,6.572,6.572,0,0,1,.291-1.9,3.841,3.841,0,0,1,.949-1.625,2.277,2.277,0,0,1,1.678-.655,1.785,1.785,0,0,1,1.429.587,2.7,2.7,0,0,1,.506,1.785,6.981,6.981,0,0,1-.276,1.907,3.744,3.744,0,0,1-.933,1.631,2.307,2.307,0,0,1-1.71.66"
              transform="translate(-265.643 -482.68)"
            />
            <path
              id="logo-path12"
              data-name="logo-path12"
              d="M303.751,483.06l-3.535,3.357.475-3.357h-.625l-1.053,7.457h.625l.551-3.9,2.721,3.9h.734l-2.794-3.968,3.682-3.489Z"
              transform="translate(-284.616 -482.953)"
            />
            <path
              id="logo-path13"
              data-name="logo-path13"
              d="M325.506,483.06h-.782l-3.534,3.357.474-3.357h-.625l-1.053,7.457h.625l.551-3.9,2.721,3.9h.734l-2.794-3.968Z"
              transform="translate(-299.667 -482.953)"
            />
            <path
              id="logo-path14"
              data-name="logo-path14"
              d="M342.744,483.06l-3.551,7.457h.647l.963-2.062h3.086l.385,2.062h.638l-1.455-7.457Zm1.038,4.833h-2.714l1.945-4.178Z"
              transform="translate(-313.449 -482.953)"
            />
            <path
              id="logo-path15"
              data-name="logo-path15"
              d="M364.322,490.517h.625l1.043-7.457h-.615Z"
              transform="translate(-331.482 -482.953)"
            />
            <path
              id="logo-path16"
              data-name="logo-path16"
              d="M378.605,483.667a3.451,3.451,0,0,0-2.119-.607h-1.45l-1.053,7.457h1.583a3.571,3.571,0,0,0,2.221-.661,3.722,3.722,0,0,0,1.249-1.717,6.66,6.66,0,0,0,.383-2.285,2.589,2.589,0,0,0-.814-2.187m-1.139,5.7a2.741,2.741,0,0,1-1.826.594H374.7l.873-6.355h.915a2.812,2.812,0,0,1,1.685.462,2.088,2.088,0,0,1,.624,1.771,6.307,6.307,0,0,1-.313,2,3.359,3.359,0,0,1-1.016,1.527"
              transform="translate(-338.415 -482.953)"
            />
            <path
              id="logo-path17"
              data-name="logo-path17"
              d="M401.782,482.68a2.9,2.9,0,0,0-2.049.75,4.344,4.344,0,0,0-1.165,1.827,6.7,6.7,0,0,0-.361,2.128,3.238,3.238,0,0,0,.682,2.195,2.377,2.377,0,0,0,1.877.782,2.933,2.933,0,0,0,2.059-.745,4.215,4.215,0,0,0,1.154-1.829,7.023,7.023,0,0,0,.351-2.165,3.188,3.188,0,0,0-.678-2.179,2.384,2.384,0,0,0-1.871-.765m-1.006,7.121a1.775,1.775,0,0,1-1.418-.6,2.7,2.7,0,0,1-.517-1.8,6.575,6.575,0,0,1,.292-1.9,3.843,3.843,0,0,1,.949-1.625,2.278,2.278,0,0,1,1.678-.655,1.786,1.786,0,0,1,1.429.587,2.7,2.7,0,0,1,.506,1.785,6.98,6.98,0,0,1-.276,1.907,3.744,3.744,0,0,1-.933,1.631,2.308,2.308,0,0,1-1.71.66"
              transform="translate(-355.798 -482.68)"
            />
            <path
              id="logo-path18"
              data-name="logo-path18"
              d="M442.4,484.727a1.433,1.433,0,0,0-.607-1.249,2.837,2.837,0,0,0-1.642-.418h-1.781l-1.054,7.457h2.065a3.617,3.617,0,0,0,1.69-.344,2.111,2.111,0,0,0,.927-.888,2.526,2.526,0,0,0,.273-1.153,1.464,1.464,0,0,0-.43-1.142,1.883,1.883,0,0,0-.855-.42,1.974,1.974,0,0,0,.95-.565,1.822,1.822,0,0,0,.464-1.277m-.774,3.394a1.846,1.846,0,0,1-.5,1.315,2.278,2.278,0,0,1-1.7.53h-1.392l.424-3.1h1.6a1.918,1.918,0,0,1,1.16.311,1.1,1.1,0,0,1,.411.944m-1.635-1.8h-1.456l.37-2.714h1.279c1.071,0,1.592.368,1.592,1.126a1.523,1.523,0,0,1-.465,1.136,1.825,1.825,0,0,1-1.32.452"
              transform="translate(-383.862 -482.953)"
            />
            <path
              id="logo-path19"
              data-name="logo-path19"
              d="M460.464,483.06l-3.551,7.457h.647l.962-2.062h3.086l.385,2.062h.637l-1.455-7.457Zm1.039,4.833h-2.715l1.945-4.178Z"
              transform="translate(-397.926 -482.953)"
            />
            <path
              id="path-logo20"
              data-name="path-logo20"
              d="M483.892,483.06h-.614l-1.054,7.457h3.615l.171-.583h-3.07Z"
              transform="translate(-416.09 -482.953)"
            />
            <path
              id="path-logo21"
              data-name="path-logo21"
              d="M501.511,483.06H500.9l-1.053,7.457h3.615l.171-.583h-3.07Z"
              transform="translate(-428.733 -482.953)"
            />
            <path
              id="path-logo22"
              data-name="path-logo22"
              d="M522,483.522a3.023,3.023,0,0,0-1.809-.462h-1.674l-1.053,7.457h.625l.406-2.911h1.065a3.145,3.145,0,0,0,2.357-.758,2.678,2.678,0,0,0,.716-1.907,1.659,1.659,0,0,0-.632-1.42m-2.4,3.523h-1.028l.477-3.434h1.1a2.527,2.527,0,0,1,1.392.313,1.137,1.137,0,0,1,.458,1.017,2.165,2.165,0,0,1-.542,1.515,2.441,2.441,0,0,1-1.853.589"
              transform="translate(-441.376 -482.953)"
            />
            <path
              id="path-logo23"
              data-name="path-logo23"
              d="M538.128,483.06l-3.551,7.457h.647l.962-2.062h3.086l.385,2.062h.637l-1.455-7.457Zm1.039,4.833h-2.715l1.945-4.178Z"
              transform="translate(-453.658 -482.953)"
            />
            <path
              id="path-logo24"
              data-name="path-logo24"
              d="M564.744,486.237a2.3,2.3,0,0,0,.487-1.435c0-1.156-.767-1.742-2.28-1.742h-1.813l-1.053,7.457h.625l.471-3.362h1.5l1.5,3.362h.689l-1.559-3.442a2.448,2.448,0,0,0,1.437-.838m-1.836-2.626a2.2,2.2,0,0,1,1.288.3,1.046,1.046,0,0,1,.39.9,1.852,1.852,0,0,1-.484,1.278,2.042,2.042,0,0,1-1.58.535h-1.274l.423-3Z"
              transform="translate(-471.962 -482.953)"
            />
            <path
              id="path-logo25"
              data-name="path-logo25"
              d="M588.335,483.06h-.782l-3.534,3.357.474-3.357h-.625l-1.054,7.457h.625l.551-3.9,2.7,3.877.016.024h.734l-2.794-3.968Z"
              transform="translate(-488.274 -482.953)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
