import { Area, Equipment } from '../constants'

export const AREA_LIST = ['fvillage', 'kitahiroshima', 'sapporo', 'chitose']

const EQUIPMENT_LIST = [
  'no_smoking',
  'smoking',
  'free_wifi',
  'parking',
  'spa',
  'restaurant',
]

const isArea = (value: string): value is Area => {
  if (value === 'all') {
    return false
  }
  if (AREA_LIST.includes(value)) {
    return true
  }
  return false
}

export const filterAreas = (
  values: string[] | undefined
): Area[] | undefined => {
  if (typeof values === 'undefined') {
    return undefined
  }
  if (values.includes('all')) {
    return AREA_LIST as Area[]
  }
  const areas: Area[] = values.filter(isArea)
  if (areas.length === 0) {
    return undefined
  }
  return areas
}

const isEquipment = (value: string): value is Equipment => {
  if (EQUIPMENT_LIST.includes(value)) {
    return true
  }
  return false
}

export const filterEquipments = (
  values: string[] | undefined
): Equipment[] | undefined => {
  if (typeof values === 'undefined') {
    return undefined
  }
  const equipments: Equipment[] = values.filter(isEquipment)
  if (equipments.length === 0) {
    return undefined
  }
  return equipments
}
