import React from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import * as styles from './Schedule.module.scss'

import { Flex, FlexBox, Icon, Text } from '../../../basics'

const translateNamespace = 'components.Schedule'

export const AvailabilityDescription: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { t } = useTranslation()

  return (
    <Flex optionClass={clsx(styles.availabilityFlex, className)}>
      <FlexBox>
        <div className={styles.availabilityDesc}>
          <div className={styles.availabilityStatus}>
            <Icon mark="circle" />
            <Text size={12} pcSize={14}>
              {t(`${translateNamespace}.available`)}
            </Text>
          </div>
          <div className={styles.availabilityStatus}>
            <Icon mark="triangle" />
            <Text size={12} pcSize={14}>
              {t(`${translateNamespace}.runningLow`)}
            </Text>
          </div>
          <div className={styles.availabilityStatus}>
            <Icon mark="cross" />
            <Text size={12} pcSize={14}>
              {t(`${translateNamespace}.soldOut`)}
            </Text>
          </div>
          <div className={styles.availabilityStatus}>
            <Icon mark="bar" />
            <Text size={12} pcSize={14}>
              {t(`${translateNamespace}.notAvailable`)}
            </Text>
          </div>
        </div>
      </FlexBox>
    </Flex>
  )
}
