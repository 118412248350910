import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as styles from './FacilitySearchPanel.module.scss'

import { PATH } from '../../../constants'
import {
  SearchParam,
  useCreateNextPath,
  usePropertyApi,
  usePublication,
  useRouteParams,
  useSearchQueryParams,
} from '../../../hooks'
import i18n from '../../../i18n'
import { formatJstDate } from '../../../utils'
import { Button, Col, Container, Row, Text } from '../../basics'
import { CalendarModal, FacilitySearchModal } from '../../modals'
import { Panel } from '../Panel'

const translateNamespace = 'components.FacilitySearchPanel'

const useNavigateRooms = (property: string) => {
  const navigate = useNavigate()
  const { onCreateNextPath } = useCreateNextPath()

  const onParamsChange = useCallback(
    (queryParams: SearchParam) => {
      navigate(
        onCreateNextPath(PATH.rooms, {
          pathParams: { property },
          queryParams,
        })
      )
      window.scrollTo(0, 0)
    },
    [navigate, onCreateNextPath, property]
  )

  return onParamsChange
}

type PanelItemProps = {
  label: React.ReactNode
  value: React.ReactNode
  action?: () => void
}

const PanelItem: React.FC<PanelItemProps> = ({ label, value, action }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.panelItem}>
      <Container>
        <Row>
          <Col pc={9} sp={9}>
            <Text size={12}>{label}</Text>
            <Text size={14} weight="bold" pcAlign="left" spAlign="left">
              {value}
            </Text>
          </Col>
          <Col pc={3} sp={3}>
            <div className={styles.button}>
              <Button
                display="inlineBlock"
                htmlType="button"
                outline
                size="small"
                variant="secondary"
                onClick={action}
              >
                {t(`${translateNamespace}.change`)}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

type FacilitySearchPanelProps = {
  isActivity?: boolean
  slug?: string
}

export const FacilitySearchPanel: React.FC<FacilitySearchPanelProps> = ({
  isActivity = false,
  slug = '',
}) => {
  const [calendarIsOpen, setCalendarIsOpen] = useState(false)
  const [roomIsOpen, setRoomIsOpen] = useState(false)
  const { t } = useTranslation()
  const { property: propertySlug } = useRouteParams()
  const { data: property } = usePropertyApi(propertySlug ?? '')
  const publication = usePublication(i18n.language, property?.publications)
  const onParamsChange = useNavigateRooms(propertySlug ?? '')

  const { params, onUpdateSearchParams } = useSearchQueryParams()
  const {
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomCount,
  } = params

  useEffect(() => {
    onUpdateSearchParams({ facility: true }, true)
  }, [onUpdateSearchParams])

  return (
    <React.Fragment>
      <Panel
        cover={{
          name: publication?.name ?? '',
          image: property?.images?.at(0)?.url ?? '/img/hotel01.jpg',
          desc: t(`${translateNamespace}.action.ticket`),
        }}
        label={t(`${translateNamespace}.label.search`)}
        items={[
          <PanelItem
            label={t(`${translateNamespace}.dateOfUse`)}
            value={formatJstDate(
              checkInDate,
              i18n.language === 'ja' ? 'yyyy/MM/dd (E)' : 'MM/dd/yyyy'
            )}
            action={() => setCalendarIsOpen(true)}
          />,
          <PanelItem
            label={t(`${translateNamespace}.people`)}
            value={
              <Trans
                i18nKey={`${translateNamespace}.guests`}
                value={{
                  numberOfAdults,
                  numberOfChildren,
                  numberOfInfants,
                }}
              >
                {{ numberOfAdults }} adults
                <br />
                {{ numberOfChildren }} children
                <br />
                {{ numberOfInfants }} infants
              </Trans>
            }
            action={() => setRoomIsOpen(true)}
          />,
        ]}
      />
      {property?.isActivity && (
        <div className={styles.notice}>
          <Text size={12}>
            {t(`${translateNamespace}.notice`)}
            <br />
            <a
              href="https://www.hkdballpark.com/news/564/"
              target="_blank"
              rel="noreferrer"
            >
              {t(`${translateNamespace}.activityDetail`, {
                name: publication?.shortName,
              })}
            </a>
          </Text>
        </div>
      )}
      <CalendarModal
        propertyName={publication?.name ?? ''}
        // Spa is used for a one day.
        // But, the backend requires checkInDate and checkOutDate.
        // And Need to both set checkInParam and checkOutParam to same value
        // to make the calendar work as a single date picker.
        checkInParam={checkInDate}
        checkOutParam={checkInDate}
        isOpen={calendarIsOpen}
        onClose={() => setCalendarIsOpen(false)}
        onSearch={(checkIn, checkOut) => {
          onParamsChange({
            checkInDate: checkIn,
            checkOutDate: checkOut,
          })
          setCalendarIsOpen(false)
        }}
        mode="single"
        key={`${checkInDate}${checkOutDate}`}
      />
      <FacilitySearchModal
        isOpen={roomIsOpen}
        onClose={() => setRoomIsOpen(false)}
        propertyName={publication?.shortName ?? ''}
        roomParam={roomCount}
        adultParam={numberOfAdults}
        childParam={numberOfChildren}
        infantParam={numberOfInfants}
        checkIn={checkInDate}
        onSearch={(rooms, adults, children, infants) => {
          setRoomIsOpen(false)
          // Force reset scroll position and scroll to top.
          window.setTimeout(() => {
            onParamsChange({
              roomCount: rooms,
              numberOfAdults: adults,
              numberOfChildren: children,
              numberOfInfants: infants,
            })
          }, 700)
        }}
        key={`${roomCount}${numberOfAdults}${numberOfChildren}${numberOfInfants}`}
        isActivity={isActivity}
        slug={slug}
      />
    </React.Fragment>
  )
}
