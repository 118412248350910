import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as styles from './AreaSearchModal.module.scss'

import { useSearchQueryParams } from '../../../hooks'
import { Button, Icon, Modal, Text } from '../../basics'

type AreaSearchModalProps = {
  isOpen: boolean
  areas?: Area[]
  onClose: () => void
  onSearch: (area: Area[]) => void
}

export type Area = { label: string; value: string }

const translateNamespace = 'components.AreaSearchModal'

export const AreaSearchModal: React.FC<AreaSearchModalProps> = ({
  isOpen,
  areas: areasProps,
  onClose,
  onSearch,
}) => {
  const {
    params: { areas: areasParams },
  } = useSearchQueryParams()
  const { t } = useTranslation()
  const areas: Area[] = [
    { label: t(`${translateNamespace}.fvillage`), value: 'fvillage' },
    { label: t(`${translateNamespace}.kitahiroshima`), value: 'kitahiroshima' },
    { label: t(`${translateNamespace}.sapporo`), value: 'sapporo' },
    { label: t(`${translateNamespace}.chitose`), value: 'chitose' },
  ]
  const [selectedAreas, setSelectedAreas] = useState(
    areasParams
      ? areas.filter(({ value }) => areasParams.includes(value))
      : areas
  )

  const toggleArea = useCallback(
    (area: Area) => {
      const index = selectedAreas.findIndex((item) => item.value === area.value)

      if (index !== -1) {
        setSelectedAreas(
          selectedAreas.filter(({ value }) => value !== area.value)
        )
      } else {
        setSelectedAreas([...selectedAreas, area])
      }
    },
    [selectedAreas]
  )

  useEffect(() => {
    if (areasProps) setSelectedAreas(areasProps)
  }, [areasProps])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`${translateNamespace}.title`)}
      subTitle={<Text>{t(`${translateNamespace}.subTitle`)}</Text>}
      leftButton={
        <Button htmlType="button" variant="secondary" onClick={onClose}>
          {t(`${translateNamespace}.close`)}
        </Button>
      }
      rightButton={
        <Button
          htmlType="button"
          disabled={selectedAreas.length === 0}
          onClick={() => onSearch(selectedAreas)}
        >
          {t(`${translateNamespace}.confirm`)}
        </Button>
      }
    >
      <div className={styles.areas}>
        {areas.map((item) => {
          const isVisible = !!selectedAreas.filter(
            (area) => area.value === item.value
          ).length

          return (
            <div
              className={styles.area}
              key={item.value}
              onClick={() => toggleArea(item)}
              onKeyUp={(e) => e.key === ' ' && toggleArea(item)}
              role="button"
              tabIndex={0}
            >
              <span className={isVisible ? '' : styles.invisible}>
                <Icon mark="check" />
              </span>
              {item.label}
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
