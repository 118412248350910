import { useSearchQueryParams } from '../useSearchParams'

// With airline ticket.
// Sidepanel's airline ticket should add check-in, check-out,
// adults, children, infants and rooms to query params
export const useAirlineTicketUrl = (withAirlineTicketUrl?: string | null) => {
  const { params } = useSearchQueryParams()
  const {
    checkInDate,
    checkOutDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    roomCount,
    areas,
  } = params

  if (!withAirlineTicketUrl) {
    return undefined
  }

  const searchParams = new URLSearchParams()
  searchParams.set('check_in', checkInDate)
  searchParams.set('check_out', checkOutDate)
  searchParams.set('adults', numberOfAdults ? numberOfAdults.toString() : '2')
  searchParams.set(
    'children',
    numberOfChildren ? numberOfChildren.toString() : '0'
  )
  searchParams.set(
    'infants',
    numberOfInfants ? numberOfInfants.toString() : '0'
  )
  searchParams.set('rooms', roomCount ? roomCount.toString() : '1')
  areas.forEach((area) => searchParams.append('area', area))

  return `${withAirlineTicketUrl}?${searchParams.toString()}`
}
