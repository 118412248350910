import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as styles from './NotFound.module.scss'

import { ButtonWrapper, Text } from '../../basics'

type NotFoundProps = {
  title: React.ReactNode
  description1: React.ReactNode
  description2?: React.ReactNode
  button?: {
    text: string
    path: string
  }
  link?: {
    text: string
    url: string
  }
}

export const NotFound: React.FC<NotFoundProps> = ({
  title,
  description1,
  description2,
  button,
  link,
}) => {
  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Text size={20} weight="bold" align="center">
          {title}
        </Text>
      </div>
      <div className={styles.description}>
        <Text size={16} align="center">
          {description1}
        </Text>
        {description2 && (
          <div className={styles.description2}>
            <Text size={16} align="center">
              {description2}
            </Text>
          </div>
        )}
      </div>
      {button && (
        <ButtonWrapper
          htmlType="button"
          variant="primary"
          position="center"
          size="medium"
          onClick={() => {
            navigate(button.path)
          }}
        >
          {button.text}
        </ButtonWrapper>
      )}
      {link && (
        <div className={styles.link}>
          <Text size={16} align="center">
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.text}
            </a>
          </Text>
        </div>
      )}
    </div>
  )
}
