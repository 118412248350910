import { useTranslation } from 'react-i18next'

import { isSameDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import esconEvents from './escon-events.json'

import { TIMEZONE } from '../../constants'

type GameDays = {
  date: Date
  startHour: number
  event: string
  summary: string
  image?: string
}[]

type TeamData = {
  image: string
}

const TEAMS_DATA: Record<string, TeamData> = {
  F: {
    image: '/img/teams/fighters.png',
  },
  E: {
    image: '/img/teams/eagles.png',
  },
  M: {
    image: '/img/teams/marines.png',
  },
  L: {
    image: '/img/teams/lions.png',
  },
  H: {
    image: '/img/teams/hawks.png',
  },
  B: {
    image: '/img/teams/buffaloes.png',
  },
  G: {
    image: '/img/teams/giants.png',
  },
  S: {
    image: '/img/teams/swallows.png',
  },
  DB: {
    image: '/img/teams/baystars.png',
  },
  D: {
    image: '/img/teams/dragons.png',
  },
  T: {
    image: '/img/teams/tigers.png',
  },
  C: {
    image: '/img/teams/carp.png',
  },
}

const SUMMARY_PATTERN = /(F-.{1,2})|(.{1,2}-F)/
const SUMMARY_HOUR_PATTERN = /\d{1,2}:\d{2}/

const translateNamespace = 'hooks.useGameDays'

export const useGameDays = () => {
  const { t } = useTranslation()

  const teams = {
    F: t(`${translateNamespace}.F`),
    E: t(`${translateNamespace}.E`),
    M: t(`${translateNamespace}.M`),
    L: t(`${translateNamespace}.L`),
    H: t(`${translateNamespace}.H`),
    B: t(`${translateNamespace}.B`),
    G: t(`${translateNamespace}.G`),
    S: t(`${translateNamespace}.S`),
    DB: t(`${translateNamespace}.DB`),
    D: t(`${translateNamespace}.D`),
    T: t(`${translateNamespace}.T`),
    C: t(`${translateNamespace}.C`),
  }
  const getTeamName = (abbreviation: string): string =>
    (teams[abbreviation as keyof typeof teams] as string) || ''

  const gameDays: GameDays = []
  const isGameDay = (date: Date) =>
    gameDays.some(({ date: gameDay }) => isSameDay(gameDay, date))

  const getEventInfo = (summary: string) => {
    const matchedSummary = summary.match(SUMMARY_PATTERN)

    if (matchedSummary) {
      const matchedStr = matchedSummary[0].trim()

      const abbreviation = (
        matchedStr.startsWith('F-')
          ? matchedStr.split('-')[1]
          : matchedStr.split('-')[0]
      ) as keyof typeof TEAMS_DATA

      const teamData = TEAMS_DATA[abbreviation]

      return {
        team: getTeamName(abbreviation),
        image: teamData.image,
      }
    }

    // For other cases, use empty string/undefined as requested
    return {
      team: '',
      image: undefined,
    }
  }

  const getSummaryHour = (summary: string) => {
    const matchedTime = summary.match(SUMMARY_HOUR_PATTERN)

    return matchedTime ? Number(matchedTime[0].split(':')[0]) : 0
  }

  esconEvents.forEach((event) => {
    const time = event.dtstart
    const { team, image } = getEventInfo(event.summary)

    gameDays.push({
      date: utcToZonedTime(time, TIMEZONE),
      startHour: getSummaryHour(event.summary),
      event: team,
      summary: event.summary,
      image,
    })
  })

  return {
    gameDays,
    isGameDay,
  }
}
