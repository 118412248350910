import React from 'react'
import { useTranslation } from 'react-i18next'

import dompurify from 'dompurify'

import * as styles from './Access.module.scss'

import { HotelDetail } from '../../../../apis/fvillage'
import { getPublication } from '../../../../hooks'
import {
  Col,
  Container,
  Flex,
  FlexBox,
  MuiIcon,
  Row,
  Text,
} from '../../../basics'

type AccessProps = {
  access: HotelDetail[]
}

const translateNamespace = 'components.Access'
export const Access: React.FC<AccessProps> = ({ access }) => {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.accessContainer}>
      <h2>{t(`${translateNamespace}.title`)}</h2>

      <Flex optionClass={styles.accessInfo}>
        <FlexBox optionClass={styles.accessInfoDetail}>
          <Container>
            {access.map(({ publications }, index) => {
              const publication = getPublication(
                i18n.language,
                publications ?? []
              )
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={index}>
                  <Col pc={1} sp={1.3}>
                    <MuiIcon
                      className={styles.icon}
                      variable={publication?.icon}
                    />
                  </Col>
                  <Col className={styles.accessInfoAddress}>
                    <Text weight="bold" pcSize={20} spSize={14}>
                      {publication?.name}
                    </Text>

                    {publication?.descriptionDetail ? (
                      <div
                        className={styles.descriptionDetail}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(
                            publication.descriptionDetail,
                            {
                              ADD_TAGS: ['iframe'],
                              ADD_ATTR: [
                                'allowfullscreen',
                                'loading',
                                'referrerpolicy',
                              ],
                            }
                          ),
                        }}
                      />
                    ) : (
                      <Text spSize={12} pcSize={16}>
                        {publication?.description}
                      </Text>
                    )}
                  </Col>
                </Row>
              )
            })}
          </Container>
        </FlexBox>
      </Flex>
    </div>
  )
}
